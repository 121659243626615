import React from 'react';
import Image from 'next/image';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import ButtonBase from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import { SEO_PAGE_ROUTES } from '@/constants';

import SearchWidgetBranchCard from '../../Cards/SearchWidgetBranchCard/SearchWidgetBranchCard';

import styles from './RecentSearchList.style';

const useStyles = makeStyles()(styles);

function RecentSearchList({
  language,
  branches,
  handleSelectItem,
  searchValue,
}) {
  const { classes } = useStyles();

  return (
    <Grid container className={classes.wrapper}>
      {branches &&
        branches.map((branch) => {
          const testId = `recentSearchBranchCard_${branch.id}`;
          const branchName = _get(branch, 'name', '');
          const cityName = _get(branch, 'cityName', '');
          const branchCityText = `${branchName}, ${cityName}`;
          return (
            <React.Fragment key={branch.id}>
              <ButtonBase
                className={classes.cardWrapper}
                key={branch.id}
                data-testid={testId}
                onClick={() => handleSelectItem(branch)}
              >
                <Typography className={classes.title}>
                  <span className={classes.clockIcon}>
                    <Image
                      height={24}
                      width={24}
                      src="/images/svg/clock-counter.svg"
                      alt="Clock Counter"
                    />
                  </span>
                  {branchCityText}
                </Typography>
              </ButtonBase>
            </React.Fragment>
          );
        })}
    </Grid>
  );
}

export default React.memo(RecentSearchList);

RecentSearchList.propTypes = {
  language: PropTypes.string,
  branches: PropTypes.array.isRequired,
  handleSelectItem: PropTypes.func,
  searchValue: PropTypes.string,
};

RecentSearchList.defaultProps = {
  searchValue: '',
  handleSelectItem: () => {
    // handle select here
  },
};
