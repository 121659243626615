const styles = (theme) => ({
  searchWrapper: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    marginBottom: 0,
  },
  banner: {
    position: 'relative',
    backgroundColor: theme.palette.common.blue400,
    padding: '36px 20px',
  },
  titleWrapper: {
    position: 'relative',
    zIndex: theme.zIndex.zIndex10,
  },
  title: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize22,
    lineHeight: 1.2,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 4,
  },
  subTitle: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize14,
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightSemiBold,
  },
});

export default styles;
