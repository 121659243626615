import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import CarDeliveryInformation from '../CarDeliveryInformation';

import styles from './CarDeliveryTooltip.style';

const useStyles = makeStyles()(styles);

export default function CarDeliveryTooltip({
  carDeliveryBranchList,
  isListingScreen,
}) {
  const { classes } = useStyles();

  const imgSrc = isListingScreen
    ? '/images/svg/information-blue-icon.svg'
    : '/images/svg/information-white-icon.svg';

  return (
    <Tooltip
      title={
        <CarDeliveryInformation carDeliveryBranchList={carDeliveryBranchList} />
      }
      classes={{
        tooltip: classes.paper,
        arrow: classes.tooltipArrow,
      }}
      interactive
      arrow
    >
      <Typography variant="caption" className={classes.infoIcon}>
        <img src={imgSrc} alt="Car Delivery Info" />
      </Typography>
    </Tooltip>
  );
}
