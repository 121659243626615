import React, { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
} from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import styles from '@/components/SideMenu/SideMenu.style';

const useStyles = makeStyles()(styles);
const NestedMenu = ({ buttonLabel, menuItems, active }) => {
  const { classes } = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (isExpanded, index) => {
    setExpanded(isExpanded ? index : false);
  };

  return (
    <div>
      <Accordion
        className={`${classes.accordionMenu} ${
          expanded === 0 || active ? classes.expandedAccordion : ''
        }`}
        expanded={expanded === 0}
        onChange={(e, isExpanded) => handleAccordionChange(isExpanded, 0)}
      >
        <AccordionSummary
          className={classes.accordionMenu}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {buttonLabel}
        </AccordionSummary>
        <AccordionDetails className={classes.accordionPanel}>
          {menuItems.map((item, index) => (
            <MenuItem
              key={item.label}
              onClick={item.onClick}
              className={classes.menuItem}
            >
              {item.label}
            </MenuItem>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default NestedMenu;
