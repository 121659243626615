import React from 'react';
import { useTranslation } from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import BranchListDesktop from '../Lists/BranchList/BranchListDesktop';

import styles from './BranchListWrapper.style';

const useStyles = makeStyles()(styles);

const BranchListWrapper = React.memo(
  ({
    language,
    data,
    handleSelectItem,
    searchValue,
    showBranchList,
    isLocationSuccess,
    branchErrorMessage,
    getBranchesByCityId,
    isSwitchOpen,
  }) => {
    const { classes } = useStyles();
    const { t } = useTranslation();

    // drived value
    const hasBranch = !_isEmpty(data);
    const hasSearch = !!searchValue;
    const searchBranchInfo = t('Showing_all_results_for').replace(
      '%@',
      `${searchValue}`,
    );

    const isSearchResult = hasSearch && hasBranch;

    if (hasSearch && !hasBranch) {
      return (
        <Typography variant="h6" className={classes.noData}>
          {t('nothing_found')}
        </Typography>
      );
    }

    if (branchErrorMessage) {
      return (
        <>
          <Typography className={classes.noData}>
            {branchErrorMessage}
          </Typography>
          <Divider
            classes={{
              root: classes.divider,
            }}
          />
        </>
      );
    }

    return showBranchList || isLocationSuccess ? (
      <>
        {isSearchResult && (
          <>
            <Typography className={classes.subTitle}>
              {searchBranchInfo}
            </Typography>
            <Divider
              classes={{
                root: classes.divider,
              }}
            />
          </>
        )}
        <List className={classes.listWrapper}>
          {Object.keys(data).map((cityId) => {
            const branches = data[cityId];
            return (
              <React.Fragment key={cityId}>
                <ListItem className={classes.listItem}>
                  <BranchListDesktop
                    language={language}
                    branches={branches}
                    handleSelectItem={handleSelectItem}
                    searchValue={searchValue}
                    isSwitchOpen={isSwitchOpen}
                    getBranchesByCityId={getBranchesByCityId}
                  />
                </ListItem>
              </React.Fragment>
            );
          })}
        </List>
      </>
    ) : null;
  },
);

export default BranchListWrapper;
