import { shakeHorizontal } from '../../animations';

const styles = (theme) => ({
  paper: {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.grey20}`,
    width: '100%',
    maxWidth: 255,
    marginTop: 4,
    borderRadius: 4,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.05)',
    transition: 'none !important',
    '&.dark': {
      maxWidth: 270,
    },
  },
  wrapper: {
    width: '100%',
    maxWidth: '25%',
    padding: '0 12px',
  },
  fieldLabel: {
    display: 'inline-flex',
    color: theme.palette.text.placeholder,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.5,
    '.dark &': {
      color: theme.palette.common.white,
    },
    '&.error': {
      color: theme.palette.common.red400,
    },
  },

  inputField: {
    padding: '16px 14px',
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.1,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightSemiBold,
    '.dark &': {
      backgroundColor: theme.palette.common.white,
    },
    '&::placeholder': {
      color: theme.palette.common.grey300,
      fontWeight: theme.typography.fontWeightRegular,
    },
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: 'pointer',
  },

  /* DayPicker styles */
  dayPickerWrapper: {
    width: '100%',
    padding: '12px 0px',
    backgroundColor: theme.palette.common.white,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    '& .DayPicker': {
      display: 'inline-block',
      fontSize: theme.typography.fontSize16,
    },
    '& .DayPicker-wrapper': {
      position: 'relative',
      flexDirection: 'row',
      paddingBottom: '1rem',
      webkitUserSelect: 'none',
      mozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none',
    },
    '& .DayPicker-Months': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    '& .DayPicker-Month': {
      display: 'flex',
      flexDirection: 'column',
      borderSpacing: 0,
      borderCollapse: 'collapse',
      webkitUserSelect: 'none',
      mozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none',
      '& .DayPicker-Weekdays': {
        display: 'flex',
        marginTop: '1em',
      },
      '& .DayPicker-WeekdaysRow': {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
      },
      '& .DayPicker-Weekday': {
        display: 'table-cell',
        padding: '0.5em',
        color: theme.palette.text.placeholder,
        textAlign: 'center',
        fontSize: theme.typography.fontSize12,
        lineHeight: 1.2,
      },
      '& .DayPicker-Weekday abbr[title]': {
        borderBottom: 'none',
        textDecoration: 'none',
      },
      '& .DayPicker-Body ': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      },
      '& .DayPicker-Week': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 8px',
        width: '100%',
      },
      '& .DayPicker-Day': {
        width: 28,
        height: 28,
        fontSize: theme.typography.fontSize12,
        lineHeight: 1.2,
        fontWeight: theme.typography.fontWeightMedium,
        padding: '7px 4px',
        borderRadius: '50%',
        verticalAlign: 'middle',
        textAlign: 'center',
        cursor: 'pointer',
        '&.DayPicker-Day--today:not(.DayPicker-Day--outside)': {
          border: `1px solid ${theme.palette.primary.main}`,
        },
      },
      '& .DayPicker-WeekNumber': {
        display: 'table-cell',
        padding: '0.5em',
        minWidth: '1em',
        borderRight: `1px solid ${theme.palette.common.grey50}`,
        color: theme.palette.common.grey100,
        verticalAlign: 'middle',
        textAlign: 'right',
        fontSize: theme.typography.fontSize14,
        cursor: 'pointer',
      },
      '& .DayPicker--interactionDisabled .DayPicker-Day': {
        cursor: 'default',
      },
    },
    '& .DayPicker-NavButton': {
      position: 'absolute',
      top: '1em',
      right: '1.5em',
      left: 'auto',
      display: 'inline-block',
      marginTop: 2,
      width: '1.25em',
      height: '1.25em',
      backgroundPosition: 'center',
      backgroundSize: '50%',
      backgroundRepeat: 'no-repeat',
      color: theme.palette.common.grey100,
      cursor: 'pointer',
      '&:hover': {
        opacity: '0.8',
      },
      '& .DayPicker-NavButton--prev': {
        marginRight: '1.5em',
        backgroundImage:
          'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC")',
      },
      '& .DayPicker-NavButton--next': {
        backgroundImage:
          'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==")',
      },
      '& .DayPicker-NavButton--interactionDisabled': {
        display: 'none',
      },
      '& .DayPicker-Footer': {
        paddingTop: '0.5em',
      },
      '& .DayPicker-TodayButton': {
        border: 'none',
        backgroundColor: 'transparent',
        backgroundImage: 'none',
        boxShadow: 'none',
        color: theme.palette.common.blue300,
        fontSize: theme.typography.fontSize14,
        cursor: 'pointer',
      },
      '& .DayPicker-Day--today': {
        color: theme.palette.common.red400,
        fontWeight: 700,
      },
      '& .DayPicker-Day--outside': {
        color: theme.palette.common.grey100,
        cursor: 'default',
      },
      '& .DayPicker-Day--disabled': {
        color: theme.palette.common.grey100,
        cursor: 'default',
      },
      '& .DayPicker-Day--sunday': {
        backgroundColor: theme.palette.common.grey20,
      },
      '& .DayPicker-Day--sunday:not(.DayPicker-Day--today)': {
        color: theme.palette.common.grey50,
      },
      '& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)':
        {
          position: 'relative',
          backgroundColor: theme.palette.common.blue300,
          color: theme.palette.common.grey20,
        },
      '& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover':
        {
          backgroundColor: theme.palette.common.blue300,
        },
      '& .DayPicker:not(.DayPicker--interactionDisabled).DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover':
        {
          backgroundColor: theme.palette.common.grey20,
        },
      '& .DayPickerInput': {
        display: 'inline-block',
      },
      '& .DayPickerInput-OverlayWrapper': {
        position: 'relative',
      },
      '& .DayPickerInput-Overlay': {
        position: 'absolute',
        left: 0,
        zIndex: 1,
        background: 'white',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
      },
    },
  },
  datePickerWrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflowY: 'auto',
    '& .DayPicker-Day': {
      color: theme.palette.text.primary,
      outline: 'none',
    },
    '& .DayPicker-Day--disabled': {
      color: theme.palette.common.grey100,
    },
    '& .DayPicker-Day--end.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside), & .DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)':
      {
        position: 'relative',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.blue400,
        borderRadius: '50%',
        outline: 'none',
        fontSize: theme.typography.fontSize13,
        fontWeight: theme.typography.fontWeightSemiBold,
      },
    '&:not(.date-pickup-active) .DayPicker-Day--end.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):before, &:not(.date-pickup-active) .DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):after':
      {
        content: '" "',
        width: '50%',
        position: 'absolute',
        height: '100%',
        top: 0,
        opacity: 0.1,
        backgroundColor: theme.palette.primary.main,
      },
    '&:not(.date-pickup-active) .DayPicker-Day--end.DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):before, &:not(.date-pickup-active) .DayPicker-Day--start.DayPicker-Day--end.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):after':
      {
        content: 'none',
      },
    '&:not(.date-pickup-active) .DayPicker-Day--end.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):before':
      {
        left: -3,
      },
    '.dark &:not(.date-pickup-active) .DayPicker-Day--end.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):before':
      {
        left: -5,
      },
    '&:not(.date-pickup-active) .DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):after':
      {
        right: -4,
      },
    '& .DayPicker-Day--selected:not(.DayPicker-Day--outside)': {
      position: 'relative',
      backgroundColor: theme.palette.common.grey20,
      color: theme.palette.text.primary,
      borderRadius: 0,
      zIndex: theme.zIndex.zIndex10,
    },

    '& .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):before, & .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):after':
      {
        position: 'absolute',
        top: 0,
        width: '50%',
        height: '100%',
        content: '" "',
        backgroundColor: 'inherit',
        zIndex: -1,
      },
    '& .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):before':
      {
        left: -5,
      },
    '.dark & .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):before':
      {
        left: -6,
      },
    '& .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):after':
      {
        right: -4,
      },
    '.dark & .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):after':
      {
        right: -6,
      },
    '& .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):first-of-type:before, & .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):last-child:after':
      {
        content: 'none',
      },
    '& .DayPicker-Day--selected.DayPicker-Day--outside:first-of-type, & .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):first-of-type':
      {
        borderTopLeftRadius: 12,
        borderBottomLeftRadius: 12,
      },
    '& .DayPicker-Day--selected.DayPicker-Day--outside:last-child, & .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):last-child':
      {
        borderTopRightRadius: 12,
        borderBottomRightRadius: 12,
      },
    '& .DayPicker-Day--selected:not(.DayPicker-Day--outside).DayPicker-Day--disabled':
      {
        backgroundColor: theme.palette.common.grey20,
        borderRadius: 0,
        color: theme.palette.common.grey100,
      },
    '& .DayPicker': {
      display: 'block',
      width: '100%',
    },
    '& .DayPicker-Month': {
      width: '100%',
      margin: 0,
      marginTop: 8,
    },
    '& .DayPicker-Caption': {
      display: 'table-caption',
      marginBottom: '0.5em',
      padding: '0 0.5em',
      textAlign: 'center',
      '& > div': {
        color: theme.palette.common.grey400,
        fontSize: theme.typography.fontSize13,
        lineHeight: 1.5,
        fontWeight: theme.typography.fontWeightSemiBold,
      },
    },
    '& .DayPicker-wrapper': {
      padding: 0,
    },
    '& .DayPicker-NavBar': {
      position: 'absolute',
      top: 10,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      '&.rtl > button img': {
        transform: 'rotate(180deg)',
      },
    },
  },
  navBtn: {
    width: 36,
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    color: theme.palette.text.placeholder,
    cursor: 'pointer',
    '& img': {
      width: 14,
      height: 14,
    },
  },
  snackbar: {
    padding: 16,
    backgroundColor: theme.palette.secondary.main,
    '& > .MuiTypography-root': {
      fontSize: theme.typography.fontSize13,
      lineHeight: 1.6,
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightSemiBold,
    },
  },
  shake: {
    animation: `${shakeHorizontal} 0.3s 2 ${theme.transitions.easing.easeInOut}`,
  },
});

export default styles;
