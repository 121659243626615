const styles = (theme) => ({
  noFoundWrapper: {
    padding: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fleetImg: {
    width: '100%',
    maxWidth: 124,
    marginBottom: 24,
  },
  contentWrapper: {
    textAlign: 'center',
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize22,
    lineHeight: 1,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 8,
  },
  text: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize26,
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: 8,
  },
  btnWrapper: {
    margin: 0,
    textAlign: 'center',
  },
  allCityBtn: {
    textTransform: 'none',
  },
});

export default styles;
