import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import getLocaleRoute from '@lumirental/lumi-web-shared/lib/utils/getLocaleRoute';

import styles from './FreeCarDeliveryCard.style';

const useStyles = makeStyles()(styles);

export default function FreeCarDeliveryCard({
  language,
  carDeliveryInfo,
  findOutCta,
  handleFindMore,
}) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const searchParams = useSearchParams();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div className={classes.contentInner}>
          <div className={classes.cardIcon}>
            <img src="/images/svg/car-delivery.svg" alt="Lumi Car Delivery" />
          </div>
          <div className={classes.textContent}>
            <Typography variant="h4" className={classes.cardTitle}>
              {t('free_car_delivery')}
            </Typography>
            {carDeliveryInfo && (
              <Typography className={classes.cardText}>
                {carDeliveryInfo}
              </Typography>
            )}
            {findOutCta && (
              <Link
                passHref
                href={`${getLocaleRoute(
                  language,
                  'cardelivery',
                )}?${searchParams}`}
                legacyBehavior
              >
                <Button
                  variant="text"
                  className={classes.findMoreBtn}
                  data-testid="carDeliveryFindMore"
                  onClick={handleFindMore}
                >
                  {findOutCta}
                </Button>
              </Link>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
