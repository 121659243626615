const styles = (theme) => ({
  cardWrapper: {
    textDecoration: 'none',
    height: '100%',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: 16,
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 16,
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'start',
    paddingRight: 16,
    textAlign: 'left',
  },
  mapIcon: {
    marginTop: 4,
    minWidth: 14,
    marginRight: 12,
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  directionBtn: {
    display: 'inline-flex',
    padding: 0,
    fontWeight: theme.typography.fontWeightRegular,
    minWidth: 110,
    whiteSpace: 'nowrap',
    marginRight: -8,
    textTransform: 'none',
  },
  branchIcon: {
    filter:
      'invert(12%) sepia(2%) saturate(8%) hue-rotate(317deg) brightness(108%) contrast(82%)',
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  timingSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: theme.palette.text.placeholder,
    fontSize: theme.typography.fontSize12,
    lineHeight: 1.2,
    '&:not(:last-child)': {
      marginBottom: 8,
    },
    '& > span': {
      '&:first-of-type': {
        width: '50%',
        textAlign: 'left',
      },
    },
  },
  timingSummaryTime: {
    paddingLeft: 12,
    textAlign: 'right',
  },
  timingGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export default styles;
