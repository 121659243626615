import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import STORAGE_KEYS from '@lumirental/lumi-web-sdk/dist/constants/app/STORAGE_KEYS';
import DROPOFF_BRANCH_CATEGORY from '@lumirental/lumi-web-shared/lib/constants/app/DROPOFF_BRANCH_CATEGORY';
import SEARCH_DEBOUNCE_TIME from '@lumirental/lumi-web-shared/lib/constants/app/SEARCH_DEBOUNCE_TIME';
import SUB_SCREENS_NAME from '@lumirental/lumi-web-shared/lib/constants/app/SUB_SCREENS_NAME';
import CUSTOM_ERROR_CODES from '@lumirental/lumi-web-shared/lib/constants/errorCodes/CUSTOM_ERROR_CODES';
import LOCATION_ERROR_CODES from '@lumirental/lumi-web-shared/lib/constants/errorCodes/LOCATION_ERROR_CODES';
import useAPIError from '@lumirental/lumi-web-shared/lib/hooks/useAPIError';
import checkLength from '@lumirental/lumi-web-shared/lib/utils/checkLength';
import apiError from '@lumirental/lumi-web-shared/lib/utils/gtm/apiError';
import branchNearMeTapped from '@lumirental/lumi-web-shared/lib/utils/gtm/branchNearMeTapped';
import branchSelected from '@lumirental/lumi-web-shared/lib/utils/gtm/branchSelected';
import noSearchResults from '@lumirental/lumi-web-shared/lib/utils/gtm/noSearchResults';
import searchPerformed from '@lumirental/lumi-web-shared/lib/utils/gtm/searchPerformed';

import { DifferentCityRegionDialog } from '@lumirental/lumi-web-components-ui';

import { LocationType } from '@/types/Location';
import { useUrl } from '@/utils/useUrl';

import Transition from '../../../animations/transition';
import BranchListWrapper from '../../BranchListWrapper';
import HeaderBackAndroidIcon from '../../Icons/HeaderBackAndroid';
import CityList from '../../Lists/CityList';
import RecentSearch from '../../RecentSearch';
import SearchBranchWrapper from '../../SearchBranchWrapper';
import LocationDenyInfoDialog from '../LocationDenyInfo';

import styles from './SearchBranch.style';

const useStyles = makeStyles()(styles);

let searchText = '';

export default function SearchDropOffBranch({
  cityProps,
  locationProps,
  searchPageProps,
  branchProps,
  language,
  detectLocation,
  fetchBranch,
  isSwitchOpen,
  dropOffCharges,
  setOpenDifferentCityReturnDialog,
  openDifferentCityDialogSuccess,
  onBranchDialogHide,
}) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { changeParams } = useUrl();

  // local states
  const [searchValue, setSearchValue] = useState('');
  const [showBranchDialog, setShowBranchDialog] = useState(false);
  const [showLocationBtn, setShowLocationBtn] = useState(false);
  const [openLocationDenyInfoDialog, setOpenLocationDenyInfoDialog] =
    useState(false);

  const [showRecentSearch, setShowRecentSearch] = useState(false);

  const showBranchList = checkLength(searchValue);

  // read props for csr
  const {
    isBranchLoading,
    isBranchSuccess,
    sortedDeliveryCityIdHashMap,
    sortedPickUpCityIdHashMap,
    branchErrorData,
    setInitialState,
    searchType,
  } = branchProps;
  const errorCode = _get(branchErrorData, 'data.code', '');
  const { errorMessage: branchErrorMessage } = useAPIError(errorCode);
  const { cityErrorData } = cityProps;
  const { errorMessage: cityErrorMessage } = useAPIError(
    _get(cityErrorData, 'data.code', ''),
  );

  const {
    pickupBranch,
    dropoffBranch,
    setDropOffBranch,
    isDeliveryTabActive,
    getCityListByActiveTab,
    getBranchesByCityId,
    getBranchByBranchId,
  } = searchPageProps;

  const dropoffBranchName = _get(dropoffBranch, 'name', '');
  const { dropoff, ...restLocationProps } = locationProps || {};

  const {
    isLocationLoading,
    isLocationSuccess,
    hasLocationFailed,
    lat,
    long,
    locationErrorData,
    clearLocationProps,
  } = dropoff || restLocationProps;

  // drived value
  const screenName = SUB_SCREENS_NAME.SEARCH;
  const filterCityList = getCityListByActiveTab && getCityListByActiveTab();
  const isCarDelivery = isDeliveryTabActive;
  const sortedCityIdsHasMap = isCarDelivery
    ? sortedDeliveryCityIdHashMap
    : sortedPickUpCityIdHashMap;

  let recentSearch;
  // nextjs is render server side so we get localStorage value
  // once comp. is render client side.
  if (typeof window !== 'undefined') {
    // Perform localStorage action
    recentSearch = JSON.parse(localStorage.getItem(STORAGE_KEYS.RECENT_SEARCH));
  }

  // get branches for branchId's
  const recentSearchBranches = useMemo(
    () =>
      recentSearch &&
      recentSearch.flatMap(
        (branchId) =>
          (getBranchByBranchId && getBranchByBranchId(branchId)) || [],
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showRecentSearch],
  );

  /**
   * runs on the client side
   * clears search field on every dialog open
   */
  useEffect(() => {
    if (showBranchDialog) {
      handleClearSearch();
      // set branch store to initial state
      // commment by:vikas kumar - for now we don't need to set initial state when dialog is open
      // setInitialState && setInitialState(); // update mobx store
      // clearLocationProps && clearLocationProps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBranchDialog]);

  /**
   * on mount
   * runs on the client side
   * checks if geolocation is supported
   */
  useEffect(() => {
    if ('geolocation' in navigator) {
      setShowLocationBtn(true);
    }
  }, []);

  /**
   * location success hook
   * runs on the client side
   * calls branch api with location
   */
  useEffect(() => {
    if (lat && long) {
      fetchBranch('', lat, long, handleError, 'near me');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, long]);
  /**
   * location failed hook
   * runs on the client side
   */
  useEffect(() => {
    if (hasLocationFailed) {
      const errCode = _get(locationErrorData, 'code', -1);
      if (
        errCode === LOCATION_ERROR_CODES.PERMISSION_DENIED ||
        errCode === LOCATION_ERROR_CODES.POSITION_UNAVAILABLE ||
        errCode === LOCATION_ERROR_CODES.TIMEOUT
      ) {
        // show error dialog
        toggleLocationDenyInfoDialog();
      } else {
        console.log(`Location Failed Unknown Error: `, locationErrorData);
      }
      // setLocationClicked(false); // update local state
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLocationFailed, locationErrorData]);

  useEffect(() => {
    if (openDifferentCityDialogSuccess) {
      setShowBranchDialog(false);
      onBranchDialogHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDifferentCityDialogSuccess]);
  /**
   * Handles branch search api error from sdk store
   * @param {AxiosError} err
   */
  const handleError = (err) => {
    console.log('Error response branch search: ', err);

    // send GTM event when API give error.
    apiError(err);

    const errorCode = _get(err, 'data.code', '');
    // send GTM event when branch search returns no results
    if (searchText && errorCode === CUSTOM_ERROR_CODES.BR404) {
      noSearchResults(screenName, searchText, dropoffBranch);
    }
  };

  /**
   * Open search dialog
   */
  const handleSelectDropoff = () => {
    setShowBranchDialog(true);
  };

  /**
   * Close search dialog
   */
  const handleCloseDialog = () => {
    setShowBranchDialog(false);
  };

  /**
   * Handle dialog close on back tap
   */
  const handleBack = () => {
    handleCloseDialog();
    clearLocationProps && clearLocationProps();
    handleCloseRecentSearch();
  };

  /**
   * Open/close location deny info  dialog
   */
  const toggleLocationDenyInfoDialog = () => {
    setOpenLocationDenyInfoDialog(!openLocationDenyInfoDialog);
  };

  /**
   * Handles search text clear on cross button click
   */
  const handleClearSearch = () => {
    setSearchValue('');
    searchText = '';

    setInitialState && setInitialState(); // update mobx store
    clearLocationProps && clearLocationProps();
  };

  /**
   * detects user location
   * runs on client side
   */
  const handleDetectLocation = () => {
    // send GTM event when branch near me tapped.
    branchNearMeTapped(screenName);

    // handleClearSearch(); // clear search before fetch location.
    // setLocationClicked(true); // update local state
    detectLocation(LocationType.DROPOFF);
  };

  /**
   * Debounced memoized function to make
   * search branch api call
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFn = useCallback(
    _debounce((searchText) => {
      fetchBranch(searchText);
      // send GTM event everytime a branch is searched
      searchPerformed(screenName, searchText);
    }, SEARCH_DEBOUNCE_TIME),
    [],
  );
  /**
   * Handles change text event for search text
   * @param {Event} evt
   */
  const handleChange = (event) => {
    const { value } = event.target;

    const lengthCheck = checkLength(value);
    setSearchValue(value);
    searchText = value;
    clearLocationProps();
    if (!value) {
      changeParams({
        // pickUpBranch: -1,
        dropOffBranch: -1,
        lat: 'null',
        long: 'null',
        search: '',
      });
    }
    if (value && lengthCheck) {
      debouncedFn(value);
    } else {
      // clears branch store data when user types less than 3 chars
      // set branch store to initial state
      // setInitialState && setInitialState(); // update mobx store
      handleOpenRecentSearch();
    }
  };

  /**
   * Called when branch item is clicked
   * for both branch list & city list
   * @param {object} branch
   * @param {string} selectionType
   */
  const handleBranchClick = (branch, type) => {
    if (isSwitchOpen && pickupBranch?.id && pickupBranch?.id !== branch.id) {
      setDropOffBranch(branch); // update global store
      dropOffCharges?.fetchDropoffCharges({
        pickupBranchId: pickupBranch.id,
        dropOffBranchId: branch.id,
      });
      setOpenDifferentCityReturnDialog(true);
    } else {
      setDropOffBranch(branch); // update global store
      handleCloseRecentSearch(); // close recent search
      handleCloseDialog(); // close search dialog
      sendGTMEvent(branch, type);
      setShowBranchDialog(false);
    }
  };

  const sendGTMEvent = (branch, selectionType) => {
    // send GTM event when branch is selected
    branchSelected(screenName, branch, selectionType || searchType, searchText);
  };

  const handleCloseRecentSearch = () => {
    setShowRecentSearch(false);
  };

  const handleOpenRecentSearch = () => {
    setShowRecentSearch(true);
  };

  const handleSearchFocus = (event) => {
    const { value } = event.target;
    if (!value) {
      handleOpenRecentSearch();
    }
  };

  const handleViewAllCity = () => {
    handleCloseRecentSearch();
    clearLocationProps && clearLocationProps();
    setInitialState && setInitialState(); // update mobx store

    handleClearSearch();
  };

  return (
    <>
      <TextField
        fullWidth
        id="dropoff-location"
        placeholder={t('select_dropoff_location')}
        variant="outlined"
        value={dropoffBranchName}
        onClick={handleSelectDropoff}
        InputProps={{
          readOnly: true,
          classes: {
            input: classes.inputField,
            notchedOutline: classes.inputBorder,
          },
        }}
        data-testid="searchSelectDropOffBranch"
      />
      <Dialog
        fullScreen
        open={showBranchDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
        classes={{
          scrollPaper: classes.scrollPaper,
        }}
      >
        <AppBar position="static" className={classes.header}>
          <Toolbar>
            <div className={classes.searchWrapper}>
              <IconButton
                edge="end"
                size="small"
                className={classes.backIcon}
                onClick={handleBack}
                data-testid="searchBackBtn"
              >
                <HeaderBackAndroidIcon language={language} />
              </IconButton>
              <TextField
                placeholder={t('search_for_city_or_branch')}
                value={searchValue}
                onChange={handleChange}
                onFocus={handleSearchFocus}
                classes={{
                  root: classes.searchInput,
                }}
                data-testid="searchForCityBranch"
              />
              {searchValue && (
                <IconButton
                  edge="end"
                  size="small"
                  className={classes.searchCloseIcon}
                  onClick={handleClearSearch}
                >
                  <Image
                    height={24}
                    width={24}
                    src="/images/svg/close-marble-icon.svg"
                    alt="Clear Search"
                  />
                </IconButton>
              )}
            </div>
          </Toolbar>
        </AppBar>
        {(isBranchLoading || isLocationLoading) && <LinearProgress />}
        <DialogContent className={classes.mainWrapper}>
          {showRecentSearch && (
            <>
              {!showBranchList && (
                <RecentSearch
                  language={language}
                  branches={recentSearchBranches}
                  handleSelectItem={handleBranchClick}
                  searchValue={searchValue}
                  handleViewAllCity={handleViewAllCity}
                />
              )}
              <SearchBranchWrapper
                language={language}
                data={sortedCityIdsHasMap}
                handleSelectItem={handleBranchClick}
                searchValue={searchValue}
                branchErrorMessage={branchErrorMessage}
                getBranchesByCityId={getBranchesByCityId}
                showBranchList={showBranchList}
                isLocationSuccess={isLocationSuccess}
                isBranchSuccess={isBranchSuccess}
                handleViewAllCity={handleViewAllCity}
              />
            </>
          )}

          {!showRecentSearch && (
            <>
              {showLocationBtn && (
                <div className={classes.navigation}>
                  <Button variant="text" onClick={handleDetectLocation}>
                    <Image
                      width={18}
                      height={18}
                      src="/images/svg/navigation.svg"
                      alt="navigation-icon"
                    />
                    {t('find_branches_near_me')}
                  </Button>
                </div>
              )}

              <BranchListWrapper
                language={language}
                data={sortedCityIdsHasMap}
                handleSelectItem={handleBranchClick}
                searchValue={searchValue}
                branchErrorMessage={branchErrorMessage}
                getBranchesByCityId={getBranchesByCityId}
                showBranchList={showBranchList}
                isLocationSuccess={isLocationSuccess}
                isBranchSuccess={isBranchSuccess}
              />

              {!isLocationSuccess && (
                <CityList
                  language={language}
                  data={filterCityList}
                  getBranchesByCityId={getBranchesByCityId}
                  handleSelectItem={handleBranchClick}
                  isCarDelivery={isCarDelivery}
                />
              )}

              {cityErrorMessage && (
                <Typography className={classes.noData}>
                  {cityErrorMessage}
                </Typography>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
      <LocationDenyInfoDialog
        openDialog={openLocationDenyInfoDialog}
        handleCloseDialog={toggleLocationDenyInfoDialog}
      />
    </>
  );
}
