const styles = (theme) => ({
  headerWrapper: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
  },
  toolbar: {
    minHeight: 70,
  },
  logoWrapper: {
    marginBottom: 0,
    width: '100%',
    textAlign: 'center',
  },
  logo: {
    display: 'inline-block',
    cursor: 'pointer',
  },
});

export default styles;
