import DIR from '@lumirental/lumi-web-shared/lib/constants/lang/DIR';

const styles = (theme) => {
  const menuTransform =
    theme.direction === DIR.RTL ? 'rotate(180deg)' : 'rotate(0)';
  return {
    hamburgerIcon: {
      padding: 0,
      transform: menuTransform,
    },
  };
};

export default styles;
