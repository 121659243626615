const styles = (theme) => ({
  wrapper: {},
  searchFormWrapper: {},
  formSection: {
    margin: 0,
    padding: '24px 12px',
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    borderTopLeftRadius: 0,
    '.dark &': {
      padding: '16px 12px',
      paddingBottom: 0,
      backgroundColor: 'transparent',
      margin: '0 -24px',
      width: 'auto',
    },
  },
  fieldRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 0 24px 0',
    '.dark &': {
      padding: '0 0 16px 0',
    },
  },
  branchField: {
    padding: '0px 12px',
    '&.MuiTextField-root .MuiFormLabel-root': {
      top: 5,
      left: 26,
      '&.Mui-focused': {
        top: -5,
        left: 12,
      },
    },
  },
  inputField: {
    padding: '16px 14px',
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.1,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightSemiBold,
    '&::placeholder': {
      color: theme.palette.common.grey300,
      fontWeight: theme.typography.fontWeightRegular,
    },
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  inputLabel: {
    color: theme.palette.common.grey300,
  },
  inputBorder: {
    borderColor: theme.palette.common.grey20,
  },
  switchWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: '0 12px',
    marginTop: 8,
    '.dark &': {
      marginTop: 0,
    },
  },
  switchSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .MuiSwitch-root': {
      width: 52,
      height: 32,
      padding: 0,
      overflow: 'visible',
      '& .MuiSwitch-switchBase': {
        top: -6,
        left: -7,
        '&.Mui-checked': {
          top: -6,
          left: -5,
        },
      },
    },
  },
  switchLabel: {
    marginLeft: 8,
    color: theme.palette.common.grey300,
    fontSize: 15,
    lineHeight: 1.3,
    '.dark &': {
      color: theme.palette.common.white,
    },
  },
  switchThumb: {
    width: 26,
    height: 26,
    backgroundColor: theme.palette.common.white,
    boxShadow:
      '0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06)',
  },
  switchTrack: {
    backgroundColor: theme.palette.common.grey100,
    borderRadius: 40,
    '.dark &': {
      opacity: 1,
      backgroundColor: theme.palette.common.grey20,
    },
    '.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + &': {
      opacity: 1,
      backgroundColor: theme.palette.common.green200,
    },
  },
  dateField: {
    width: '100%',
    maxWidth: '25%',
    padding: '0 12px',
  },
  timeField: {
    width: '100%',
    maxWidth: '25%',
    padding: '0 12px',
  },
  btnWrapper: {
    minWidth: 340,
  },
});

export default styles;
