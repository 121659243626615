import { ReactElement } from 'react';

import classes from './Responsive.module.css';

const DesktopOnly = ({
  children,
  className = '',
}: {
  children: ReactElement;
  className?: string;
}): ReactElement => {
  return (
    <div className={`${classes.desktopOnly} ${className}`}>{children}</div>
  );
};

export default DesktopOnly;
