import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import { useSearchParams } from 'next/navigation';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import STORAGE_KEYS from '@lumirental/lumi-web-sdk/dist/constants/app/STORAGE_KEYS';
import SEARCH_DEBOUNCE_TIME from '@lumirental/lumi-web-shared/lib/constants/app/SEARCH_DEBOUNCE_TIME';
import SUB_SCREENS_NAME from '@lumirental/lumi-web-shared/lib/constants/app/SUB_SCREENS_NAME';
import CUSTOM_ERROR_CODES from '@lumirental/lumi-web-shared/lib/constants/errorCodes/CUSTOM_ERROR_CODES';
import LOCATION_ERROR_CODES from '@lumirental/lumi-web-shared/lib/constants/errorCodes/LOCATION_ERROR_CODES';
import useAB from '@lumirental/lumi-web-shared/lib/hooks/useAB';
import useAPIError from '@lumirental/lumi-web-shared/lib/hooks/useAPIError';
import checkLength from '@lumirental/lumi-web-shared/lib/utils/checkLength';
import getUniqueArray from '@lumirental/lumi-web-shared/lib/utils/getUniqueArray';
import apiError from '@lumirental/lumi-web-shared/lib/utils/gtm/apiError';
import branchNearMeTapped from '@lumirental/lumi-web-shared/lib/utils/gtm/branchNearMeTapped';
import branchSelected from '@lumirental/lumi-web-shared/lib/utils/gtm/branchSelected';
import noSearchResults from '@lumirental/lumi-web-shared/lib/utils/gtm/noSearchResults';
import searchPerformed from '@lumirental/lumi-web-shared/lib/utils/gtm/searchPerformed';

import { LocationType } from '@/types/Location';
import { useUrl } from '@/utils/useUrl';

import BranchListWrapper from '../BranchListWrapper';
import CarDeliveryTooltip from '../CarDeliveryTooltip';
import CityList from '../Lists/CityList';
import LocationDenyInfoDialog from '../LocationDenyInfo';
import RecentSearch from '../RecentSearch';

import styles from './BranchPicker.style';

const useStyles = makeStyles()(styles);

let searchText = '';
export default function PickUpBranchPicker({
  cityProps,
  locationProps,
  searchPageProps,
  branchProps,
  language,
  detectLocation,
  fetchBranch,
  textInputRef,
  id,
  isSwitchOpen,
  defaultValue,
  dropoffCityId,
  isDropOffBranchSelected,
  isListingScreen,
  dropOffCharges,
  setOpenDifferentCityReturnDialog,
}) {
  // read props for csr
  const {
    getBranchesByCityId,
    setPickUpBranch,
    isDeliveryTabActive,
    getCityListByActiveTab,
    carDeliveryBranchList,
    getBranchByBranchId,
    dropoffBranch,
  } = searchPageProps;

  const searchParams = useSearchParams();
  const search = searchParams.get('search') ?? '';

  const [showBranchPopover, setShowBranchPopover] = useState(false);
  const [searchValue, setSearchValue] = useState(
    defaultValue === '' ? search : defaultValue,
  );
  const defaultValRef = useRef(searchValue);
  const [searchClicked, setSearchClicked] = useState(false);
  const [showLocationBtn, setShowLocationBtn] = useState(false);
  const [openLocationDenyInfoDialog, setOpenLocationDenyInfoDialog] =
    useState(false);
  const [locationClicked, setLocationClicked] = useState(false);
  const [showRecentSearch, setShowRecentSearch] = useState(false);

  const wrapperRef = useRef(null);
  const { classes } = useStyles();
  const { t } = useTranslation();

  const testId = `searchSelect${id}`;
  const showBranchList = checkLength(search);

  const screenName = SUB_SCREENS_NAME.SEARCH;

  const { cityErrorData } = cityProps;

  const {
    isBranchLoading,
    isBranchSuccess,
    branchErrorData,
    setInitialState,
    searchType,
    sortedQueryPickUpCityIdHashMap,
    sortedQueryDeliveryCityIdHashMap,
  } = branchProps;

  const { pickup } = locationProps || {};

  const {
    isLocationLoading,
    isLocationSuccess,
    hasLocationFailed,
    lat,
    long,
    locationErrorData,
  } = pickup || locationProps;

  const errorCode = _get(branchErrorData, 'data.code', '');
  const { errorMessage: branchErrorMessage } = useAPIError(errorCode);
  const { errorMessage: cityErrorMessage } = useAPIError(
    _get(cityErrorData, 'data.code', ''),
  );

  const { changeParams } = useUrl();
  // drived value
  const filterCityList = getCityListByActiveTab && getCityListByActiveTab();
  const isCarDelivery = useAB('free_car_delivery_tag') && isDeliveryTabActive;

  const sortedCityIdsHasMap = isCarDelivery
    ? sortedQueryDeliveryCityIdHashMap
    : sortedQueryPickUpCityIdHashMap;

  const pickUpLabel = isCarDelivery
    ? t('delivery_location_web')
    : t('pickup_location');

  const pickUpPlaceholder = isCarDelivery
    ? t('select_delivery_web')
    : t('search_for_city_or_branch');

  const hasBranch = !_isEmpty(sortedCityIdsHasMap);
  const hasSearch = !!searchValue;
  const isSearchResult = hasSearch && isBranchSuccess && hasBranch;

  let recentSearch;
  // nextjs is render server side so we get localStorage value
  // once comp. is render client side.
  if (typeof window !== 'undefined') {
    // Perform localStorage action
    recentSearch = JSON.parse(localStorage.getItem(STORAGE_KEYS.RECENT_SEARCH));
  }

  // get branches for branchId's
  const recentSearchBranches = useMemo(() => {
    return (
      recentSearch &&
      recentSearch.flatMap(
        (branchId) =>
          (getBranchByBranchId && getBranchByBranchId(branchId)) || [],
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRecentSearch, isCarDelivery]);

  const hasRecentSearchBranches = !_isEmpty(recentSearchBranches);

  /**
   * on mount
   * runs on client side
   */
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);

    // checks if geolocation is supported
    if ('geolocation' in navigator) {
      setShowLocationBtn(true);
    }

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * effect to persist the text input value
   */
  useEffect(() => {
    const val = defaultValue === '' ? search : defaultValue;
    defaultValRef.current = val;
    setSearchValue(val);
  }, [defaultValue, search]);

  /**
   * location success - runs on the client side
   * calls branch api with location
   */
  useEffect(() => {
    if (isLocationSuccess && locationClicked) {
      fetchBranch('', lat, long, handleError, 'near me');
      setLocationClicked(false); // update local state
      // set branch store to initial state
      setInitialState && setInitialState(); // update mobx store
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationClicked, isLocationSuccess, lat, long, setInitialState]);

  /**
   * location failed - runs on the client side
   * shows alert for now
   */
  useEffect(() => {
    if (hasLocationFailed && locationClicked) {
      const errCode = _get(locationErrorData, 'code', -1);
      if (
        errCode === LOCATION_ERROR_CODES.PERMISSION_DENIED ||
        errCode === LOCATION_ERROR_CODES.POSITION_UNAVAILABLE ||
        errCode === LOCATION_ERROR_CODES.TIMEOUT
      ) {
        // show error dialog
        toggleLocationDenyInfoDialog();
      } else {
        console.log(`Location Failed Unknown Error: `, locationErrorData);
      }
      setLocationClicked(false); // update local state
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationClicked, hasLocationFailed, locationErrorData]);

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setShowBranchPopover(false);
      // setSearchValue(defaultValRef.current); // reset the last selected branch name into text field
      setInitialState && setInitialState(); // update mobx store
      setSearchClicked(false);
    }
  };

  const handleBranchOpen = () => {
    if (errorCode) {
      // set branch store to initial state
      setInitialState && setInitialState(); // update mobx store
    }
    setShowBranchPopover(true);
    handleOpenRecentSearch();
  };

  /**
   * detects user location
   * runs on client side
   */
  const handleDetectLocation = () => {
    // send GTM event when branch near me tapped.
    branchNearMeTapped(screenName);

    // clear search before fetch location.
    // handleClearSearch();
    setLocationClicked(true); // update local state
    detectLocation(LocationType.PICKUP);
  };

  /**
   * Handles search text clear on cross button click
   */
  // const handleClearSearch = () => {
  //   setSearchValue('');
  // };

  /**
   * Handles branch search api error from sdk store
   * @param {AxiosError} err
   */
  const handleError = (err) => {
    console.log('pickup branch search error: ', err);

    // send GTM event when API give error.
    apiError(err);

    const errorCode = _get(err, 'data.code', '');
    // send GTM event when branch search returns no results
    if (searchText && errorCode === CUSTOM_ERROR_CODES.BR404) {
      noSearchResults(screenName, searchText);
    }
  };

  /**
   * Debounced memoized function to make
   * search branch api call
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFn = useCallback(
    _debounce((searchText) => {
      fetchBranch(searchText, 0, 0, handleError, 'search');
      // send GTM event everytime a branch is searched
      searchPerformed(screenName, searchText);
    }, SEARCH_DEBOUNCE_TIME),
    [],
  );

  /**
   * Handles change text event for search text
   * @param {Event} evt
   */
  const handleChange = (event) => {
    const { value } = event.target;
    // const searchText = value.replace(/^\s+|\s+$/g, '');
    const lengthCheck = checkLength(value);
    setSearchValue(value);
    searchText = value;
    if (!value) {
      changeParams({
        pickUpBranch: -1,
        // dropOffBranch: -1,
        lat: 'null',
        long: 'null',
        search: '',
      });
    }
    // clearLocationProps();
    if (value && lengthCheck) {
      debouncedFn(value);
      setSearchClicked(true);
    } else {
      // clears branch store data when user types less than 3 chars
      // set branch store to initial state
      setInitialState && setInitialState(); // update mobx store
      setSearchClicked(false);
    }
  };

  /**
   * Called for both branch list & city list
   * @param {object} branch
   * @param {string} selectionType
   */
  const handleBranchSelect = (branch, selectionType) => {
    // console.log('branch click: ', branch, selectionType);
    const name = _get(branch, 'name', '');
    const pickupBranchId = _get(branch, 'id', -1);

    if (isSwitchOpen && dropoffBranch?.id && dropoffBranch?.id !== branch.id) {
      setPickUpBranch(branch); // update global store
      dropOffCharges?.fetchDropoffCharges({
        pickupBranchId: branch.id,
        dropOffBranchId: dropoffBranch.id,
      });
      // show diff city return dialog
      setOpenDifferentCityReturnDialog(true);
    } else {
      defaultValRef.current = name;
      setSearchValue(name); // update local state
      setShowBranchPopover(false); // hide search panel
      setPickUpBranch(branch); // update global store
      handleCloseRecentSearch(); // close recent search

      setBranchInLocalStorage(pickupBranchId); // set branchId in local Storage.
      if (searchClicked) {
        setInitialState && setInitialState(); // update mobx store
        setSearchClicked(false);
      }
    }

    /**
     * clears branch store on before search panel is closed
     * only if previous selection was city list
     * this is to make sure no previous search result is shown
     */
    if (selectionType === 'city') {
      // set branch store to initial state
      setInitialState && setInitialState(); // update mobx store
    }

    // send GTM event when branch is selected
    branchSelected(screenName, branch, selectionType || searchType, searchText);
  };

  const toggleLocationDenyInfoDialog = () => {
    setOpenLocationDenyInfoDialog(!openLocationDenyInfoDialog);
  };

  const handleCloseRecentSearch = () => {
    setShowRecentSearch(false);
  };

  const handleOpenRecentSearch = () => {
    setShowRecentSearch(true);
  };

  const setBranchInLocalStorage = (branchId) => {
    let recentSearch = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.RECENT_SEARCH),
    );

    if (recentSearch) {
      recentSearch.unshift(branchId);
      recentSearch = getUniqueArray(recentSearch);

      // remove last Item from array.
      if (recentSearch.length > 3) {
        recentSearch.pop();
      }
    }
    // set branchId in local storage.
    // using native javascript localstorage feature.
    localStorage.setItem(
      STORAGE_KEYS.RECENT_SEARCH,
      JSON.stringify(recentSearch || [branchId]),
    );
  };

  return (
    <>
      <div ref={wrapperRef} className={classes.wrapper}>
        <span className={classes.fieldLabel}>{pickUpLabel}</span>
        {isCarDelivery && (
          <CarDeliveryTooltip
            carDeliveryBranchList={carDeliveryBranchList}
            isListingScreen={isListingScreen}
          />
        )}
        <TextField
          inputRef={textInputRef}
          fullWidth
          value={searchValue}
          variant="standard"
          placeholder={pickUpPlaceholder}
          onClick={handleBranchOpen}
          InputProps={{
            classes: {
              input: classes.inputField,
            },
          }}
          data-testid={testId}
          onChange={handleChange}
          onFocus={(event) => {
            event.target.select();
          }}
        />
        {showBranchPopover && (
          <>
            <div className={classes.branchWrapper}>
              {(isBranchLoading || isLocationLoading) && <LinearProgress />}
              {showLocationBtn &&
                !((isSearchResult && searchClicked) || branchErrorMessage) && (
                  <div className={classes.navigation}>
                    <Button
                      variant="text"
                      className={classes.branchNearBtn}
                      onClick={handleDetectLocation}
                    >
                      <Image
                        height={18}
                        width={18}
                        src="/images/svg/navigation.svg"
                        alt="navigation-icon"
                      />
                      {t('find_branches_near_me')}
                    </Button>
                  </div>
                )}
              {!(isSearchResult || branchErrorMessage || isLocationSuccess) &&
                hasRecentSearchBranches && (
                  <RecentSearch
                    branches={recentSearchBranches}
                    handleSelectItem={handleBranchSelect}
                  />
                )}
              <BranchListWrapper
                language={language}
                data={sortedCityIdsHasMap}
                handleSelectItem={handleBranchSelect}
                searchValue={searchValue}
                branchErrorMessage={branchErrorMessage}
                showBranchList={showBranchList}
                isLocationSuccess={isLocationSuccess}
                isBranchSuccess={isBranchSuccess}
                isSwitchOpen={isSwitchOpen}
                getBranchesByCityId={getBranchesByCityId}
                searchClicked={searchClicked}
              />
              {!(cityErrorMessage || isLocationSuccess) && (
                <CityList
                  language={language}
                  data={filterCityList}
                  isSwitchOpen={isSwitchOpen}
                  getBranchesByCityId={getBranchesByCityId}
                  handleSelectItem={handleBranchSelect}
                  isCarDelivery={isCarDelivery}
                />
              )}
              {cityErrorMessage && (
                <Typography className={classes.noData}>
                  {cityErrorMessage}
                </Typography>
              )}
            </div>
          </>
        )}
      </div>
      <LocationDenyInfoDialog
        openDialog={openLocationDenyInfoDialog}
        handleCloseDialog={toggleLocationDenyInfoDialog}
      />
    </>
  );
}
