/* eslint-disable */
/**
 * To-Do: disabling linting for entire file as this is a 3rd party module
 * We have imported into our own project
 * since the support for module was not there.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';

import getArabicAmPM from '@lumirental/lumi-web-shared/lib/utils/date/getArabicAmPM';
import LANGUAGES from '@lumirental/lumi-web-shared/lib/constants/lang/LANGUAGES';
import CLOSED_BRANCH from '@lumirental/lumi-web-shared/lib/constants/app/CLOSED_BRANCH';

import styles from './Picker.style';

class PickerColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMoving: false,
      startTouchY: 0,
      startScrollerTranslate: 0,
      ...this.computeTranslate(props),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isMoving) {
      return;
    }
    this.setState(this.computeTranslate(nextProps));
  }

  computeTranslate = (props) => {
    const { options, value, itemHeight, columnHeight } = props;
    let selectedIndex = options.indexOf(value);
    // if (selectedIndex < 0) {
    //   // throw new ReferenceError();
    //   console.warn(
    //     `Warning: "${this.props.name}" doesn't contain an option of "${value}".`
    //   );
    //   this.onValueSelected(options[0]);
    //   selectedIndex = 0;
    // }
    return {
      scrollerTranslate:
        columnHeight / 2 - itemHeight / 2 - selectedIndex * itemHeight,
      minTranslate:
        columnHeight / 2 - itemHeight * options.length + itemHeight / 2,
      maxTranslate: columnHeight / 2 - itemHeight / 2,
    };
  };

  onValueSelected = (newValue) => {
    this.props.onChange(this.props.name, newValue);
  };

  handleTouchStart = (event) => {
    const startTouchY = event.targetTouches[0].pageY;
    this.setState(({ scrollerTranslate }) => ({
      startTouchY,
      startScrollerTranslate: scrollerTranslate,
    }));
  };

  handleTouchMove = (event) => {
    event.preventDefault();
    const touchY = event.targetTouches[0].pageY;
    this.setState(
      ({
        isMoving,
        startTouchY,
        startScrollerTranslate,
        minTranslate,
        maxTranslate,
      }) => {
        if (!isMoving) {
          return {
            isMoving: true,
          };
        }

        let nextScrollerTranslate =
          startScrollerTranslate + touchY - startTouchY;
        if (nextScrollerTranslate < minTranslate) {
          nextScrollerTranslate =
            minTranslate - Math.pow(minTranslate - nextScrollerTranslate, 0.8);
        } else if (nextScrollerTranslate > maxTranslate) {
          nextScrollerTranslate =
            maxTranslate + Math.pow(nextScrollerTranslate - maxTranslate, 0.8);
        }
        return {
          scrollerTranslate: nextScrollerTranslate,
        };
      },
    );
  };

  handleTouchEnd = (event) => {
    if (!this.state.isMoving) {
      return;
    }
    this.setState({
      isMoving: false,
      startTouchY: 0,
      startScrollerTranslate: 0,
    });
    setTimeout(() => {
      const { options, itemHeight, onCloseHover } = this.props;
      const { scrollerTranslate, minTranslate, maxTranslate } = this.state;
      let activeIndex;
      if (scrollerTranslate > maxTranslate) {
        activeIndex = 0;
      } else if (scrollerTranslate < minTranslate) {
        activeIndex = options.length - 1;
      } else {
        activeIndex = -Math.floor(
          (scrollerTranslate - maxTranslate) / itemHeight,
        );
      }
      this.onValueSelected(options[activeIndex]);
      onCloseHover &&
        onCloseHover(options[activeIndex].includes(CLOSED_BRANCH));
    }, 0);
  };

  handleTouchCancel = (event) => {
    if (!this.state.isMoving) {
      return;
    }
    this.setState((startScrollerTranslate) => ({
      isMoving: false,
      startTouchY: 0,
      startScrollerTranslate: 0,
      scrollerTranslate: startScrollerTranslate,
    }));
  };

  handleItemClick = (option) => {
    if (option !== this.props.value) {
      this.onValueSelected(option);
    } else {
      this.props.onClick(this.props.name, this.props.value);
    }
  };

  renderItems() {
    const { language, options, itemHeight, closedText, value, onCloseHover } =
      this.props;
    return options.map((option, index) => {
      const isArabic = LANGUAGES.AR === language;
      const isClosed = option?.toString().includes(CLOSED_BRANCH);

      const onMouseOver = () => {
        onCloseHover && onCloseHover(isClosed);
      };
      const timeLabel = isClosed
        ? `${closedText} ${
            isArabic ? getArabicAmPM(options[index - 1]) : options[index - 1]
          } - ${
            isArabic ? getArabicAmPM(options[index + 1]) : options[index + 1]
          }`
        : // const timeLabel =
        option && isArabic // get time label in arabic if language is ar
        ? getArabicAmPM(option)
        : option;
      const style = {
        height: `${itemHeight}px`,
        lineHeight: `${itemHeight}px`,
      };
      const className = `picker-item${
        option === value ? ' picker-item-selected' : ''
      }${isClosed ? ' closed-item' : ''}`;
      const testId = option.toString();
      return (
        <div
          key={index}
          className={className}
          style={style}
          onClick={() => this.handleItemClick(option)}
          data-testid={testId}
          onMouseOver={onMouseOver}
        >
          {timeLabel}
        </div>
      );
    });
  }

  render() {
    const translateString = `translate3d(0, ${this.state.scrollerTranslate}px, 0)`;
    const style = {
      MsTransform: translateString,
      MozTransform: translateString,
      OTransform: translateString,
      WebkitTransform: translateString,
      transform: translateString,
    };
    if (this.state.isMoving) {
      style.transitionDuration = '0ms';
    }
    const { classes } = this.props;
    return (
      <div className={classes.pickerClm}>
        <div
          className="picker-scroller"
          style={style}
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
          onTouchEnd={this.handleTouchEnd}
          onTouchCancel={this.handleTouchCancel}
        >
          {this.renderItems()}
        </div>
      </div>
    );
  }
}

PickerColumn.propTypes = {
  language: PropTypes.string,
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  itemHeight: PropTypes.number.isRequired,
  columnHeight: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onCloseHover: PropTypes.func,
};

export default withStyles(styles)(PickerColumn);
