'use client';

import React, { useState } from 'react';
import Image from 'next/legacy/image';
import Link from 'next/link';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import { makeStyles } from 'tss-react/mui';

import { useLanguageContext } from '@lumirental/lumi-web-shared/lib/contexts/Language.context';
import getLocaleRoute from '@lumirental/lumi-web-shared/lib/utils/getLocaleRoute';

import HamburgerIcon from '@/components/Icons/Hamburger';
import SideMenu from '@/components/SideMenu';

import styles from '@/blocks/MobileHeader/MobileHeader.style';

const useStyles = makeStyles()(styles);

export default function MobileHeader() {
  const { classes } = useStyles();
  const { language } = useLanguageContext();

  const [openDrawer, setOpenDrawer] = useState(false);

  /**
   * Toggle side menu
   */
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <>
      <AppBar position="sticky" className={classes.headerWrapper}>
        <Toolbar className={classes.toolbar}>
          <HamburgerIcon handleClick={toggleDrawer} />
          <div className={classes.logoWrapper}>
            <Link href={getLocaleRoute(language, '')} legacyBehavior>
              <a className={classes.logo} alt="Lumi Logo" title="Lumi Logo">
                <Image
                  src="/images/svg/lumi-logo.svg"
                  alt="Lumi Logo"
                  width={78}
                  height={49}
                  quality={100}
                  priority
                />
              </a>
            </Link>
          </div>
        </Toolbar>
      </AppBar>
      <SideMenu
        openMenu={openDrawer}
        onCloseMenu={toggleDrawer}
        onLogout={toggleDrawer}
      />
    </>
  );
}
