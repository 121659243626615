const styles = (theme) => ({
  accordion: {
    boxShadow: 'none',
    margin: 0,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&.Mui-expanded': {
      margin: '8px 0',
    },
    '&:before': {
      display: 'none',
    },
  },
  accordionSummary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 72,
    width: '100%',
    padding: 8,
    marginTop: -1,
    paddingLeft: 16,
    paddingRight: 16,
    marginBottom: 0,
    '& .MuiButtonBase-root': {
      width: '100%',
      textAlign: 'left',
      display: 'block',
      minHeight: 40,
    },
    '&.Mui-expanded': {
      minHeight: 56,
    },
    '& .MuiAccordionSummary-content': {
      margin: '8px 0',
      '&.Mui-expanded': {
        margin: '8px 0',
      },
    },
    '&.active': {
      backgroundColor: theme.palette.common.blue50,
    },
  },
  accordionActiveSummary: {
    backgroundColor: theme.palette.common.blue50,
  },
  accordionDetails: {
    padding: '16px 0',
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap',
  },
});

export default styles;
