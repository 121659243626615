'use client';

import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/legacy/image';
import { usePathname, useRouter } from 'next/navigation';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import STORAGE_KEYS from '@lumirental/lumi-web-sdk/dist/constants/app/STORAGE_KEYS';
import $sessionStorage from '@lumirental/lumi-web-sdk/dist/services/session.storage.service';
import getTimeStampForDateTime from '@lumirental/lumi-web-sdk/dist/utils/store/getTimeStampForDateTime';
import SCREENS_NAME from '@lumirental/lumi-web-shared/lib/constants/app/SCREENS_NAME';
import useAB from '@lumirental/lumi-web-shared/lib/hooks/useAB';
import isDropOffValid from '@lumirental/lumi-web-shared/lib/utils/isValidDropOff';
import validateSearch from '@lumirental/lumi-web-shared/lib/utils/validateSearch';

import { searchTapped } from '@/utils/gtm';
import { useUrl } from '@/utils/useUrl';

import InvalidSearchDialog from '@/components/Dialogs/InvalidSearch/InvalidSearchDialog';
import DesktopOnly from '@/components/Responsive/DesktopOnly';
import MobileOnly from '@/components/Responsive/MobileOnly';

import FindBranchBlock from '../SearchWidget/components/FindBranch';
import FindCarForm from '../SearchWidget/components/FindCar/FindCarForm';

import { useMobileSearch } from './useMobileSearch';

import desktopStyles from '@/blocks/MobileSearch/DesktopSearchBlock.style';
import styles from '@/blocks/MobileSearch/MobileSearchBlock.style';

const useStyles = makeStyles()(styles as unknown as any);
const useDesktopStyles = makeStyles()(desktopStyles as unknown as any);

function prerenderPage(url: string) {
  // Create a new link element
  const link = document.createElement('link');
  link.rel = 'prerender';
  link.href = url;

  // Append to the head section
  document.head.appendChild(link);
}

interface MobileSearchBlockProps {
  textInputRef?: any;
  moveTip?: boolean;
  branch?: Record<string, any>;
  cityList: any[];
  title: string;
  subTitle: string;
  language: string;
  branchList: any[];
  slug?: string;
  handleSearchCTA?: (...e: any) => void;
}

export default function MobileSearchBlock({
  textInputRef = null,
  moveTip = false,
  branch = null,
  branchList,
  cityList,
  title,
  subTitle,
  language,
  slug = null,
  handleSearchCTA = null,
}: MobileSearchBlockProps): ReactElement {
  const { classes } = useStyles();
  const { classes: desktopClasses } = useDesktopStyles();

  const [openInvalidSearchDialog, setOpenInvalidSearchDialog] = useState(false);
  const router = useRouter();
  const pathName = usePathname();
  const { changeParams } = useUrl();
  const { t, i18n } = useTranslation();
  const isDeliveryFeatureFlag = useAB('free_car_delivery_tag');

  const {
    searchPageProps,
    branchProps,
    locationProps,
    cityProps,
    detectLocation,
    isDeliveryTabActive,
    dropOffCharges,
  } = useMobileSearch(branch, cityList, branchList, i18n.language, slug);

  const {
    setSearchDataUpdated,
    pickupBranch,
    dropoffBranch,
    pickupDate,
    dropoffDate,
    pickupTime,
    dropoffTime,
    isSameDay,
    minDaysOfDeliveryDropOff,
    isDropOffBranchSame,
    activeTabIndex,
    isSwitchOn,
  } = searchPageProps;

  const isInvalidDeliveryDropOff =
    isDeliveryFeatureFlag &&
    !isDropOffValid(pickupDate, dropoffDate, minDaysOfDeliveryDropOff);

  const isDeliveryTabActiveEnable = isDeliveryTabActive;

  const errorInfo =
    isInvalidDeliveryDropOff && isDeliveryTabActive
      ? t('car_delivery_error_xdays', {
          0: minDaysOfDeliveryDropOff,
        })
      : t('date_time_error');

  // first verify if all the search data is valid
  const isSearchValid = validateSearch({
    pickupBranch,
    dropoffBranch,
    pickupDate,
    dropoffDate,
    pickupTime,
    dropoffTime,
    isSameDay,
    isDeliveryTabActive: isDeliveryTabActiveEnable,
    minDaysOfDeliveryDropOff,
  });

  const searchQueryString =
    pickupBranch && dropoffBranch
      ? `pickupBranchId=${pickupBranch.id}&dropOffBranchId=${
          isSwitchOn ? dropoffBranch.id : pickupBranch.id
        }&pickupBranchName=${pickupBranch.name}&dropOffBranchName=${
          isSwitchOn ? dropoffBranch.name : pickupBranch.name
        }&pickupDate=${getTimeStampForDateTime(
          pickupDate,
          pickupTime,
        )}&dropOffDate=${getTimeStampForDateTime(
          dropoffDate,
          dropoffTime,
        )}&dropOffSwitch=${isSwitchOn}`
      : '';

  useEffect(() => {
    const { language } = i18n;
    if (isSearchValid && searchQueryString) {
      const route = `/${language}/fleet?${searchQueryString}`;
      prerenderPage(route);
    }
  }, [i18n, isSearchValid, router, searchQueryString]);

  /**
   * Callback function for handling click
   * on Search button
   */
  const handleSearchClick = () => {
    const screenName = SCREENS_NAME[pathName as keyof typeof SCREENS_NAME];

    if (isSearchValid) {
      // send GTM event when search button is tapped
      searchTapped(
        screenName,
        pickupBranch,
        dropoffBranch,
        pickupDate,
        dropoffDate,
        pickupTime,
        dropoffTime,
        activeTabIndex,
        isDropOffBranchSame,
      );

      $sessionStorage(STORAGE_KEYS.ACTIVE_TAB, activeTabIndex);

      // update store
      setSearchDataUpdated(true);

      if (handleSearchCTA) {
        handleSearchCTA(
          pickupBranch,
          dropoffBranch,
          pickupDate,
          dropoffDate,
          pickupTime,
          dropoffTime,
          searchQueryString,
        );
      } else {
        // go to listing page
        const route = `/${language}/fleet?${searchQueryString}`;
        router.push(route);
      }
    } else {
      // show invalid search dialog
      handleOpenInvalidSearchDialog();
    }
  };

  const handleOpenInvalidSearchDialog = () => {
    setOpenInvalidSearchDialog(true);
  };
  const handleCloseInvalidSearchDialog = () => {
    setOpenInvalidSearchDialog(false);
  };

  const fetchBranch = (search: string, lat?: number, long?: number) => {
    changeParams({
      search,
      ...(lat ? { lat } : {}),
      ...(long ? { long } : {}),
    });
  };

  return (
    <>
      <MobileOnly>
        <div className={classes.searchWrapper} id="searchBlock">
          <div className={classes.banner}>
            <Image
              alt="Lumi car rental"
              src="/images/png/mob-banner-bg.png"
              layout="fill"
              objectFit="cover"
              quality={100}
              priority
            />
            <div className={classes.titleWrapper}>
              {title && (
                <Typography variant="h1" className={classes.title}>
                  {title}
                </Typography>
              )}
              {subTitle && (
                <Typography className={classes.subTitle}>{subTitle}</Typography>
              )}
            </div>
          </div>
          <FindCarForm
            fetchBranch={fetchBranch}
            branchProps={branchProps}
            cityProps={cityProps}
            locationProps={locationProps}
            searchPageProps={searchPageProps}
            detectLocation={detectLocation}
            onSearchClick={handleSearchClick}
            dropOffCharges={dropOffCharges}
            moveTip={moveTip}
          />
        </div>
      </MobileOnly>
      <DesktopOnly>
        <div className={desktopClasses.mainWrapper} id="searchBlock">
          <div className={desktopClasses.bgWrap}>
            <Image
              alt="Lumi car rental"
              src="/images/png/search-back.jpg"
              layout="fill"
              objectFit="cover"
              quality={70}
              priority
            />
          </div>
          <div className={desktopClasses.wrapperInner}>
            <Container>
              <Grid container className={desktopClasses.container}>
                <div className={desktopClasses.titleWrapper}>
                  {title && (
                    <Typography variant="h1" className={desktopClasses.title}>
                      {title}
                    </Typography>
                  )}
                  {subTitle && (
                    <Typography className={desktopClasses.subTitle}>
                      {subTitle}
                    </Typography>
                  )}
                </div>
                {/* Desktop FindBranchBlock */}
                <FindBranchBlock
                  textInputRef={textInputRef}
                  moveTip={moveTip}
                  fetchBranch={fetchBranch}
                  detectLocation={detectLocation}
                  branchProps={branchProps}
                  cityProps={cityProps}
                  locationProps={locationProps}
                  searchPageProps={searchPageProps}
                  onSearchClick={handleSearchClick}
                  dropOffCharges={dropOffCharges}
                  showCloseBtn={false}
                  handleCloseSearch={() => {
                    console.log('handle close search');
                  }}
                  isThemeChange
                />
              </Grid>
            </Container>
          </div>
        </div>
      </DesktopOnly>

      {/**
       * This dialog will show up when user clicks on search button
       * And if the search data is invalid for the branch
       * And if drop off date is less then 30 days for car delivery
       * eg. branch is off on selected date
       */}
      <InvalidSearchDialog
        openDialog={openInvalidSearchDialog}
        errorInfo={errorInfo}
        closeDialog={handleCloseInvalidSearchDialog}
      />
    </>
  );
}
