const styles = (theme) => ({
  contentWrapper: {
    padding: '16px 24px',
    width: '100%',
    '@media(min-width: 768px)': {
      padding: 16,
    },
  },
  subTitle: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 8,
    '& > img': {
      marginRight: 8,
    },
    '@media(min-width: 768px)': {
      fontSize: theme.typography.fontSize18,
      fontWeight: '400',
    },
  },
  branchIcon: {
    filter:
      'invert(12%) sepia(2%) saturate(8%) hue-rotate(317deg) brightness(108%) contrast(82%)',
  },
  infoWrapper: {
    '& p.MuiTypography-root': {
      marginBottom: 8,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    '@media(min-width: 768px)': {
      paddingLeft: 30,
    },
  },
  distanceText: {
    color: theme.palette.text.primary,
    fontSize: 12,
    lineHeight: 1.2,
    fontWeight: theme.typography.fontWeightMedium,
    '@media(min-width: 768px)': {
      fontSize: theme.typography.fontSize16,
    },
  },
  timingSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.common.grey300,
    fontSize: 12,
    lineHeight: 1.2,
    textAlign: 'left',
    '& > span:last-child': {
      minWidth: 110,
    },
    '@media(min-width: 768px)': {
      fontSize: theme.typography.fontSize14,
    },
  },
  timingGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
});

export default styles;
