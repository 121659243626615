'use client';

import React from 'react';
import { useTranslation } from 'react-i18next';
import _size from 'lodash/size';
import PropTypes from 'prop-types';

import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import BranchCard from './BranchCard';

import styles from './BranchList.style';

const useStyles = makeStyles()(styles);

function BranchList({
  language,
  branches,
  handleSelectItem,
  searchValue,
  isSwitchOpen,
  getBranchesByCityId,
}) {
  const { t } = useTranslation();

  const { classes } = useStyles();
  const { cityName, cityId } = branches[0];

  const branchesByCityId = getBranchesByCityId(cityId);
  const noOfBranch = _size(branchesByCityId);
  const branchText = `${noOfBranch} ${t('branches', {
    count: noOfBranch,
  })}`;

  const branchContainerClass = !isSwitchOpen ? `${classes.fullWidth}` : '';
  const branchListClass = `${classes.branchList} ${
    isSwitchOpen ? classes.flexCol : ''
  }`;

  return (
    <Grid container className={branchContainerClass}>
      <div className={classes.titleWrap}>
        <Typography variant="h5" className={classes.title}>
          {cityName}
        </Typography>
        <Typography variant="body2" className={classes.subTitle}>
          {branchText}
        </Typography>
      </div>
      <div className={branchListClass}>
        {branches.map((branch) => {
          const testId = `searchCityBranchCard_${branch.id}`;
          return (
            <ButtonBase
              className={classes.cardWrapper}
              key={branch.id}
              data-testid={testId}
              onClick={() => handleSelectItem(branch)}
            >
              <BranchCard
                language={language}
                branch={branch}
                searchValue={searchValue}
              />
            </ButtonBase>
          );
        })}
      </div>
    </Grid>
  );
}

export default React.memo(BranchList);

BranchList.propTypes = {
  branches: PropTypes.array.isRequired,
  handleSelectItem: PropTypes.func,
  searchValue: PropTypes.string,
};

BranchList.defaultProps = {
  searchValue: '',
  handleSelectItem: () => {
    // handle select here
  },
};
