import React from 'react';
import PropTypes from 'prop-types';

import LANGUAGES from '@lumirental/lumi-web-shared/lib/constants/lang/LANGUAGES';

export default function HeaderBackAndroidIcon({ language }) {
  const rotate180 = { transform: 'rotateZ(180deg)' };

  const style = language === LANGUAGES.AR ? rotate180 : null;

  return (
    <img style={style} src="/images/svg/arrow-back-icon.svg" alt="Back Arrow" />
  );
}

HeaderBackAndroidIcon.lengthpropTypes = {
  language: PropTypes.string,
};
