import React from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import BranchListWrapper from '../BranchListWrapper';

import styles from './SearchBranchWrapper.style';

const useStyles = makeStyles()(styles);

const SearchBranchWrapper = React.memo(
  ({
    language,
    data,
    handleSelectItem,
    searchValue,
    branchErrorMessage,
    showBranchList,
    isLocationSuccess,
    isBranchSuccess,
    handleViewAllCity,
    getBranchesByCityId,
  }) => {
    const { classes } = useStyles();
    const { t } = useTranslation();

    if (branchErrorMessage) {
      return (
        <div className={classes.noFoundWrapper}>
          <Image
            width={124}
            height={91}
            className={classes.fleetImg}
            src="/images/svg/no-result-found.svg"
            alt="No Result Found"
          />
          <div className={classes.contentWrapper}>
            <Typography variant="h6" className={classes.title}>
              {t('nothing_found')}
            </Typography>
            <Typography variant="body2" className={classes.text}>
              {t('please_try_another_keyword')}
            </Typography>
          </div>
          <div className={classes.btnWrapper}>
            <Button
              variant="text"
              className={classes.allCityBtn}
              onClick={handleViewAllCity}
            >
              {t('View_all_cities')}
            </Button>
          </div>
        </div>
      );
    }

    return (
      <>
        <BranchListWrapper
          language={language}
          data={data}
          handleSelectItem={handleSelectItem}
          searchValue={searchValue}
          branchErrorMessage={false}
          getBranchesByCityId={getBranchesByCityId}
          showBranchList={showBranchList}
          isLocationSuccess={isLocationSuccess}
          isBranchSuccess={isBranchSuccess}
        />
      </>
    );
  },
);

export default SearchBranchWrapper;
