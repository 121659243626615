const styles = (theme) => ({
  pickerWrapper: {
    overflow: 'hidden',
  },
  pickerContainer: {
    width: '100%',
    position: 'relative',
    zIndex: theme.zIndex.zIndex1000,
    '& :before, & :after': {
      boxSizing: 'border-box',
    },
  },
  pickerInner: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    padding: 0,
    fontSize: '1.2em',
    width: '50%',
    margin: '0 auto',
  },
  labelIndicator: {
    position: 'absolute',
    top: '39%',
    left: 10,
    textTransform: 'capitalize',
    fontSize: theme.typography.fontSize12,
    lineHeight: 1.3,
  },
  pickerClm: {
    flex: '1 1 auto',
    position: 'relative',
    maxHeight: '100%',
    textAlign: 'center',
    '& .picker-scroller': {
      transition: '300ms',
      transitionTimingFunction: 'ease-out',
    },
    '& .picker-item': {
      position: 'relative',
      color: theme.palette.text.primary,
      fontSize: theme.typography.fontSize15,
      lineHeight: 1.4,
      padding: '0 10px',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .picker-item-selected': {
      color: theme.palette.common.grey400,
    },
    '& .closed-item': {
      fontSize: theme.typography.fontSize13,
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.common.grey300,
      textTransform: 'none',
    },
  },
  pickerHighlight: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    pointerEvents: 'none',
    '&::before, &::after': {
      content: '" "',
      position: 'absolute',
      left: '0',
      right: 'auto',
      display: 'block',
      width: '100%',
      height: '1px',
      background: theme.palette.common.grey50,
      transform: 'scaleY(0.5)',
    },
    '&::before': {
      top: 56,
      bottom: 'auto',
    },
    '&::after': {
      bottom: -56,
      top: 'auto',
    },
  },
});

export default styles;
