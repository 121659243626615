const styles = (theme) => ({
  wrapper: {
    flexDirection: 'column',
  },
  cardWrapper: {
    width: '100%',
    textAlign: 'left',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    fontSize: theme.typography.fontSize16,
    lineHeight: 1.5,
    color: theme.palette.common.grey300,
    paddingTop: 8,
    paddingBottom: 8,
  },
  clockIcon: {
    display: 'inline-flex',
    maxWidth: 20,
    marginRight: 8,
    '& > img': {
      width: '100%',
    },
  },
  divider: {
    backgroundColor: theme.palette.common.grey20,
  },
});

export default styles;
