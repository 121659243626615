import { useSearchParams } from 'next/navigation';

export const useUrl = (): Record<string, (...p: any) => void> => {
  const searchParams = useSearchParams();
  const changeParam = (key: string, value: string): void => {
    console.log('Param', key, value);
    const params = new URLSearchParams(searchParams.toString());
    if (
      value === '' ||
      value === 'null' ||
      value === null ||
      value === 'undefined' ||
      value === undefined
    ) {
      params.delete(key);
    } else {
      params.set(key, value);
    }
    console.log('pushing to: ', `?${params.toString()}`);
    // router.push(`?${params.toString()}`);
    window.history.pushState(null, '', `?${params.toString()}`);
  };

  const changeParams = (data: Record<string, string>): void => {
    const params = new URLSearchParams(searchParams.toString());
    Object.entries(data).forEach(([key, value]) => {
      if (
        value === '' ||
        value === 'null' ||
        value === null ||
        value === 'undefined' ||
        value === undefined
      ) {
        params.delete(key);
      } else {
        params.set(key, value);
      }
    });
    // router.push(`?${params.toString()}`);
    window.history.pushState(null, '', `?${params.toString()}`);
  };

  return { changeParam, changeParams };
};
