import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/legacy/image';
import { usePathname, useRouter } from 'next/navigation';
import PropTypes from 'prop-types';

import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { makeStyles } from 'tss-react/mui';

import MENUS, {
  MENU_V2,
} from '@lumirental/lumi-web-shared/lib/constants/app/MENUS';
import LANGUAGES from '@lumirental/lumi-web-shared/lib/constants/lang/LANGUAGES';
import { useLanguageContext } from '@lumirental/lumi-web-shared/lib/contexts/Language.context';
import useAB from '@lumirental/lumi-web-shared/lib/hooks/useAB';
import changeLanguageTapped from '@lumirental/lumi-web-shared/lib/utils/gtm/changeLanguageTapped';
import contactUsTapped from '@lumirental/lumi-web-shared/lib/utils/gtm/contactUsTapped';
import faqsTapped from '@lumirental/lumi-web-shared/lib/utils/gtm/faqsTapped';
import privacyPolicyTapped from '@lumirental/lumi-web-shared/lib/utils/gtm/privacyPolicyTapped';
import profileTapped from '@lumirental/lumi-web-shared/lib/utils/gtm/profileTapped';
import signOutTapped from '@lumirental/lumi-web-shared/lib/utils/gtm/signOutTapped';
import navigateAndScrollToTop from '@lumirental/lumi-web-shared/lib/utils/lang/navigateAndScrollToTop';
import switchLanguagePath from '@lumirental/lumi-web-shared/lib/utils/lang/switchLanguagePath';

import { deleteCookie, getCookie, TOKEN_COOKIE } from '@/utils/auth';
import { onTapEvent } from '@/utils/gtm';

import NestedMenu from '@/components/SideMenu/NestedMenu';

import styles from '@/components/SideMenu/SideMenu.style';

const useStyles = makeStyles()(styles);

const MARKETING_DIALOG_STORAGE = 'isMarketingDialogSeen';

export default function SideMenu({
  openMenu,
  onCloseMenu,
  // activeMenu,
  onLogout,
  // appVersion,
  // isLoggedIn,
  // logout,
}) {
  const router = useRouter();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const activeMenu = MENU_V2[router.pathname] ?? '';

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(activeMenu);

  const pathName = usePathname();
  let { isArabic } = useLanguageContext();
  // TODO: Remove/modify above hook once rollout
  isArabic = /^\/ar/.test(pathName);

  useEffect(() => {
    const isLoggedIn = getCookie(TOKEN_COOKIE);
    setIsLoggedIn(isLoggedIn);
  }, []);

  const isSaveCardForFuturePaymentEnable = useAB('save_card_flag');

  const langBtnText = isArabic
    ? t('English_language_switch_button')
    : t('Arabic_language_switch_button');

  const dataLang = isArabic ? LANGUAGES.EN : LANGUAGES.AR;

  const langMenuText = (
    <>
      <span>{t('change_language')}</span>
      <span className={classes.highlightText}>{langBtnText}</span>
    </>
  );
  const authMenuItem = isLoggedIn ? t('profile') : t('login');
  const mailTo = `mailto:${t('contact_email_id')}`;
  const tel = `tel:+${t('contact_phone')}`;

  const handleLangChange = (evt) => {
    const setLang = evt.currentTarget.dataset.lang;
    const newUrl = switchLanguagePath(setLang);
    // replace the URL
    // returns a promise now
    router.replace(newUrl);

    // send GTM event when language link tapped.
    changeLanguageTapped(isLoggedIn);
  };

  const handleLogin = () => {
    if (isLoggedIn) {
      // send GTM event when profile link tapped.
      profileTapped(isLoggedIn);

      // navigate to profile page
      navigateAndScrollToTop(router, 'profile');
    } else {
      // navigate to login page
      navigateAndScrollToTop(router, 'account');
    }
  };

  const handleLogoutSuccess = () => {
    // invoke callback if any
    if (onLogout && typeof onLogout === 'function') {
      onLogout();
    }

    window.Moengage.destroy_session();

    // Perform localStorage action
    localStorage.removeItem(MARKETING_DIALOG_STORAGE);

    // navigate to home page
    navigateAndScrollToTop(router, '/');
  };

  const handleLogoutError = () => {
    // reload the page
    console.log('logout error');
  };

  const handleLogout = () => {
    try {
      deleteCookie(TOKEN_COOKIE);
      handleLogoutSuccess();
    } catch (error) {
      handleLogoutError(error);
    }

    // send GTM event when signout link tapped.
    signOutTapped();
  };

  const handleMenuItemClick = (selectedMenu) => {
    let eventName = '';
    if (selectedMenu === MENUS.HOME) {
      eventName = 'home_tapped';
      // navigate to home page
      navigateAndScrollToTop(router, '');
    }

    if (selectedMenu === MENUS.PROFILE) {
      // handle profile page

      // handle Login
      handleLogin();
    }

    if (selectedMenu === MENUS.BOOKING) {
      eventName = 'bookings_tapped';
      // navigate to bookings page
      navigateAndScrollToTop(router, 'bookings');
    }
    if (selectedMenu === MENUS.MANAGE_PAYMENT) {
      eventName = 'manage_payment_tapped';
      // navigate to bookings page
      navigateAndScrollToTop(router, 'manage-payment');
    }

    if (selectedMenu === MENUS.OFFERS) {
      eventName = 'offers_tapped';
      // navigate to offers page
      navigateAndScrollToTop(router, 'offers');
    }

    if (selectedMenu === MENUS.FAQ) {
      // send GTM event when faqs link tapped.
      faqsTapped(isLoggedIn);

      // navigate to FAQ page
      navigateAndScrollToTop(router, 'faq');
    }

    if (selectedMenu === MENUS.SERVICES) {
      eventName = 'services_tapped';
      // navigate to services page
      navigateAndScrollToTop(router, 'services');
    }
    if (selectedMenu === MENUS.IR) {
      eventName = 'ir_tapped';
      // navigate to ir page
      navigateAndScrollToTop(router, 'company/IR');
    }

    if (selectedMenu === MENUS.ABOUT_US) {
      eventName = 'about_us_tapped';
      // navigate to about page
      navigateAndScrollToTop(router, 'company/about');
    }

    if (selectedMenu === MENUS.MOTORCYCLE) {
      eventName = 'motorcycle_tapped';
      // navigate to motorcycle page
      navigateAndScrollToTop(router, 'car-rental/motorcycles');
    }

    if (selectedMenu === MENUS.BUS_RENTAL) {
      eventName = 'bus_rental_tapped';
      // navigate to bus-rental page
      navigateAndScrollToTop(router, 'bus-rental');
    }

    if (selectedMenu === MENUS.DIRECT_SALE) {
      eventName = 'direct_sale_tapped';
      // navigate to used-car page
      navigateAndScrollToTop(router, 'used-car');
    }

    if (selectedMenu === MENUS.LOCATIONS) {
      eventName = 'location_tapped';
      // navigate to locations page
      navigateAndScrollToTop(router, 'locations');
    }

    if (selectedMenu === MENUS.UCS) {
      eventName = 'ucs_tapped';
      // navigate to ucs page
      navigateAndScrollToTop(router, MENUS.UCS);
    }

    if (selectedMenu === MENUS.DIRECT_SALE) {
      eventName = 'ucs_ds_tapped';
      // navigate to ucs page
      navigateAndScrollToTop(router, MENUS.DIRECT_SALE);
    }

    if (selectedMenu === MENUS.PRIVACY_POLICY) {
      // send GTM event when privacy-policy link tapped.
      privacyPolicyTapped(isLoggedIn);

      // navigate to Privacy Policy page
      navigateAndScrollToTop(router, 'privacy');
    }

    if (selectedMenu === MENUS.TERMS_AND_CONDITIONS) {
      eventName = 'terms_and_conditions_tapped';
      // navigate to Terms & Condition page
      navigateAndScrollToTop(router, 'terms');
    }

    setSelectedMenu(selectedMenu);

    if (eventName) {
      // send GA event
      onTapEvent('Home', eventName);
    }
  };

  const handleContactusClick = () => {
    // send GTM event when contact us link tapped.
    contactUsTapped(isLoggedIn);
  };

  const menuItems = [
    {
      label: t('ucs_header'),
      onClick: () => handleMenuItemClick(MENUS.UCS),
    },
    {
      label: t('car_for_sale'),
      onClick: () => handleMenuItemClick(MENUS.DIRECT_SALE),
    },
  ];

  return (
    <SwipeableDrawer
      anchor="left"
      open={openMenu}
      onClose={onCloseMenu}
      onOpen={() => {}}
    >
      <div className={classes.container} data-testid="sideNavWrapper">
        <List component="nav" className={classes.list}>
          <ListItem
            button
            className={`${classes.listItem} ${
              selectedMenu === MENUS.HOME ? 'active' : ''
            }`}
            data-testId={`link-${MENUS.HOME}`}
            onClick={() => handleMenuItemClick(MENUS.HOME)}
          >
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText className={classes.title} primary={t('home')} />
          </ListItem>
          <ListItem
            button
            className={`${classes.listItem} ${
              selectedMenu === MENUS.PROFILE ? 'active' : ''
            }`}
            data-testId={`link-${MENUS.PROFILE}`}
            onClick={() => handleMenuItemClick(MENUS.PROFILE)}
          >
            <ListItemIcon>
              <img src="/images/svg/profile_icon.svg" alt="Profile Icon" />
            </ListItemIcon>
            <ListItemText className={classes.title} primary={authMenuItem} />
          </ListItem>
          <ListItem
            button
            className={`${classes.listItem} ${
              selectedMenu === MENUS.BOOKING ? 'active' : ''
            }`}
            data-testId={`link-${MENUS.BOOKING}`}
            onClick={() => handleMenuItemClick(MENUS.BOOKING)}
          >
            <ListItemIcon>
              <img src="/images/svg/booking_icon.svg" alt="Bookings Icon" />
            </ListItemIcon>
            <ListItemText className={classes.title} primary={t('bookings')} />
          </ListItem>
          <ListItem
            button
            className={`${classes.listItem} ${
              selectedMenu === MENUS.OFFERS ? 'active' : ''
            }`}
            data-testId={`link-${MENUS.OFFERS}`}
            onClick={() => handleMenuItemClick(MENUS.OFFERS)}
          >
            <ListItemIcon>
              <Image
                src="/images/svg/gift_icon.svg"
                alt="Offers Icon"
                width={18}
                height={18}
              />
            </ListItemIcon>
            <ListItemText className={classes.title} primary={t('Offers')} />
          </ListItem>
          <Divider className={classes.divider} />
          <ListItem
            button
            data-lang={dataLang}
            className={classes.listItem}
            data-testId="changeLanguageAction"
            onClick={handleLangChange}
          >
            <ListItemText className={classes.title} primary={langMenuText} />
          </ListItem>
          {isLoggedIn && isSaveCardForFuturePaymentEnable && (
            <ListItem
              button
              className={`${classes.listItem} ${
                selectedMenu === MENUS.MANAGE_PAYMENT ? 'active' : ''
              }`}
              data-testId={`link-${MENUS.MANAGE_PAYMENT}`}
              onClick={() => handleMenuItemClick(MENUS.MANAGE_PAYMENT)}
            >
              <ListItemIcon>
                <img
                  src="https://cdn.lumirental.com/mobile/images/svg/manage-payment-icon.svg"
                  alt="Manage Payments Icon"
                />
              </ListItemIcon>
              <ListItemText
                className={classes.title}
                primary={t('menu_item_manage_payment')}
              />
            </ListItem>
          )}
          <ListItem
            button
            className={`${classes.listItem} ${
              selectedMenu === MENUS.IR ? 'active' : ''
            }`}
            data-testId={`link-${MENUS.IR}`}
            onClick={() => handleMenuItemClick(MENUS.IR)}
          >
            <ListItemText
              className={classes.title}
              primary={t('investor-relations')}
            />
          </ListItem>
          <ListItem
            button
            className={`${classes.listItem} ${
              selectedMenu === MENUS.FAQ ? 'active' : ''
            }`}
            data-testId={`link-${MENUS.FAQ}`}
            onClick={() => handleMenuItemClick(MENUS.FAQ)}
          >
            <ListItemText className={classes.title} primary={t('faq')} />
          </ListItem>
          <ListItem
            button
            className={`${classes.listItem} ${
              selectedMenu === MENUS.PRIVACY_POLICY ? 'active' : ''
            }`}
            data-testId={`link-${MENUS.PRIVACY_POLICY}`}
            onClick={() => handleMenuItemClick(MENUS.PRIVACY_POLICY)}
          >
            <ListItemText
              className={classes.title}
              primary={t('privacy_policy')}
            />
          </ListItem>
          <ListItem
            button
            className={`${classes.listItem} ${
              selectedMenu === MENUS.TERMS_AND_CONDITIONS ? 'active' : ''
            }`}
            data-testId={`link-${MENUS.TERMS_AND_CONDITIONS}`}
            onClick={() => handleMenuItemClick(MENUS.TERMS_AND_CONDITIONS)}
          >
            <ListItemText
              className={classes.title}
              primary={t('terms_and_conditions')}
            />
          </ListItem>
          <ListItem
            button
            className={`${classes.listItem} ${
              selectedMenu === MENUS.MOTORCYCLE ? 'active' : ''
            }`}
            data-testId={`link-${MENUS.MOTORCYCLE}`}
            onClick={() => handleMenuItemClick(MENUS.MOTORCYCLE)}
          >
            <ListItemText
              className={classes.title}
              primary={t('motorcycles')}
            />
          </ListItem>
          <ListItem
            button
            className={`${classes.listItem} ${
              selectedMenu === MENUS.BUS_RENTAL ? 'active' : ''
            }`}
            data-testId={`link-${MENUS.BUS_RENTAL}`}
            onClick={() => handleMenuItemClick(MENUS.BUS_RENTAL)}
          >
            <ListItemText
              className={classes.title}
              primary={t('bus_rental_nav_item')}
            />
          </ListItem>
          <ListItem
            button
            className={`${classes.listItem} ${
              selectedMenu === MENUS.LOCATIONS ? 'active' : ''
            }`}
            data-testId={`link-${MENUS.LOCATIONS}`}
            onClick={() => handleMenuItemClick(MENUS.LOCATIONS)}
          >
            <ListItemText className={classes.title} primary={t('Locations')} />
          </ListItem>
          <NestedMenu
            buttonLabel={t('Used Car Sale')}
            menuItems={menuItems}
            active={selectedMenu === MENUS.DIRECT_SALE}
          />
          <ListItem
            button
            className={`${classes.listItem} ${
              selectedMenu === MENUS.ABOUT_US ? 'active' : ''
            }`}
            data-testId={`link-${MENUS.ABOUT_US}`}
            onClick={() => handleMenuItemClick(MENUS.ABOUT_US)}
          >
            <ListItemText className={classes.title} primary={t('about_lumi')} />
          </ListItem>

          <Divider className={classes.divider} />
          <ListItem
            button
            component="a"
            href={tel}
            className={classes.listItem}
            data-testId="callUsAction"
            onClick={handleContactusClick}
          >
            <ListItemText className={classes.title} primary={t('call_us')} />
          </ListItem>
          <ListItem
            button
            component="a"
            href={mailTo}
            className={classes.listItem}
            data-testId="emailAction"
          >
            <ListItemText className={classes.title} primary={t('email')} />
          </ListItem>
          {isLoggedIn && (
            <ListItem
              button
              className={classes.listItem}
              data-testId="logoutAction"
              onClick={handleLogout}
            >
              <ListItemText
                className={`${classes.title} logout`}
                primary={t('logout')}
              />
            </ListItem>
          )}
        </List>
      </div>
      {/* <p className={classes.appVersion}>{appVersion}</p> */}
    </SwipeableDrawer>
  );
}

SideMenu.propTypes = {
  openMenu: PropTypes.bool,
  onCloseMenu: PropTypes.func,
};
