const styles = (theme) => ({
  contentWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: 16,
    width: '100%',
    maxWidth: 360,
  },
  subTitle: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize18,
    lineHeight: 1.5,
  },
  branchIcon: {
    filter:
      'invert(12%) sepia(2%) saturate(8%) hue-rotate(317deg) brightness(108%) contrast(82%)',
    maxWidth: 20,
    marginRight: 8,
  },
  infoWrapper: {
    width: '100%',
  },
  distanceText: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize16,
    lineHeight: 1.5,
    marginBottom: 4,
  },
  timingSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.common.grey300,
    fontSize: theme.typography.fontSize14,
    lineHeight: 1.5,
    textAlign: 'left',
    '& > span:last-child': {
      minWidth: 126,
    },
  },
  timingGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
});

export default styles;
