import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import CUSTOMER_CONTACT_TYPE from '@lumirental/lumi-web-shared/lib/constants/app/CUSTOMER_CONTACT_TYPE';

import styles from '@/components/Dialogs/Action/ActionDialog.style';

const useStyles = makeStyles()(styles);

export default function ActionDialog({
  openDialog,
  title,
  titleInfo,
  infoMessage,
  primaryCtaTitle,
  secondaryCtaTitle,
  primaryActionType,
  handlePrimaryCtaAction,
  handleSecondaryCtaAction,
}) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const tel = `tel:+${t('contact_phone')}`;
  const isCall = primaryActionType === CUSTOMER_CONTACT_TYPE.CALL;

  return (
    <Dialog
      open={openDialog}
      classes={{
        paper: `${classes.dialog} ${!title ? classes.noTitle : ''}`,
      }}
    >
      <DialogContent
        className={`${classes.mainWrapper} ${
          !title ? classes.noTitleMainWrapper : ''
        }`}
      >
        {title && (
          <div className={classes.titleWrapper}>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            {titleInfo && (
              <Typography variant="body2" className={classes.titleInfo}>
                {titleInfo}
              </Typography>
            )}
          </div>
        )}
        {infoMessage && (
          <Typography variant="body1" className={classes.paragraph}>
            {infoMessage}
          </Typography>
        )}
      </DialogContent>
      <DialogActions className={classes.actionWrapper}>
        <div className={classes.btnItem}>
          <Button
            variant="text"
            className={classes.secondaryBtn}
            onClick={handleSecondaryCtaAction}
          >
            {secondaryCtaTitle}
          </Button>
        </div>
        {!primaryActionType && (
          <div className={classes.btnItem}>
            <Button
              variant="text"
              className={classes.primaryBtn}
              onClick={handlePrimaryCtaAction}
            >
              {primaryCtaTitle}
            </Button>
          </div>
        )}
        {isCall && (
          <div className={classes.btnItem}>
            <Button
              variant="text"
              className={classes.primaryBtn}
              component="a"
              href={tel}
              onClick={handlePrimaryCtaAction}
            >
              {primaryCtaTitle}
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}

ActionDialog.propTypes = {
  openDialog: PropTypes.bool,
  title: PropTypes.string,
  infoMessage: PropTypes.string,
  primaryCtaTitle: PropTypes.string,
  secondaryCtaTitle: PropTypes.string,
  primaryActionType: PropTypes.string,
  handlePrimaryCtaAction: PropTypes.func,
  handleSecondaryCtaAction: PropTypes.func,
};
