import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import { useSearchParams } from 'next/navigation';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import STORAGE_KEYS from '@lumirental/lumi-web-sdk/dist/constants/app/STORAGE_KEYS';
import SEARCH_DEBOUNCE_TIME from '@lumirental/lumi-web-shared/lib/constants/app/SEARCH_DEBOUNCE_TIME';
import SUB_SCREENS_NAME from '@lumirental/lumi-web-shared/lib/constants/app/SUB_SCREENS_NAME';
import CUSTOM_ERROR_CODES from '@lumirental/lumi-web-shared/lib/constants/errorCodes/CUSTOM_ERROR_CODES';
import LOCATION_ERROR_CODES from '@lumirental/lumi-web-shared/lib/constants/errorCodes/LOCATION_ERROR_CODES';
import useAPIError from '@lumirental/lumi-web-shared/lib/hooks/useAPIError';
import checkLength from '@lumirental/lumi-web-shared/lib/utils/checkLength';
import apiError from '@lumirental/lumi-web-shared/lib/utils/gtm/apiError';
import branchNearMeTapped from '@lumirental/lumi-web-shared/lib/utils/gtm/branchNearMeTapped';
import branchSelected from '@lumirental/lumi-web-shared/lib/utils/gtm/branchSelected';
import noSearchResults from '@lumirental/lumi-web-shared/lib/utils/gtm/noSearchResults';
import searchPerformed from '@lumirental/lumi-web-shared/lib/utils/gtm/searchPerformed';

import { LocationType } from '@/types/Location';
import { useUrl } from '@/utils/useUrl';

import BranchListWrapper from '../BranchListWrapper';
import CityList from '../Lists/CityList';
import LocationDenyInfoDialog from '../LocationDenyInfo';
import RecentSearch from '../RecentSearch';

import styles from './BranchPicker.style';

const useStyles = makeStyles()(styles);

let searchText = '';
export default function DropOffBranchPicker({
  branchProps,
  searchPageProps,
  language,
  cityProps,
  locationProps,
  detectLocation,
  fetchBranch,
  id,
  isSwitchOpen,
  defaultValue,
  pickupCityId,
  isPickUpBranchSelected,
  dropOffCharges,
  setOpenDifferentCityReturnDialog,
}) {
  const searchParams = useSearchParams();
  const search = searchParams.get('search') ?? '';

  const [showBranchPopover, setShowBranchPopover] = useState(false);
  const [searchValue, setSearchValue] = useState(
    defaultValue === '' ? search : defaultValue,
  );
  const defaultValRef = useRef(searchValue);
  const [searchClicked, setSearchClicked] = useState(false);
  const [showLocationBtn, setShowLocationBtn] = useState(false);
  const [openLocationDenyInfoDialog, setOpenLocationDenyInfoDialog] =
    useState(false);
  const [locationClicked, setLocationClicked] = useState(false);
  const [showRecentSearch, setShowRecentSearch] = useState(false);

  const wrapperRef = useRef(null);
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { changeParams } = useUrl();
  const testId = `searchSelect${id}`;
  const label = t('dropoff_location');
  const showBranchList = checkLength(search);

  const screenName = SUB_SCREENS_NAME.SEARCH;

  // read props for csr
  const {
    getBranchesByCityId,
    setDropOffBranch,
    isDeliveryTabActive,
    getCityListByActiveTab,
    getBranchByBranchId,
    pickupBranch,
  } = searchPageProps;

  const { cityErrorData } = cityProps;

  const {
    isBranchLoading,
    isBranchSuccess,
    setInitialState,
    searchType,
    sortedDeliveryCityIdHashMap,
    sortedPickUpCityIdHashMap,
  } = branchProps;

  const { dropoff, ...restLocationProps } = locationProps || {};

  const {
    isLocationLoading,
    isLocationSuccess,
    hasLocationFailed,
    lat,
    long,
    locationErrorData,
  } = dropoff || restLocationProps;

  // const errorCode = _get(branchErrorData, 'data.code', '');
  // const { errorMessage: branchErrorMessage } = useAPIError(errorCode);
  const { errorMessage: cityErrorMessage } = useAPIError(
    _get(cityErrorData, 'data.code', ''),
  );

  // drived value
  const filterCityList = getCityListByActiveTab && getCityListByActiveTab();
  const isCarDelivery = isDeliveryTabActive;
  const sortedCityIdsHasMap = isCarDelivery
    ? sortedDeliveryCityIdHashMap
    : sortedPickUpCityIdHashMap;

  const hasBranch = !_isEmpty(sortedCityIdsHasMap);
  const hasSearch = !!searchValue;
  const isSearchResult = hasSearch && isBranchSuccess && hasBranch;

  let recentSearch;
  // nextjs is render server side so we get localStorage value
  // once comp. is render client side.
  if (typeof window !== 'undefined') {
    // Perform localStorage action
    recentSearch = JSON.parse(localStorage.getItem(STORAGE_KEYS.RECENT_SEARCH));
  }

  // get branches for branchId's
  const recentSearchBranches = useMemo(() => {
    return (
      recentSearch &&
      recentSearch.flatMap(
        (branchId) =>
          (getBranchByBranchId && getBranchByBranchId(branchId)) || [],
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRecentSearch, isCarDelivery]);

  const hasRecentSearchBranches = !_isEmpty(recentSearchBranches);

  /**
   * on mount
   * runs on client side
   */
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);

    // checks if geolocation is supported
    if ('geolocation' in navigator) {
      setShowLocationBtn(true);
    }

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * effect to persist the text input value
   */
  useEffect(() => {
    if (defaultValue) {
      const val = defaultValue === '' ? search : defaultValue;
      defaultValRef.current = val;
      setSearchValue(defaultValue);
    }
    if (!defaultValue && !search) {
      setSearchValue('');
    }
  }, [defaultValue, search]);

  /**
   * location success - runs on the client side
   * calls branch api with location
   */
  useEffect(() => {
    if (isLocationSuccess && locationClicked) {
      fetchBranch('', lat, long, handleError, 'near me');
      setLocationClicked(false); // update local state
      // set branch store to initial state
      setInitialState && setInitialState(); // update mobx store
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationClicked, isLocationSuccess, lat, long, setInitialState]);

  /**
   * location failed - runs on the client side
   * shows alert for now
   */
  useEffect(() => {
    if (hasLocationFailed && locationClicked) {
      const errCode = _get(locationErrorData, 'code', -1);
      if (
        errCode === LOCATION_ERROR_CODES.PERMISSION_DENIED ||
        errCode === LOCATION_ERROR_CODES.POSITION_UNAVAILABLE ||
        errCode === LOCATION_ERROR_CODES.TIMEOUT
      ) {
        // show error dialog
        toggleLocationDenyInfoDialog();
      } else {
        console.log(`Location Failed Unknown Error: `, locationErrorData);
      }
      setLocationClicked(false); // update local state
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationClicked, hasLocationFailed, locationErrorData]);

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setShowBranchPopover(false);
      // setSearchValue(defaultValRef.current); // reset the last selected branch name into text field
      setInitialState && setInitialState(); // update mobx store
      handleCloseRecentSearch(); // close recent search
      setSearchClicked(false);
    }
  };

  const handleBranchOpen = () => {
    setShowBranchPopover(true);
    handleOpenRecentSearch();
  };

  /**
   * detects user location
   * runs on client side
   */
  const handleDetectLocation = () => {
    // send GTM event when branch near me tapped
    branchNearMeTapped(screenName);

    // clear search before fetch location.
    // handleClearSearch();
    setLocationClicked(true); // update local state
    detectLocation(LocationType.DROPOFF);
  };

  /**
   * Handles search text clear on cross button click
   */
  // const handleClearSearch = () => {
  //   setSearchValue('');
  // };

  /**
   * Handles branch search api error from sdk store
   * @param {AxiosError} err
   */
  const handleError = (err) => {
    console.log('dropoff branch search error: ', err);

    // send GTM event when API give error.
    apiError(err);

    const errorCode = _get(err, 'data.code', '');
    // send GTM event when branch search returns no results
    if (searchText && errorCode === CUSTOM_ERROR_CODES.BR404) {
      noSearchResults(screenName, searchText);
    }
  };

  /**
   * Debounced memoized function to make
   * search branch api call
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFn = useCallback(
    _debounce((searchText) => {
      fetchBranch(searchText, 0, 0, handleError, 'search');
      // send GTM event everytime a branch is searched
      searchPerformed(screenName, searchText);
    }, SEARCH_DEBOUNCE_TIME),
    [],
  );

  /**
   * Handles change text event for search text
   * @param {Event} evt
   */
  const handleChange = (event) => {
    const { value } = event.target;
    // const searchText = value.replace(/^\s+|\s+$/g, '');
    const lengthCheck = checkLength(value);
    setSearchValue(value);
    searchText = value;
    // clearLocationProps();
    if (!value) {
      changeParams({
        // pickUpBranch: -1,
        dropOffBranch: -1,
        lat: 'null',
        long: 'null',
        search: '',
      });
    }
    if (value && lengthCheck) {
      debouncedFn(value);
      setSearchClicked(true);
    } else {
      // clears branch store data when user types less than 3 chars
      // set branch store to initial state
      setInitialState && setInitialState(); // update mobx store
      setSearchClicked(false);
    }
  };

  /**
   * Called for both branch list & city list
   * @param {object} branch
   * @param {string} selectionType
   */
  const handleBranchSelect = (branch, selectionType) => {
    // console.log('branch click: ', branch, selectionType);
    const name = _get(branch, 'name', '');

    if (isSwitchOpen && pickupBranch?.id && pickupBranch?.id !== branch.id) {
      // show diff city return dialog
      setDropOffBranch(branch); // update global store
      dropOffCharges?.fetchDropoffCharges({
        pickupBranchId: pickupBranch.id,
        dropOffBranchId: branch.id,
      });
      setOpenDifferentCityReturnDialog(true);
    } else {
      defaultValRef.current = name;
      setSearchValue(name); // update local state
      setShowBranchPopover(false); // hide search panel
      setDropOffBranch(branch); // update global store
      handleCloseRecentSearch(); // close recent search

      if (searchClicked) {
        setInitialState && setInitialState(); // update mobx store
        setSearchClicked(false);
      }
    }

    /**
     * clears branch store on before search panel is closed
     * only if previous selection was city list
     * this is to make sure no previous search result is shown
     */
    if (selectionType === 'city') {
      // set branch store to initial state
      setInitialState && setInitialState(); // update mobx store
    }

    // send GTM event when branch is selected
    branchSelected(screenName, branch, selectionType || searchType, searchText);
  };

  const toggleLocationDenyInfoDialog = () => {
    setOpenLocationDenyInfoDialog(!openLocationDenyInfoDialog);
  };

  const handleCloseRecentSearch = () => {
    setShowRecentSearch(false);
  };

  const handleOpenRecentSearch = () => {
    setShowRecentSearch(true);
  };

  return (
    <>
      <div ref={wrapperRef} className={classes.wrapper}>
        <span className={classes.fieldLabel}>{label}</span>
        <TextField
          fullWidth
          value={searchValue}
          variant="standard"
          placeholder={t('search_for_city_or_branch')}
          onClick={handleBranchOpen}
          InputProps={{
            classes: {
              input: classes.inputField,
            },
          }}
          data-testid={testId}
          onChange={handleChange}
          onFocus={(event) => {
            event.target.select();
          }}
        />
        {showBranchPopover && (
          <>
            <div className={classes.branchWrapper}>
              {(isBranchLoading || isLocationLoading) && <LinearProgress />}
              {showLocationBtn && !(isSearchResult && searchClicked) && (
                <div className={classes.navigation}>
                  <Button
                    variant="text"
                    className={classes.branchNearBtn}
                    onClick={handleDetectLocation}
                  >
                    <Image
                      height={18}
                      width={18}
                      src="/images/svg/navigation.svg"
                      alt="navigation-icon"
                    />
                    {t('find_branches_near_me')}
                  </Button>
                </div>
              )}
              {!(isSearchResult || isLocationSuccess) &&
                hasRecentSearchBranches && (
                  <RecentSearch
                    branches={recentSearchBranches}
                    handleSelectItem={handleBranchSelect}
                  />
                )}
              <BranchListWrapper
                language={language}
                data={sortedCityIdsHasMap}
                handleSelectItem={handleBranchSelect}
                searchValue={searchValue}
                showBranchList={showBranchList}
                isLocationSuccess={isLocationSuccess}
                isBranchSuccess={isBranchSuccess}
                isSwitchOpen={isSwitchOpen}
                getBranchesByCityId={getBranchesByCityId}
                searchClicked={searchClicked}
              />
              {!(cityErrorMessage || isLocationSuccess) && (
                <CityList
                  language={language}
                  data={filterCityList}
                  isSwitchOpen={isSwitchOpen}
                  getBranchesByCityId={getBranchesByCityId}
                  handleSelectItem={handleBranchSelect}
                  isCarDelivery={isCarDelivery}
                />
              )}
              {cityErrorMessage && (
                <Typography className={classes.noData}>
                  {cityErrorMessage}
                </Typography>
              )}
            </div>
          </>
        )}
      </div>
      <LocationDenyInfoDialog
        openDialog={openLocationDenyInfoDialog}
        handleCloseDialog={toggleLocationDenyInfoDialog}
      />
    </>
  );
}
