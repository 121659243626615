const styles = (theme) => ({
  formSection: {
    margin: 0,
    padding: 16,
  },
  fieldRow: {
    padding: '8px 0',
  },
  switchWrapper: {
    paddingTop: 0,
  },
  switchSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.common.grey20}`,
    paddingBottom: 8,
    marginBottom: 8,
  },
  switchLabel: {
    color: theme.palette.common.grey300,
    fontSize: 15,
    lineHeight: 1.3,
  },
  switchThumb: {
    backgroundColor: theme.palette.common.grey200,
    boxShadow: '0px 2px 4px rgb(0 0 0 / 25%)',
    '.MuiSwitch-colorSecondary.Mui-checked  &': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  switchTrack: {
    backgroundColor: theme.palette.common.grey300,
    '.MuiSwitch-colorSecondary.Mui-checked + &': {
      backgroundColor: theme.palette.common.green200,
    },
  },
  dateFieldRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 0',
  },
});

export default styles;
