import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import _get from 'lodash/get';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import DEFAULT_BRANCH_TIMING from '@lumirental/lumi-web-sdk/dist/constants/date/DEFAULT_BRANCH_TIMING';
import getKSADate from '@lumirental/lumi-web-sdk/dist/utils/store/getKSADate';
import DATE_TIME_PICKER from '@lumirental/lumi-web-shared/lib/constants/date/DATE_TIME_PICKER';
import useAB from '@lumirental/lumi-web-shared/lib/hooks/useAB';
import getDateObjectFromDateString from '@lumirental/lumi-web-shared/lib/utils/date/getDateObjectFromDateString';
import getLocaleDayMonth from '@lumirental/lumi-web-shared/lib/utils/date/getLocaleDayMonth';
import getNextDay from '@lumirental/lumi-web-shared/lib/utils/date/getNextDay';
import getNextWorkingDay from '@lumirental/lumi-web-shared/lib/utils/date/getNextWorkingDay';
import shouldDisableDropOffDate from '@lumirental/lumi-web-shared/lib/utils/date/shouldDisableDropOffDate';
import shouldDisableToday from '@lumirental/lumi-web-shared/lib/utils/date/shouldDisableToday';
import getNextAvailableDate from '@lumirental/lumi-web-shared/lib/utils/getNextAvailableDate';
import dateSelected from '@lumirental/lumi-web-shared/lib/utils/gtm/dateSelected';

import { DROPOFF_DATE, PICKUP_DATE } from '../../../constants';
import DatePicker from '../../DatePicker';
import DesktopDatePicker from '../../DekstopDatePicker';

import styles from './DatePickerWrapper.style';

const useStyles = makeStyles()(styles);

/**
 * Custom component for Date picker header
 */
function DatePickerHeader({ language, date }) {
  const { classes } = useStyles();
  const yearTxt = format(date, 'yyy');
  const monthTxt = format(date, 'E, d MMMM');
  const dayMonthTxt = getLocaleDayMonth(date, language);

  const yearTestId = yearTxt;
  const dayMonthTestId = monthTxt;

  return (
    <div>
      <Typography
        variant="subtitle1"
        className={classes.yearText}
        data-testid={yearTestId}
      >
        {yearTxt}
      </Typography>
      <Typography
        variant="h3"
        className={classes.dateText}
        data-testid={dayMonthTestId}
      >
        {dayMonthTxt}
      </Typography>
    </div>
  );
}

export default function DatePickerWrapper({
  id,
  value,
  dataTestId,
  onDateConfirmClick,
  searchPageProps,
  customClasses = {
    textField: '',
  },
  forwardedRef,
  isError = false,
  agreementMaxExtensionDate,
}) {
  const { classes } = useStyles();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const textFieldStyle = `${classes.dateField} ${customClasses.textField}`;
  const shakeClass = `${isError ? classes.shake : ''}`;

  // local state
  const [showDatePickerDialog, setShowDatePickerDialog] = useState(false);
  const [datePickerError, setDatePickerError] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [disableDatePickerBtn, setDisableDatePickerBtn] = useState(false);
  const [backupDates, setBackupDates] = useState({});
  const [disableToday, setDisableToday] = useState(false);

  const isPickupDateDialogOpen = id === 'pickupDate';
  const label = isPickupDateDialogOpen ? t('pickup_date') : t('dropoff_date');
  const cId = isPickupDateDialogOpen ? 'pickup-date' : 'dropoff-date';
  const datePickerBtnText = isPickupDateDialogOpen
    ? t('confirm_pickup_date')
    : t('confirm_dropoff_date');

  const isSearchUpdateEnabled = useAB('auto_search_update_tag');

  // read props for csr
  const {
    // language,
    pickupBranch,
    dropoffBranch,
    pickupDate,
    pickupTime,
    dropoffDate,
    setPickUpDate,
    setDropOffDate,
    setBookingDates,
    setOpenPickupTimer,
    setOpenDropoffTimer,
  } = searchPageProps;

  const calendarDates = {
    from: pickupDate,
    to: dropoffDate,
  };
  const cardDate = isPickupDateDialogOpen ? pickupDate : dropoffDate;

  const pickUpOffDays = _get(pickupBranch, 'offDays', []);
  const dropOffDays = _get(dropoffBranch, 'offDays', []);
  const offDays = isPickupDateDialogOpen ? pickUpOffDays : dropOffDays;
  const onDays = isPickupDateDialogOpen
    ? _get(pickupBranch, 'onDays', {})
    : _get(dropoffBranch, 'onDays', {});
  const defaultSelectedDate = isPickupDateDialogOpen ? pickupDate : dropoffDate;
  const defaultWeekDay = getDay(defaultSelectedDate);
  const isDefaultDayOff = offDays.indexOf(defaultWeekDay) !== -1;
  const leadTimeMinutes = _get(
    pickupBranch,
    'leadTimeMinutes',
    DATE_TIME_PICKER.T_PLUS_240_M,
  );
  const todayWeekDay = getDay(getKSADate(new Date())); // convert to ksa time zone
  const todayTiming = _get(onDays, `${todayWeekDay}`, DEFAULT_BRANCH_TIMING);
  const isTodayDisabled = shouldDisableToday(todayTiming, leadTimeMinutes);

  /**
   * Effect to Update Date to next available date
   * when Branch is change
   * if the branch is Closed then get next available date
   */
  useEffect(() => {
    if (isSearchUpdateEnabled && cardDate) {
      const {
        nextPickUpWorkingDay,
        nextDropOffWorkingDay,
        isPickUpDateChanged,
        isDropOffDateChanged,
      } = getNextAvailableDate(
        pickupDate,
        dropoffDate,
        pickUpOffDays,
        dropOffDays,
        isTodayDisabled,
      );

      // update global store
      if (isPickUpDateChanged && isDropOffDateChanged) {
        setBookingDates(nextPickUpWorkingDay, nextDropOffWorkingDay);
      } else {
        isPickUpDateChanged && setPickUpDate(nextPickUpWorkingDay);
        isDropOffDateChanged && setDropOffDate(nextDropOffWorkingDay);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDate, pickupBranch, dropoffBranch]);

  /**
   * Effect to take a backup of the
   * pick and drop dates
   * when date picker dialog opens
   */
  useEffect(() => {
    if (showDatePickerDialog) {
      setBackupDates({
        from: pickupDate,
        to: dropoffDate,
      });
      setDatePickerError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDatePickerDialog]);

  /**
   * Effect to check if the default pickup
   * date is an off day when date picker opens
   * fix for LR-460
   */
  useEffect(() => {
    if (showDatePickerDialog) {
      if (isDefaultDayOff) {
        setDisableDatePickerBtn(true);
      } else {
        setDisableDatePickerBtn(false);
      }
    }
  }, [showDatePickerDialog, isDefaultDayOff]);

  /**
   * Effect to find out if we should disable today
   * when date picker is open
   * if T + LeadTime > branch closing time
   */
  useEffect(() => {
    if (showDatePickerDialog) {
      setDisableToday(isTodayDisabled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDatePickerDialog, onDays]);

  /**
   * Show date picker dialog
   */
  const handleDatePickerOpen = () => {
    setShowDatePickerDialog(true);
  };

  /**
   * Hide date picker dialog
   */
  const handleCloseDialog = () => {
    setShowDatePickerDialog(false);
  };

  /**
   * When clicked on the date picker backdrop
   */
  const handleClickOutside = () => {
    const { from, to } = backupDates;
    // update global store
    setBookingDates(from, to);
  };

  /**
   * Handles date click inside calendar
   * @param {Date} day
   * @param {Boolean} disabled
   */
  const handleDaySelection = (day, { disabled }) => {
    let dateErrorMsg = '';

    if (disabled) {
      // user clicked on disabled date
      const dayOfWeek = getDay(day);
      const isOffDay = offDays.indexOf(dayOfWeek) !== -1;
      const maxLimitExtensionDate = getDateObjectFromDateString(
        agreementMaxExtensionDate,
      );

      // check if day selected is in past or future
      const today = getKSADate(new Date()); // convert to ksa time zone
      const diffInDays = differenceInCalendarDays(day, today);
      const diffInDaysFromPickupDate = differenceInCalendarDays(
        day,
        pickupDate,
      );
      const diffInDaysFromExtensionDate = differenceInCalendarDays(
        day,
        maxLimitExtensionDate,
      );

      // if selected disabled date falls out of range
      if (diffInDays < 0) {
        dateErrorMsg = t('please_select_a_day_in_the_future');
      } else if (isPickupDateDialogOpen && diffInDays === 0) {
        // if today was disabled due to t+4 > branch close time
        dateErrorMsg = t('no_time_slots_available');
      } else if (!isPickupDateDialogOpen && diffInDaysFromPickupDate >= 365) {
        dateErrorMsg = t('maximum_rental_duration_allowed_is_1_year');
      } else if (!isPickupDateDialogOpen && diffInDaysFromPickupDate < 0) {
        dateErrorMsg = t('please_select_a_day_in_the_future');
      } else if (isPickupDateDialogOpen && diffInDays >= 90) {
        dateErrorMsg = t('please_select_a_date_within_90_days_from_today');
      } else if (isOffDay) {
        dateErrorMsg = t('branch_is_closed_on_selected_date_and_time');
      } else if (agreementMaxExtensionDate && diffInDaysFromExtensionDate > 0) {
        dateErrorMsg = t('please_select_a_date_within_90_days_from_today');
      }
      setDatePickerError(dateErrorMsg); // update local state
      setOpenSnackBar(true); // update local state
      setDisableDatePickerBtn(true); // update local state
    } else {
      // user clicked on correct date
      if (isPickupDateDialogOpen) {
        // if pick up date picker is open
        const nextDay = getNextDay(day);
        const offDays = _get(dropoffBranch, 'offDays', []); // get dropoff off days
        const nextWorkingDay = getNextWorkingDay(nextDay, offDays);

        // update global store
        setBookingDates(day, nextWorkingDay);
      } else {
        // if drop off date picker is open
        setDropOffDate(day); // update global store

        // if pick up and drop dates are same
        // pick up time is near to branch closing time
        // drop off time 30 mins gap is more than branch closing time
        // we need to disable drop date in that case
        // check this when dropoff date dialog is open
        const selectedWeekDay = getDay(day); // eg. 0, 1, 3 etc
        const selectedDayTiming = _get(
          onDays,
          `${selectedWeekDay}`,
          DEFAULT_BRANCH_TIMING,
        ); // read from store
        const isDropDateDisabled = shouldDisableDropOffDate(
          selectedDayTiming,
          pickupDate,
          pickupTime,
          day, // current dropoff date
        );
        if (isDropDateDisabled) {
          dateErrorMsg = t('no_time_slots_available');
          setDatePickerError(dateErrorMsg); // update local state
          setOpenSnackBar(true); // update local state
          setDisableDatePickerBtn(true); // update local state
          return;
        }
      }
      setDatePickerError(''); // update local state
      setOpenSnackBar(false); // update local state
      setDisableDatePickerBtn(false); // update local state
    }
  };

  /**
   * Handle date picker confirm button click
   * Just close the dialog, since the pick
   * and drop dates are already updated in store
   */
  const handleConfirmDatePicker = () => {
    handleCloseDialog(); // close date picker dialog

    // open time picker once confirm btn is clicked
    if (isPickupDateDialogOpen) {
      setOpenPickupTimer(true); // update global store

      // send GTM event when pickup date is selected
      dateSelected(PICKUP_DATE, pickupDate);
    } else {
      setOpenDropoffTimer(true); // update global store

      // send GTM event when dropoff date is selected
      dateSelected(DROPOFF_DATE, dropoffDate);
    }

    // invoke callback
    onDateConfirmClick && onDateConfirmClick();
  };

  /**
   * Handle snack bar close
   */
  const handleSnackBarClose = (evt, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  return (
    <>
      <TextField
        fullWidth
        label={label}
        value={value}
        id={cId}
        variant="outlined"
        onClick={handleDatePickerOpen}
        className={textFieldStyle}
        InputProps={{
          readOnly: true,
          classes: {
            root: shakeClass,
            input: classes.inputField,
            notchedOutline: classes.inputBorder,
          },
          ref: forwardedRef,
        }}
        data-testid={dataTestId}
        error={isError}
      />
      <Dialog
        open={showDatePickerDialog}
        onClose={handleCloseDialog}
        onBackdropClick={handleClickOutside}
        classes={{
          scrollPaper: classes.scrollPaper,
          paper: classes.paper,
        }}
      >
        <DialogContent className={classes.mainWrapper}>
          <>
            <div className={classes.header}>
              <DatePickerHeader language={language} date={cardDate} />
            </div>
            <div className={classes.contentWrapper}>
              <DatePicker
                language={language}
                handleDayClick={handleDaySelection}
                calendarDates={calendarDates}
                isPickupDateDialogOpen={isPickupDateDialogOpen}
                offDays={offDays}
                disableToday={disableToday}
                agreementMaxExtensionDate={agreementMaxExtensionDate}
              />
            </div>
            <div className={classes.actionWrapper}>
              <Button
                fullWidth
                variant="contained"
                disabled={disableDatePickerBtn}
                onClick={handleConfirmDatePicker}
                data-testid="searchDatePickerConfirmBtn"
              >
                {datePickerBtnText}
              </Button>
            </div>
          </>
        </DialogContent>
      </Dialog>
      <Snackbar
        classes={{
          root: classes.snackBarRoot,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackBar}
        onClose={handleSnackBarClose}
        autoHideDuration={1500}
        data-testid="selectDateSnackbarError"
      >
        <SnackbarContent
          classes={{
            root: classes.snackBarContent,
          }}
          message={datePickerError}
        />
      </Snackbar>
    </>
  );
}
