import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';

import { makeStyles } from 'tss-react/mui';

import styles from '@/components/Icons/Hamburger/HamburgerIcon.style';

const useStyles = makeStyles()(styles);

export default function HamburgerIcon({ handleClick, dataTestId }) {
  const { classes } = useStyles();
  return (
    <IconButton
      component="span"
      onClick={handleClick}
      className={classes.hamburgerIcon}
      data-testid={dataTestId}
    >
      <img
        src="/images/svg/hamburger-icon.svg"
        alt="Hamburger Icon"
        width="100%"
        height="100%"
      />
    </IconButton>
  );
}

HamburgerIcon.propTypes = {
  handleClick: PropTypes.func,
};
