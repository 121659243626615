const styles = (theme) => ({
  card: {
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.grey20,
    border: `1px solid ${theme.palette.common.grey20}`,
    marginTop: 8,
    marginBottom: 8,
    borderRadius: 8,
    boxShadow: 'none',
  },
  cardIcon: {
    marginRight: 12,
    maxWidth: 18,
  },
  cardContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '16px 18px',
    '&:last-child': {
      paddingBottom: 16,
    },
  },
  contentInner: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
  },
  textContent: {
    width: '100%',
  },
  cardTitle: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize12,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightBold,
  },
  cardText: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize12,
    lineHeight: 1.5,
    marginBottom: 4,
  },
  timingSummaryWrapper: {
    paddingBottom: 8,
    borderBottom: `1px solid ${theme.palette.common.grey20}`,
  },
  timingSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.common.grey300,
    fontSize: theme.typography.fontSize12,
    lineHeight: 1.5,
    textAlign: 'left',
    '& > span:last-child': {
      minWidth: 110,
    },
  },
  timingGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  btnWrapper: {
    marginTop: 12,
  },
  findMoreBtn: {
    fontSize: theme.typography.fontSize12,
    lineHeight: 1.5,
    padding: 0,
    textTransform: 'none',
    '& .MuiButton-endIcon': {
      marginLeft: 2,
    },
  },
});

export default styles;
