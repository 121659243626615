/**
 * Custom Accordion Component
 * Material UI Accordion has perf issues
 * https://github.com/mui-org/material-ui/issues/10931
 * https://medium.com/@freshmilkdev/reactjs-render-optimization-for-collapsible-material-ui-long-list-with-checkboxes-231b36892e20
 */

import React from 'react';
import PropTypes from 'prop-types';

import ButtonBase from '@mui/material/ButtonBase';

import { makeStyles } from 'tss-react/mui';

import styles from './Accordion.style';

const useStyles = makeStyles()(styles);

export default function Accordion({
  value,
  expanded,
  setExpanded,
  header,
  headerClickHandler,
  children,
  accordionHeaderClass,
}) {
  const { classes } = useStyles();

  const isAccordianOpen = value === expanded;
  const expandedValue = isAccordianOpen ? false : value;

  const accordionSummaryClass = `${classes.accordionSummary} ${
    isAccordianOpen ? classes.accordionActiveSummary : ''
  } ${accordionHeaderClass}`;

  const handleClick = () => {
    setExpanded(expandedValue);
    if (headerClickHandler && typeof headerClickHandler === 'function') {
      headerClickHandler();
    }
  };

  return (
    <div className={classes.accordion}>
      <ButtonBase onClick={handleClick} className={accordionSummaryClass}>
        {header}
      </ButtonBase>

      {isAccordianOpen && (
        <div key="content" className={classes.accordionDetails}>
          {children}
        </div>
      )}
    </div>
  );
}

Accordion.propTypes = {
  value: PropTypes.string.isRequired,
  expanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  setExpanded: PropTypes.func.isRequired,
  header: PropTypes.element.isRequired,
  headerClickHandler: PropTypes.func,
  children: PropTypes.node.isRequired,
};
Accordion.defaultProps = {
  headerClickHandler: () => {},
};
