import React, { Component } from 'react';
import _keys from 'lodash/keys';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';

import PickerColumn from './PickerColumn';

import styles from './Picker.style';

class Picker extends Component {
  renderInner() {
    const {
      language,
      optionGroups,
      valueGroups,
      itemHeight,
      height,
      closedText,
      onChange,
      onClick,
      onCloseHover,
    } = this.props;
    const highlightStyle = {
      height: itemHeight,
      marginTop: -(itemHeight / 2),
    };
    const columnNodes = [];
    _keys(optionGroups).map((optionName) => {
      return columnNodes.push(
        <PickerColumn
          language={language}
          key={optionName}
          name={optionName}
          options={optionGroups[optionName]}
          value={valueGroups[optionName]}
          itemHeight={itemHeight}
          columnHeight={height}
          closedText={closedText}
          onChange={onChange}
          onClick={onClick}
          onCloseHover={onCloseHover}
        />,
      );
    });

    const { classes } = this.props;
    return (
      <div className={`picker-container ${classes.pickerContainer}`}>
        <div className={`picker-inner ${classes.pickerInner}`}>
          {columnNodes}
          <div
            className={`picker-highlight ${classes.pickerHighlight}`}
            style={highlightStyle}
          />
        </div>
      </div>
    );
  }

  render() {
    const { classes, indicator, height } = this.props;
    const style = {
      height,
    };

    return (
      <div className={classes.pickerWrapper} style={style}>
        <span className={classes.labelIndicator}>{indicator}</span>
        {this.renderInner()}
      </div>
    );
  }
}

Picker.propTypes = {
  language: PropTypes.string,
  optionGroups: PropTypes.object.isRequired,
  valueGroups: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  itemHeight: PropTypes.number,
  height: PropTypes.number,
};

Picker.defaultProps = {
  onClick: () => {},
  itemHeight: 36,
  height: 110,
};

export default withStyles(styles)(Picker);
