import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import styles from './FreeCarDeliveryDialog.style';

const useStyles = makeStyles()(styles);

export default function FreeCarDeliveryDialog({
  openDialog,
  handleCloseDialog,
}) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogContent className={classes.mainWrapper}>
        <Typography variant="h3" className={classes.title}>
          {t('free_car_delivery')}
        </Typography>
        <div className={classes.content}>
          <Typography variant="body1" className={classes.paragraph}>
            {t('free_car_delivery_body')}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions className={classes.actionWrapper}>
        <Button
          fullWidth
          variant="contained"
          className={classes.agreeBtn}
          onClick={handleCloseDialog}
        >
          {t('i_understand')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FreeCarDeliveryDialog.propTypes = {
  openDialog: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
};
