import React from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import CAR_DELIVERY_DURATION from '@lumirental/lumi-web-shared/lib/constants/date/CAR_DELIVERY_DURATION';
import LANGUAGES from '@lumirental/lumi-web-shared/lib/constants/lang/LANGUAGES';
import useAB from '@lumirental/lumi-web-shared/lib/hooks/useAB';
import getArabicDayWithLabel from '@lumirental/lumi-web-shared/lib/utils/date/getArabicDayWithLabel';
import getCapitalizeDayLabel from '@lumirental/lumi-web-shared/lib/utils/date/getCapitalizeDayLabel';
import getTimeWithArabicLabel from '@lumirental/lumi-web-shared/lib/utils/date/getTimeWithArabicLabel';

import styles from './CarDeliveryCard.style';

const useStyles = makeStyles()(styles);

export default function CarDeliveryCard({
  language,
  branch,
  handleCarDelivery,
}) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const isArabic = LANGUAGES.AR === language;

  const isMinimumDeliveryFeatureFlag = useAB('minimum_car_delivery_tag');

  // drived value
  const branchName = _get(branch, 'name', '');
  const timingSummary = _get(branch, 'timingSummary', []);

  const forBookingInfo = t('for_bookings_over_xdays', {
    0: isMinimumDeliveryFeatureFlag
      ? CAR_DELIVERY_DURATION.DAYS_15
      : CAR_DELIVERY_DURATION.DAYS_30,
  });

  const branchTimingSummary =
    timingSummary &&
    timingSummary.map((item) => {
      const timeLabel = _get(item, 'timeLabel', '');
      const dayLabel = _get(item, 'dayLabel', '');
      const formattedTimeClosed = isArabic
        ? getTimeWithArabicLabel(timeLabel)
        : timeLabel;
      const timeSlots = item?.timeRanges?.length
        ? item.timeRanges.map((timeRange) => {
            const formattedTime = isArabic
              ? getTimeWithArabicLabel(timeRange)
              : timeRange;
            return formattedTime;
          })
        : [formattedTimeClosed]; // return time slot as array e.g ['Closed']
      const formattedDay = isArabic
        ? getArabicDayWithLabel(dayLabel)
        : dayLabel;
      const capitalDay = formattedDay && getCapitalizeDayLabel(formattedDay);
      const formattedDayInfo =
        capitalDay && capitalDay.replace(' - ', ` ${t('to')} `);

      return (
        <React.Fragment key={item.dayLabel}>
          <Typography className={classes.timingSummary}>
            <span>{formattedDayInfo}</span>
            <span className={classes.timingGroup}>
              {timeSlots.map((slot) => {
                return <span>{slot}</span>;
              })}
            </span>
          </Typography>
        </React.Fragment>
      );
    });

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div className={classes.contentInner}>
          <div className={classes.cardIcon}>
            <img src="/images/svg/car-delivery.svg" alt="Lumi Car Delivery" />
          </div>
          <div className={classes.textContent}>
            <Typography variant="h4" className={classes.cardTitle}>
              {branchName}
            </Typography>
            <Typography className={classes.cardText}>
              {forBookingInfo}
            </Typography>

            <div className={classes.timingSummaryWrapper}>
              {branchTimingSummary}
            </div>

            <div className={classes.btnWrapper}>
              <Button
                variant="text"
                className={classes.findMoreBtn}
                onClick={handleCarDelivery}
                endIcon={
                  language === LANGUAGES.EN ? (
                    <img
                      src="/images/svg/arrow-next-icon.svg"
                      alt="Arrow Next"
                    />
                  ) : (
                    <img
                      src="/images/svg/arrow-prev-icon.svg"
                      alt="Arrow Previous"
                    />
                  )
                }
              >
                {t('book_free_car_delivery_now')}
              </Button>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

CarDeliveryCard.propTypes = {
  branch: PropTypes.object.isRequired,
};
