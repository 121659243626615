import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import styles from './LocationDenyInfoDialog.style';

const useStyles = makeStyles()(styles);

export default function LocationDenyInfoDialog({
  openDialog,
  handleCloseDialog,
}) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogContent className={classes.mainWrapper}>
        <Typography variant="body1" className={classes.paragraph}>
          {t('location_error')}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actionWrapper}>
        <Button
          variant="text"
          className={classes.cancelBtn}
          onClick={handleCloseDialog}
        >
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

LocationDenyInfoDialog.propTypes = {
  openDialog: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
};
