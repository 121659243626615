import { keyframes } from 'tss-react';

/**
 * Animation properties defined here
 */

export const easing = [0.6, -0.5, 0.01, 0.99];

export const fadeToggle = {
  fadeIn: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 0.5,
      easing,
    },
  },
  fadeOut: {
    opacity: 1,
    height: 19,
    transition: {
      duration: 0.5,
      easing,
    },
  },
};

export const sizeToggle = {
  smallIn: {
    scale: 1,
    left: 0,
  },
  largeIn: {
    scale: 1.1,
  },
};

export const moveCenter = {
  animate: (xCr) => ({
    x: xCr,
    opacity: 1,
    transition: {
      duration: 0.2,
      ease: 'easeOut',
    },
  }),
};

export const accordianAnimation = {
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
  transition: {
    duration: 0.4,
    ease: [0.04, 0.62, 0.23, 0.98],
  },
};

export const shakeHorizontal = keyframes` 
    0 %  { transform: translate(1px, 1px) },
    10%  { transform: translate(-1px, 0px) },
    20%  { transform: translate(-3px, 0px) },
    30%  { transform: translate(3px, 0px) },
    40%  { transform: translate(1px, 0px) },
    50%  { transform: translate(-1px, 0px) },
    60%  { transform: translate(-3px, 0px) },
    70%  { transform: translate(3px, 0px) },
    80%  { transform: translate(-1px, 0px) },
    100% { transform: translate(1px, 0px) }`;
