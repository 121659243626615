import React, { useEffect, useState } from 'react';
import DayPicker from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import addDays from 'date-fns/addDays';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import getDay from 'date-fns/getDay';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import DEFAULT_BRANCH_TIMING from '@lumirental/lumi-web-sdk/dist/constants/date/DEFAULT_BRANCH_TIMING';
import GREGORIAN_MONTHS from '@lumirental/lumi-web-sdk/dist/constants/date/GREGORIAN_MONTHS';
import getKSADate from '@lumirental/lumi-web-sdk/dist/utils/store/getKSADate';
import DATE_TIME_PICKER from '@lumirental/lumi-web-shared/lib/constants/date/DATE_TIME_PICKER';
import DIR from '@lumirental/lumi-web-shared/lib/constants/lang/DIR';
import useAB from '@lumirental/lumi-web-shared/lib/hooks/useAB';
import getArabicShortDayNameOfWeek from '@lumirental/lumi-web-shared/lib/utils/date/getArabicShortDayNameOfWeek';
import getNextDay from '@lumirental/lumi-web-shared/lib/utils/date/getNextDay';
import getNextWorkingDay from '@lumirental/lumi-web-shared/lib/utils/date/getNextWorkingDay';
import shouldDisableToday from '@lumirental/lumi-web-shared/lib/utils/date/shouldDisableToday';
import getNextAvailableDate from '@lumirental/lumi-web-shared/lib/utils/getNextAvailableDate';

import { useUrl } from '@/utils/useUrl';

import { DARK_CLASS } from '../../constants';

import styles from './DatePicker.style';

const useStyles = makeStyles()(styles);

function Navbar({ isArabic, onPreviousClick, onNextClick, className }) {
  const { classes } = useStyles();
  const wrapperClass = isArabic ? `${className} ${DIR.RTL}` : className;
  return (
    <div className={wrapperClass}>
      <button onClick={() => onPreviousClick()} className={classes.navBtn}>
        <img src="/images/svg/arrow-prev-marble.svg" alt="Arrow Previous" />
      </button>
      <button onClick={() => onNextClick()} className={classes.navBtn}>
        <img src="/images/svg/arrow-next-marble.svg" alt="Arrow Next" />
      </button>
    </div>
  );
}

function Weekday({ weekday, className, localeUtils, locale, isArabic }) {
  const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
  const shortWeekdayName = weekdayName.slice(0, 1);
  // get halfWeekdayName to find the respective weekday name in arabic.
  const halfWeekdayName = weekdayName.slice(0, 2);
  const formattedWeekdayName = isArabic
    ? getArabicShortDayNameOfWeek(halfWeekdayName)
    : shortWeekdayName;
  return (
    <div className={className} title={weekdayName}>
      {formattedWeekdayName}
    </div>
  );
}

export default function DesktopDatePicker({
  id,
  value,
  handleDateClick,
  isThemeChange,
  handleDateUpdate,
  forwardedRef,
  searchPageProps,
  isError = false,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [datePickerError, setDatePickerError] = useState('');

  const { classes } = useStyles();
  const { t, i18n } = useTranslation();

  const isArabic = i18n.language === 'ar';

  const testId = `searchSelect${id}`;
  const isCalendarOpen = Boolean(anchorEl);
  const isPickupDateDialogOpen = id === 'pickupDate';
  const label = isPickupDateDialogOpen ? t('pickup_date') : t('dropoff_date');
  const datePickupClass = isPickupDateDialogOpen ? 'date-pickup-active' : '';
  const shakeClass = `${isError ? classes.shake : ''}`;
  const fieldLabelClass = `${classes.fieldLabel} ${shakeClass} ${
    isError ? 'error' : ''
  }`;

  // get months on the base of locale
  const months = isArabic ? GREGORIAN_MONTHS.AR : GREGORIAN_MONTHS.EN;

  const isSearchUpdateEnabled = useAB('auto_search_update_tag');

  // read props for csr
  const {
    pickupBranch,
    dropoffBranch,
    pickupDate,
    dropoffDate,
    setPickUpDate,
    setDropOffDate,
  } = searchPageProps;

  const pickerDate = isPickupDateDialogOpen ? pickupDate : dropoffDate;
  const selectedDays = isPickupDateDialogOpen
    ? pickupDate
    : [pickupDate, { from: pickupDate, to: dropoffDate }];
  const modifiers = { start: pickupDate, end: dropoffDate };
  const pickUpOffDays = _get(pickupBranch, 'offDays', []);
  const dropOffDays = _get(dropoffBranch, 'offDays', []);
  const offDays = isPickupDateDialogOpen ? pickUpOffDays : dropOffDays;

  const onDays = isPickupDateDialogOpen
    ? _get(pickupBranch, 'onDays', {})
    : _get(dropoffBranch, 'onDays', {});
  const todayDate = new Date();

  const daysOfWeek = !_isEmpty(offDays) ? offDays : [];
  const leadTimeMinutes = _get(
    pickupBranch,
    'leadTimeMinutes',
    DATE_TIME_PICKER.T_PLUS_240_M,
  );
  const todayWeekDay = getDay(getKSADate(todayDate)); // convert to ksa time zone
  const todayTiming = _get(onDays, `${todayWeekDay}`, DEFAULT_BRANCH_TIMING);
  const isTodayDisabled = shouldDisableToday(todayTiming, leadTimeMinutes);

  // add a day
  const nextDay = isTodayDisabled
    ? todayDate.setDate(todayDate.getDate() + 1)
    : todayDate;

  const disabledBeforeDays = isPickupDateDialogOpen
    ? getKSADate(nextDay)
    : pickupDate;
  const disabledAfterDays = isPickupDateDialogOpen
    ? addDays(getKSADate(new Date()), 90)
    : addDays(pickupDate, 365);

  const disabledDays = [
    {
      before: disabledBeforeDays,
      after: disabledAfterDays,
    },
    {
      daysOfWeek,
    },
  ];

  const selectedMonth = isPickupDateDialogOpen ? pickupDate : dropoffDate;

  const paperClass = `${classes.paper} ${isThemeChange ? DARK_CLASS : ''}`;

  const { changeParams } = useUrl();

  /**
   * Effect to Update Date to next available date
   * when Branch is change
   * if the branch is Closed then get next available date
   */
  useEffect(() => {
    if (isSearchUpdateEnabled && pickerDate) {
      const {
        nextPickUpWorkingDay,
        nextDropOffWorkingDay,
        isPickUpDateChanged,
        isDropOffDateChanged,
      } = getNextAvailableDate(
        pickupDate,
        dropoffDate,
        pickUpOffDays,
        dropOffDays,
        isTodayDisabled,
      );

      // update global store
      isPickUpDateChanged && setPickUpDate(nextPickUpWorkingDay);
      isDropOffDateChanged && setDropOffDate(nextDropOffWorkingDay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickerDate, pickupBranch, dropoffBranch]);

  const handleCalendarOpen = (event) => {
    setDatePickerError(''); // update local state
    setAnchorEl(event.currentTarget);
  };

  const handleCalendarClose = () => {
    setAnchorEl(null);
  };

  const handleDayClick = (day, { disabled }) => {
    console.log('** handleDayClick: ', day, disabled);
    let dateErrorMsg = '';

    if (disabled) {
      // user clicked on disabled date
      const dayOfWeek = getDay(day);
      const isOffDay = offDays.indexOf(dayOfWeek) !== -1;

      // check if day selected is in past or future
      const today = getKSADate(new Date()); // convert to ksa time zone
      const diffInDays = differenceInCalendarDays(day, today);
      const diffInDaysFromPickupDate = differenceInCalendarDays(
        day,
        pickupDate,
      );
      // console.log(
      //   'Diff in days from today: ',
      //   diffInDays,
      //   day,
      //   today,
      //   diffInDaysFromPickupDate
      // );

      // if selected disabled date falls out of range
      if (diffInDays < 0) {
        dateErrorMsg = t('please_select_a_day_in_the_future');
      } else if (isPickupDateDialogOpen && diffInDays === 0) {
        // if today was disabled due to t+4 > branch close time
        dateErrorMsg = t('no_time_slots_available');
      } else if (!isPickupDateDialogOpen && diffInDaysFromPickupDate >= 365) {
        dateErrorMsg = t('maximum_rental_duration_allowed_is_1_year');
      } else if (!isPickupDateDialogOpen && diffInDaysFromPickupDate < 0) {
        dateErrorMsg = t('please_select_a_day_in_the_future');
      } else if (isPickupDateDialogOpen && diffInDays >= 90) {
        dateErrorMsg = t('please_select_a_date_within_90_days_from_today');
      } else if (isOffDay) {
        dateErrorMsg = t('branch_is_closed_on_selected_date_and_time');
      }
      setDatePickerError(dateErrorMsg); // update local state
    } else {
      // user clicked on correct date
      if (isPickupDateDialogOpen) {
        const nextDay = getNextDay(day);
        const offDays = _get(dropoffBranch, 'offDays', []); // get dropoff off days
        const nextWorkingDay = getNextWorkingDay(nextDay, offDays);

        changeParams({
          pickUpDate: day,
          dropOffDate: nextWorkingDay,
        }); // update search params
      } else {
        setDropOffDate(day); // update global store
      }
      // close calendar when date selected.
      handleCalendarClose();
      setDatePickerError(''); // update local state
      handleDateClick && handleDateClick(day); // invoke callback
    }
  };

  return (
    <div className={classes.wrapper}>
      <label htmlFor={id} className={fieldLabelClass}>
        {label}
      </label>
      <TextField
        fullWidth
        value={value}
        id={id}
        onClick={handleCalendarOpen}
        InputProps={{
          readOnly: true,
          classes: {
            root: shakeClass,
            input: classes.inputField,
          },
          ref: forwardedRef,
        }}
        data-testid={testId}
        error={isError}
      />
      <Popover
        id={id}
        open={isCalendarOpen}
        anchorEl={anchorEl}
        onClose={handleCalendarClose}
        classes={{
          paper: paperClass,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transition={false}
      >
        <div className={`${datePickupClass} ${classes.datePickerWrapper}`}>
          <div className={classes.dayPickerWrapper}>
            <DayPicker
              modifiers={modifiers}
              selectedDays={selectedDays}
              onDayClick={handleDayClick}
              months={months}
              month={selectedMonth}
              weekdayElement={<Weekday isArabic={isArabic} />}
              navbarElement={<Navbar isArabic={isArabic} />}
              disabledDays={disabledDays}
            />
          </div>
        </div>
        {datePickerError && (
          <div className={classes.snackbar}>
            <Typography variant="body1">{datePickerError}</Typography>
          </div>
        )}
      </Popover>
    </div>
  );
}
