import React from 'react';
import DayPicker from 'react-day-picker';
import addDays from 'date-fns/addDays';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import _isEmpty from 'lodash/isEmpty';

import { makeStyles } from 'tss-react/mui';

import GREGORIAN_MONTHS from '@lumirental/lumi-web-sdk/dist/constants/date/GREGORIAN_MONTHS';
import getKSADate from '@lumirental/lumi-web-sdk/dist/utils/store/getKSADate';
import DIR from '@lumirental/lumi-web-shared/lib/constants/lang/DIR';
import LANGUAGES from '@lumirental/lumi-web-shared/lib/constants/lang/LANGUAGES';
import getArabicShortDayNameOfWeek from '@lumirental/lumi-web-shared/lib/utils/date/getArabicShortDayNameOfWeek';
import getDateObjectFromDateString from '@lumirental/lumi-web-shared/lib/utils/date/getDateObjectFromDateString';

import styles from './DatePicker.style';

const useStyles = makeStyles()(styles);

function Navbar({ isArabic, onPreviousClick, onNextClick, className }) {
  const { classes } = useStyles();
  const wrapperClass = isArabic ? `${className} ${DIR.RTL}` : className;
  return (
    <div className={wrapperClass}>
      <button onClick={() => onPreviousClick()} className={classes.navBtn}>
        <img src="/images/svg/arrow-prev-marble.svg" alt="Arrow Previous" />
      </button>
      <button onClick={() => onNextClick()} className={classes.navBtn}>
        <img src="/images/svg/arrow-next-marble.svg" alt="Arrow Next" />
      </button>
    </div>
  );
}

function Weekday({ weekday, className, localeUtils, locale, isArabic }) {
  const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
  const shortWeekdayName = weekdayName.slice(0, 1);
  // get halfWeekdayName to find the respective weekday name in arabic.
  const halfWeekdayName = weekdayName.slice(0, 2);
  const formattedWeekdayName = isArabic
    ? getArabicShortDayNameOfWeek(halfWeekdayName)
    : shortWeekdayName;
  return (
    <div className={className} title={weekdayName}>
      {formattedWeekdayName}
    </div>
  );
}

function DatePicker({
  language,
  handleDayClick,
  calendarDates: { from, to },
  isPickupDateDialogOpen,
  offDays,
  disableToday,
  agreementMaxExtensionDate,
}) {
  const { classes } = useStyles();

  const modifiers = { start: from, end: to };
  const isArabic = !!(language === LANGUAGES.AR);
  const selectedDays = isPickupDateDialogOpen ? from : [from, { from, to }];

  const maxLimitExtensionDate = getDateObjectFromDateString(
    agreementMaxExtensionDate,
  );
  const maxLimitExtensionKSADate = getKSADate(maxLimitExtensionDate);
  const maxLimitExtensionDays =
    differenceInCalendarDays(maxLimitExtensionKSADate, from) + 1;

  // Define the target date (01/01/2025)
  const disabledBeforeDays = isPickupDateDialogOpen
    ? getKSADate(new Date())
    : from;
  const disabledAfterDays = isPickupDateDialogOpen
    ? addDays(getKSADate(new Date()), 90)
    : addDays(from, agreementMaxExtensionDate ? maxLimitExtensionDays : 365);
  const datePickupClass = isPickupDateDialogOpen ? 'date-pickup-active' : '';
  const daysOfWeek = !_isEmpty(offDays) ? offDays : [];
  const selectedMonth = isPickupDateDialogOpen ? from : to;

  // get months on the base of locale
  const months = isArabic ? GREGORIAN_MONTHS.AR : GREGORIAN_MONTHS.EN;

  const disabledDays = [
    {
      before: disabledBeforeDays,
      after: disabledAfterDays,
    },
    {
      daysOfWeek,
    },
  ];

  // disable today
  if (disableToday) {
    disabledDays.push({
      from: getKSADate(new Date()),
      to: getKSADate(new Date()),
    });
  }

  return (
    <div className={`${datePickupClass} ${classes.datePickerWrapper}`}>
      <div className={classes.dayPickerWrapper}>
        <DayPicker
          modifiers={modifiers}
          selectedDays={selectedDays}
          onDayClick={handleDayClick}
          disabledDays={disabledDays}
          months={months}
          month={selectedMonth}
          weekdayElement={<Weekday isArabic={isArabic} />}
          navbarElement={<Navbar isArabic={isArabic} />}
        />
      </div>
    </div>
  );
}

export default DatePicker;
