'use client';

import Image from 'next/image';

import Skeleton from '@mui/material/Skeleton';

import DesktopOnly from '@/components/Responsive/DesktopOnly';
import MobileOnly from '@/components/Responsive/MobileOnly';

const mobileStyle: any = {
  searchWrapper: {
    backgroundColor: 'white',
    borderRadius: 8,
    marginBottom: 0,
  },
  banner: {
    position: 'relative',
    backgroundColor: '#364e52',
    padding: '36px 20px',
  },
  bannerPlain: {
    position: 'relative',
    padding: '20px',
  },
  titleWrapper: {
    position: 'relative',
    zIndex: 10,
  },
  title: {
    color: 'white',
    fontSize: '1.375rem',
    letterSpacing: '-0.01562em',
    lineHeight: 1.2,
    fontWeight: 'bold',
    marginBottom: 4,
    marginTop: 0,
  },
  subTitle: {
    color: 'white',
    fontSize: '14px',
    lineHeight: 1.3,
    fontWeight: '600',
  },
  tabsSkeleton: {
    display: 'flex',
    gap: 5,
  },
};

const desktopStyles: any = {
  mainWrapper: {
    position: 'relative',
    backgroundColor: '#364e52',
    height: 463,
  },
  bgWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  wrapperInner: {
    width: '100%',
    padding: '40px 0px',
  },
  container: {
    position: 'relative',
    maxWidth: 1200,
    width: '100%',
    padding: '0 24px',
    zIndex: 100,
  },
  titleWrapper: {
    width: '100%',
    marginBottom: 24,
  },
  title: {
    color: 'white',
    fontSize: '38px',
    lineHeight: 1.3,
    margin: 0,
    fontWeight: 'bold',
  },
  subTitle: {
    color: 'white',
    fontSize: '15px',
    lineHeight: 1.3,
    fontWeight: 'semiBold',
  },
  tabsSkeleton: {
    display: 'flex',
    gap: 20,
    width: '30%',
  },
  pickerWrapperSkeleton: {
    display: 'flex',
    gap: 20,
    justifyContent: 'space-between',
  },
};

interface SearchBlockSkeletonProps {
  title: string;
  subTitle: string;
}

const SearchBlockSkeleton: React.FC<SearchBlockSkeletonProps> = ({
  title,
  subTitle,
}) => {
  return (
    <>
      <MobileOnly>
        <div style={mobileStyle.searchWrapper} id="searchBlock">
          <div style={mobileStyle.banner}>
            <Image
              alt="Lumi car rental"
              src="/images/png/mob-banner-bg.png"
              layout="fill"
              objectFit="cover"
              quality={100}
              priority
            />
            <div style={mobileStyle.titleWrapper}>
              {title && <h1 style={mobileStyle.title}>{title}</h1>}
              {subTitle && <div style={mobileStyle.subTitle}>{subTitle}</div>}
            </div>
          </div>
          <div style={mobileStyle.bannerPlain}>
            <div style={mobileStyle.tabsSkeleton}>
              <Skeleton animation="wave" width="50%" height={70} />
              <Skeleton animation="wave" width="50%" height={70} />
            </div>
            <Skeleton animation="wave" height={80} />
            <Skeleton animation="wave" height={30} />
            <Skeleton animation="wave" height={30} />
            <div style={mobileStyle.tabsSkeleton}>
              <Skeleton animation="wave" width="65%" height={70} />
              <Skeleton animation="wave" width="35%" height={70} />
            </div>
            <div style={mobileStyle.tabsSkeleton}>
              <Skeleton animation="wave" width="65%" height={70} />
              <Skeleton animation="wave" width="35%" height={70} />
            </div>
            <Skeleton animation="wave" height={70} />
          </div>
        </div>
      </MobileOnly>
      <DesktopOnly>
        <div style={desktopStyles.mainWrapper} id="searchBlock">
          <div style={desktopStyles.bgWrap}>
            <Image
              alt="Lumi car rental"
              src="/images/png/search-back.jpg"
              layout="fill"
              objectFit="cover"
              quality={10}
              priority
            />
          </div>
          <div style={desktopStyles.wrapperInner}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div style={desktopStyles.container}>
                <div style={desktopStyles.titleWrapper}>
                  {title && <h1 style={desktopStyles.title}>{title}</h1>}
                  {subTitle && (
                    <div style={desktopStyles.subTitle}>{subTitle}</div>
                  )}
                </div>

                <div>
                  <div style={desktopStyles.tabsSkeleton}>
                    <Skeleton
                      animation="wave"
                      width="50%"
                      height={60}
                      sx={{
                        background: '#ffffff3b',
                      }}
                    />
                    <Skeleton
                      animation="wave"
                      width="50%"
                      height={60}
                      sx={{
                        background: '#ffffff3b',
                      }}
                    />
                  </div>
                  <Skeleton
                    animation="wave"
                    height={80}
                    sx={{
                      background: '#ffffff3b',
                    }}
                  />
                  <div style={desktopStyles.pickerWrapperSkeleton}>
                    <Skeleton
                      animation="wave"
                      height={80}
                      width="25%"
                      sx={{
                        background: '#ffffff3b',
                      }}
                    />
                    <Skeleton
                      animation="wave"
                      width="25%"
                      height={80}
                      sx={{
                        background: '#ffffff3b',
                      }}
                    />
                    <Skeleton
                      animation="wave"
                      height={80}
                      width="25%"
                      sx={{
                        background: '#ffffff3b',
                      }}
                    />
                    <Skeleton
                      animation="wave"
                      height={80}
                      width="25%"
                      sx={{
                        background: '#ffffff3b',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DesktopOnly>
    </>
  );
};

export default SearchBlockSkeleton;
