const styles = (theme) => ({
  container: {
    width: 250,
    flex: 1,
  },
  list: {
    paddingTop: 24,
    paddingBottom: 24,
    marginBottom: 24,
  },
  listItem: {
    padding: '12px 16px',
    '& .MuiListItemIcon-root': {
      minWidth: 'auto',
      marginRight: 12,
      '& > .MuiSvgIcon-root': {
        width: 18,
        height: 18,
      },
    },
    '&.active': {
      backgroundColor: theme.palette.common.purple100,
      '& .MuiListItemIcon-root': {
        color: theme.palette.secondary.main,
      },
      '& .MuiTypography-body1': {
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightSemiBold,
      },
    },
  },
  title: {
    color: theme.palette.common.grey400,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.4,
    '& .MuiTypography-body1': {
      color: 'inherit',
      fontSize: 'inherit',
      display: 'flex',
      justifyContent: 'space-between',
    },
    '&.logout': {
      color: theme.palette.common.red400,
    },
  },
  highlightText: {
    color: theme.palette.secondary.main,
  },
  divider: {
    backgroundColor: theme.palette.common.grey20,
  },

  // To-Do: remove before launch
  appVersion: {
    padding: 16,
    margin: 0,
  },
  accordionMenu: {
    boxShadow: 'none',
    borderRadius: '0 !important',
    fontSize: theme.typography.fontSize15,
    color: theme.palette.common.grey400,
  },
  expandedAccordion: {
    fontWeight: theme.typography.fontWeightSemiBold,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.common.purple100,
    borderRadius: '0 !important',
  },
  accordionPanel: {
    backgroundColor: '#fbf3fd',
    padding: '5px 0',
  },
  menuItem: {
    padding: '12px 16px',
    '&.active': {
      fontWeight: theme.typography.fontWeightSemiBold,
    },
  },
});

export default styles;
