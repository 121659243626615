const styles = (theme) => ({
  dialog: {
    minWidth: 320,
    borderRadius: 14,
    '& .MuiDialogContent-root': {
      paddingBottom: 16,
    },
  },
  paragraph: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize13,
    lineHeight: 1.5,
  },
  actionWrapper: {
    alignItems: 'flex-end',
    padding: '16px 24px',
    borderTop: `1px solid ${theme.palette.common.grey20}`,
  },
  cancelBtn: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.fontSize13,
    lineHeight: 1.5,
    padding: 0,
    textTransform: 'capitalize',
    minWidth: 'auto',
  },
});

export default styles;
