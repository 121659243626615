const styles = (theme) => ({
  wrapper: {
    marginBottom: 16,
  },
  title: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.fontSize14,
    lineHeight: 1.5,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 16,
  },
  clockIcon: {
    display: 'inline-flex',
    maxWidth: 18,
    marginRight: 6,
    '& > img': {
      width: '100%',
    },
  },
  divider: {
    backgroundColor: theme.palette.common.grey20,
  },
  btnWrapper: {
    margin: '16px 0px',
    textAlign: 'center',
  },
  allCityBtn: {
    textTransform: 'none',
  },
});

export default styles;
