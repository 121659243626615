'use client';

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePathname } from 'next/navigation';
import addDays from 'date-fns/addDays';
import _get from 'lodash/get';
import _some from 'lodash/some';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import DROPOFF_BRANCH_CATEGORY from '@lumirental/lumi-web-shared/lib/constants/app/DROPOFF_BRANCH_CATEGORY';
import SCREENS_NAME from '@lumirental/lumi-web-shared/lib/constants/app/SCREENS_NAME';
import SEARCH_DEBOUNCE_TIME from '@lumirental/lumi-web-shared/lib/constants/app/SEARCH_DEBOUNCE_TIME';
import TAB_INDEX from '@lumirental/lumi-web-shared/lib/constants/form/TAB_INDEX';
import LANGUAGES from '@lumirental/lumi-web-shared/lib/constants/lang/LANGUAGES';
import getArabicAmPM from '@lumirental/lumi-web-shared/lib/utils/date/getArabicAmPM';
import getLocaleDayMonth from '@lumirental/lumi-web-shared/lib/utils/date/getLocaleDayMonth';
import getNextWorkingDay from '@lumirental/lumi-web-shared/lib/utils/date/getNextWorkingDay';
import { validateDateAndTimeSearch } from '@lumirental/lumi-web-shared/lib/utils/validateSearch';

import {
  DifferentCityRegionDialog,
  DropoffChargesInfo,
} from '@lumirental/lumi-web-components-ui';

import { onTapEvent } from '@/utils/gtm';
import { useUrl } from '@/utils/useUrl';

// import { onTapEvent } from '../../../../utils/gtm';
import {
  DROPOFF_DATE,
  DROPOFF_TIME,
  PICKUP_DATE,
  PICKUP_TIME,
} from '../../constants';
import FreeCarDeliveryCard from '../Cards/FreeCarDelivery';
import DatePickerWrapper from '../Dialogs/DatePickerWrapper';
import FreeCarDeliveryDialog from '../Dialogs/FreeCarDelivery';
import SearchDropOffBranch from '../Dialogs/SearchBranch/SearchDropOffBranch';
import SearchPickUpBranch from '../Dialogs/SearchBranch/SearchPickUpBranch';
import TimePickerWrapper from '../Dialogs/TimePickerWrapper';
import FindBranchTab from '../Tabs/FindBranchTab';

import styles from './FindCarForm.style';

const useStyles = makeStyles()(styles);

const defaultDateAndTimeError = {
  [PICKUP_DATE]: false,
  [PICKUP_TIME]: false,
  [DROPOFF_DATE]: false,
  [DROPOFF_TIME]: false,
};

function FindCarForm({
  searchPageProps,
  branchProps,
  cityProps,
  locationProps,
  detectLocation,
  fetchBranch,
  dropOffCharges,
  moveTip,
  onSearchClick,
}) {
  const { changeParams } = useUrl();

  const pathname = usePathname();
  const { classes } = useStyles();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const pickupDatePickerRef = useRef(null);
  const dropoffDatePickerRef = useRef(null);

  const [openFreeCarDeliveryDialog, setOpenFreeCarDeliveryDialog] =
    useState(false);
  const [openDifferentCityReturnDialog, setOpenDifferentCityReturnDialog] =
    useState(false);
  const [openDifferentCityDialogSuccess, setOpenDifferentCityDialogSuccess] =
    useState(false);
  // adding a local state because of a switch issue
  // switch does not turn on when coming from lisitng page
  const [localSwitchState, setlocalSwitchState] = useState(false);
  const [dateAndTimeError, setDateAndTimeError] = useState(
    defaultDateAndTimeError,
  );

  const { isDropoffChargesLoading, dropoffCharge, dropoffCategoryType } =
    dropOffCharges;
  // read props for  csr
  const {
    // language,
    isPickUpBranchSelected,
    isSwitchOn,
    isDeliveryTabActive,
    setSwitchState,
    pickupBranch,
    dropoffBranch,
    pickupDate,
    dropoffDate,
    pickupTime,
    dropoffTime,
    activeTabIndex,
    isSameDay,
    openPickupTimer,
    openDropoffTimer,
    setDropOffDate,
    setPickUpBranch,
    setDropOffBranch,
    minDaysOfDeliveryDropOff,
    pickUpCount,
    carDeliveryCount,
    setDeliveryParams,
  } = searchPageProps;

  const isArabic = LANGUAGES.AR === language;
  const isCarDelivery = isDeliveryTabActive;

  const formattedPickupDate =
    pickupDate && getLocaleDayMonth(pickupDate, language);

  const formattedDropoffDate =
    dropoffDate && getLocaleDayMonth(dropoffDate, language);

  // Pickup Time
  const pickupTimeInfo =
    pickupTime && isArabic ? getArabicAmPM(pickupTime) : pickupTime;

  // Dropoff Time
  const dropoffTimeInfo =
    dropoffTime && isArabic ? getArabicAmPM(dropoffTime) : dropoffTime;

  const searchBtnDisabled = !pickupBranch || (isSwitchOn && !dropoffBranch);

  const forBookingInfo = t('for_bookings_over_xdays', {
    0: minDaysOfDeliveryDropOff,
  });

  const dropOffChargesInfo = {
    [DROPOFF_BRANCH_CATEGORY.INTRA_CITY]: {
      title: t('modal_title_free_charges'),
      description: t('modal_text_additional_charges_different_intracity'),
      imgUrl: '/images/png/different-city-dropoff.png',
    },
    [DROPOFF_BRANCH_CATEGORY.INTRA_REGION]: {
      title: t('modal_title_additional_charges', {
        price: dropoffCharge,
        0: '',
      }),
      description: t('modal_text_additional_charges_different_city'),
      imgUrl: '/images/png/different-city-dropoff.png',
    },
    [DROPOFF_BRANCH_CATEGORY.INTER_REGION]: {
      title: t('modal_title_additional_charges', {
        price: dropoffCharge,
        0: '',
      }),
      description: t('modal_text_additional_charges_different_region'),
      imgUrl: '/images/png/different-region-dropoff.png',
    },
  };

  // fix for switch issue mentioned above
  useEffect(() => {
    setlocalSwitchState(isSwitchOn);
  }, [isSwitchOn]);

  const handleToggleSwitch = (evt) => {
    const { checked } = evt.target;
    setSwitchState(checked); // update global store
    setlocalSwitchState(checked);
  };

  const handlePickupDateConfirm = () => {
    // update local state
    setDateAndTimeError({
      ...dateAndTimeError,
      [PICKUP_DATE]: false,
    });
  };

  const handleDropoffDateConfirm = () => {
    // update local state
    setDateAndTimeError({
      ...dateAndTimeError,
      [DROPOFF_DATE]: false,
    });
  };

  const handlePickupTimeConfirm = () => {
    // update local state
    setDateAndTimeError({
      ...dateAndTimeError,
      [PICKUP_TIME]: false,
    });
  };

  const handleDropoffTimeConfirm = () => {
    // update local state
    setDateAndTimeError({
      ...dateAndTimeError,
      [DROPOFF_TIME]: false,
    });
  };

  const tabChanged = (tabIndx) => {
    if (tabIndx !== activeTabIndex) {
      let newDropOffDate = null;
      if (tabIndx === TAB_INDEX.FIRST) {
        newDropOffDate = addDays(pickupDate, 1); // add 1 day
      } else {
        newDropOffDate = addDays(pickupDate, minDaysOfDeliveryDropOff + 1); // add no. of days for delivery branch
      }

      const offDays = _get(dropoffBranch, 'offDays', []); // get dropoff off days
      const nextWorkingDay = getNextWorkingDay(newDropOffDate, offDays);

      changeParams({
        pickUpBranch: null,
        dropOffBranch: null,
        dropOffSwitch: null,
        search: '',
        dropOffDate: nextWorkingDay,
        branchTab: tabIndx,
      });
    }
  };

  // const tabChanged = (tabIndx) => {
  //   if (tabIndx !== activeTabIndex) {
  //     setActiveTabIndex(tabIndx);
  //     setPickUpBranch({});
  //     setDropOffBranch({});
  //     setSwitchState(false); // update global store
  //     setlocalSwitchState(false);
  //   }
  // };

  const handleFindMoreCarDelivery = () => {
    const screenName = SCREENS_NAME[pathname];
    onTapEvent(screenName, 'delivery_find_out_more_tapped');
  };

  const handelSearch = () => {
    handleOpenFreeCarDeliveryDialog();
  };

  /**
   * Open free car delivery dialog
   */
  const handleOpenFreeCarDeliveryDialog = () => {
    setOpenFreeCarDeliveryDialog(true);
  };

  /**
   * Close free car delivery dialog
   */
  const handleCloseFreeCarDeliveryDialog = () => {
    setOpenFreeCarDeliveryDialog(false);
    handleSearchClick && handleSearchClick();
  };

  const handleSearchClick = () => {
    const {
      isPickupBranchClosed,
      isDropoffBranchClosed,
      isPickupTimeValid,
      isDropoffTimeValid,
      isMinDurationValid,
      isTPlusLeadTimeValid,
    } = validateDateAndTimeSearch({
      pickupBranch,
      dropoffBranch,
      pickupDate,
      dropoffDate,
      pickupTime,
      dropoffTime,
      isSameDay,
    });

    const dateAndTimeErrObj = {
      [PICKUP_DATE]: isPickupBranchClosed,
      [PICKUP_TIME]: !isPickupTimeValid || !isTPlusLeadTimeValid,
      [DROPOFF_DATE]: isDropoffBranchClosed,
      [DROPOFF_TIME]: !isDropoffTimeValid || !isMinDurationValid,
    };

    setDateAndTimeError(dateAndTimeErrObj);

    const hasError = _some(dateAndTimeErrObj);

    if (hasError) {
      // add fake delay if date/time is invalid
      setTimeout(() => {
        onSearchClick && onSearchClick();
      }, SEARCH_DEBOUNCE_TIME);
    } else {
      onSearchClick && onSearchClick();
    }
  };

  const handleOpenDatePicker = () => {
    if (isPickUpBranchSelected) {
      // open dropoff date picker dialog.
      dropoffDatePickerRef?.current?.click();
    }
  };

  const handleDiffDropCityReturnChange = () => {
    setDropOffBranch(null); // update global store
    setOpenDifferentCityReturnDialog(false);
  };

  const handleDiffDropCityReturnClose = () => {
    setOpenDifferentCityReturnDialog(false);
    setOpenDifferentCityDialogSuccess(true);
  };

  return (
    <>
      <FindBranchTab
        language={language}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={tabChanged}
        pickUpBranchCount={pickUpCount}
        deliveryBranchCount={carDeliveryCount}
        pickupDate={pickupDate}
        dropoffBranch={dropoffBranch}
        moveTip={moveTip}
        minDaysOfDeliveryDropOff={minDaysOfDeliveryDropOff}
        setDropOffDate={setDropOffDate}
        setDeliveryParams={setDeliveryParams}
      />
      <Grid container className={classes.formSection}>
        {isCarDelivery && (
          <FreeCarDeliveryCard
            language={language}
            carDeliveryInfo={forBookingInfo}
            findOutCta={t('find_out_more_web')}
            handleFindMore={handleFindMoreCarDelivery}
          />
        )}
        <Grid item xs={12} className={classes.fieldRow}>
          <SearchPickUpBranch
            branchProps={branchProps}
            searchPageProps={searchPageProps}
            language={language}
            cityProps={cityProps}
            locationProps={locationProps}
            detectLocation={detectLocation}
            fetchBranch={fetchBranch}
            isSwitchOpen={isSwitchOn}
            dropOffCharges={dropOffCharges}
            setOpenDifferentCityReturnDialog={setOpenDifferentCityReturnDialog}
          />
        </Grid>
        {isSwitchOn && (
          <Grid item xs={12} className={classes.fieldRow}>
            <SearchDropOffBranch
              branchProps={branchProps}
              searchPageProps={searchPageProps}
              language={language}
              cityProps={cityProps}
              locationProps={locationProps}
              detectLocation={detectLocation}
              fetchBranch={fetchBranch}
              isSwitchOpen={isSwitchOn}
              dropOffCharges={dropOffCharges}
              openDifferentCityDialogSuccess={openDifferentCityDialogSuccess}
              onBranchDialogHide={() => {
                setOpenDifferentCityDialogSuccess(false);
              }}
              setOpenDifferentCityReturnDialog={(val) => {
                setOpenDifferentCityReturnDialog(val);
                setOpenDifferentCityDialogSuccess(false);
              }}
            />
          </Grid>
        )}
        {!isCarDelivery && (
          <Grid
            item
            xs={12}
            className={(classes.fieldRow, classes.switchWrapper)}
          >
            <div className={classes.switchSection}>
              <Box sx={{ display: 'flex' }}>
                <Typography variant="subtitle1" className={classes.switchLabel}>
                  {t('return_to_a_different_location')}
                </Typography>
                <DropoffChargesInfo />
              </Box>

              <Switch
                checked={localSwitchState}
                onChange={handleToggleSwitch}
                name="checkedA"
                classes={{
                  thumb: classes.switchThumb,
                  track: classes.switchTrack,
                }}
                data-testid="searchSwitch"
              />
            </div>
          </Grid>
        )}
        <Grid item xs={12} className={(classes.fieldRow, classes.dateFieldRow)}>
          <DatePickerWrapper
            id="pickupDate"
            value={formattedPickupDate || ''}
            dataTestId="searchSelectPickUpDate"
            onDateConfirmClick={handlePickupDateConfirm}
            forwardedRef={pickupDatePickerRef}
            isError={dateAndTimeError[PICKUP_DATE]}
            searchPageProps={searchPageProps}
          />
          <TimePickerWrapper
            id="pickupTime"
            value={pickupTimeInfo || ''}
            dataTestId="searchSelectPickUpTime"
            openTimerDialog={openPickupTimer}
            handleOpenDatePicker={handleOpenDatePicker}
            isError={dateAndTimeError[PICKUP_TIME]}
            handleConfirmClick={handlePickupTimeConfirm}
            searchPageProps={searchPageProps}
          />
        </Grid>
        <Grid item xs={12} className={(classes.fieldRow, classes.dateFieldRow)}>
          <DatePickerWrapper
            id="dropoffDate"
            value={formattedDropoffDate || ''}
            dataTestId="searchSelectDropOffDate"
            searchPageProps={searchPageProps}
            onDateConfirmClick={handleDropoffDateConfirm}
            forwardedRef={dropoffDatePickerRef}
            isError={dateAndTimeError[DROPOFF_DATE]}
          />
          <TimePickerWrapper
            id="dropoffTime"
            value={dropoffTimeInfo || ''}
            dataTestId="searchSelectDropOffTime"
            openTimerDialog={openDropoffTimer}
            searchPageProps={searchPageProps}
            isError={dateAndTimeError[DROPOFF_TIME]}
            handleConfirmClick={handleDropoffTimeConfirm}
          />
        </Grid>
        <Grid item xs={12} className={classes.fieldRow}>
          <Button
            disabled={searchBtnDisabled}
            fullWidth
            variant="contained"
            onClick={isCarDelivery ? handelSearch : handleSearchClick}
            data-testid="searchButton"
          >
            {t('search')}
          </Button>
        </Grid>
      </Grid>
      <FreeCarDeliveryDialog
        openDialog={openFreeCarDeliveryDialog}
        handleCloseDialog={handleCloseFreeCarDeliveryDialog}
      />
      <DifferentCityRegionDialog
        isOpen={openDifferentCityReturnDialog}
        onClose={handleDiffDropCityReturnChange}
        onDropoffConfirm={handleDiffDropCityReturnClose}
        isLoading={isDropoffChargesLoading}
        dropOffChargesInfo={dropOffChargesInfo[dropoffCategoryType]}
      />
    </>
  );
}

export default FindCarForm;
