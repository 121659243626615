import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import _get from 'lodash/get';
import _size from 'lodash/size';

import ButtonBase from '@mui/material/ButtonBase';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import LANGUAGES from '@lumirental/lumi-web-shared/lib/constants/lang/LANGUAGES';

import Accordion from '../../Accordion';
import BranchCard from '../../Cards/BranchCard/BranchCard';
import CarDeliveryCard from '../../Cards/CarDelivery';

import styles from './CityList.style';

const useStyles = makeStyles()(styles);

const CityList = ({
  data,
  language,
  getBranchesByCityId,
  handleSelectItem,
  isCarDelivery,
}) => {
  const [expanded, setExpanded] = useState('');
  const [branches, setBranches] = useState([]);

  const { classes } = useStyles();
  const { t } = useTranslation();

  const wrapperClass = isCarDelivery
    ? `${classes.cardWrapper} ${classes.carDeliveryWrapper}`
    : classes.cardWrapper;

  const isArabic = LANGUAGES.AR === language;
  const arrowIconSrc = isArabic
    ? '/images/svg/arrow-prev-icon.svg'
    : '/images/svg/arrow-next-icon.svg';

  const handleHeaderClick = (branches) => {
    setBranches(branches); // update local state
  };

  return (
    <div component="nav" className={classes.listWrapper}>
      <div className={classes.listItem}>
        <Typography className={classes.listTitle}>
          {t('cities_we_are_available_in')}
        </Typography>
      </div>
      <Divider
        classes={{
          root: classes.divider,
        }}
      />
      {data &&
        data.map((city) => {
          const cityName = _get(city, 'name', '');
          const branchesByCityId = getBranchesByCityId(city.id);
          const noOfBranch = _size(branchesByCityId);
          const branchText = `${noOfBranch} ${t('branches', {
            count: noOfBranch,
          })}`;

          const testId = `searchCityCardHeader_${city.id}`;

          const isAccordianOpen = cityName === expanded;

          const arrowClass = `${classes.arrowIcon} ${
            isAccordianOpen ? 'active' : ''
          } ${isArabic ? 'rtl' : ''}`;
          return (
            <React.Fragment key={city.id}>
              <Accordion
                value={cityName}
                expanded={expanded}
                setExpanded={setExpanded}
                headerClickHandler={() => handleHeaderClick(branchesByCityId)}
                accordionHeaderClass={classes.accordionTitleWrapper}
                header={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <>
                    <div className={classes.titleWrapper} data-testid={testId}>
                      <div className={classes.content}>
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          {city.name}
                        </Typography>
                        {isCarDelivery && (
                          <Chip
                            size="small"
                            label={t('free_car_delivery')}
                            className={classes.chip}
                          />
                        )}
                      </div>
                      <div>
                        <Typography
                          variant="body2"
                          className={classes.subTitle}
                        >
                          {branchText}
                        </Typography>
                      </div>
                    </div>
                    <Image
                      height={12}
                      width={12}
                      src={arrowIconSrc}
                      alt="Arrow Icon"
                      className={arrowClass}
                    />
                  </>
                }
              >
                {branches &&
                  branches.map((branch) => {
                    const testId = `searchCityBranchCard_${branch.id}`;
                    const showCard = isCarDelivery ? (
                      <CarDeliveryCard
                        language={language}
                        branch={branch}
                        handleCarDelivery={() =>
                          handleSelectItem(branch, 'city')
                        }
                      />
                    ) : (
                      <>
                        <BranchCard
                          language={language}
                          branch={branch}
                          isfromCityCenter
                        />
                      </>
                    );
                    return (
                      <ButtonBase
                        key={branch.id}
                        className={wrapperClass}
                        data-testid={testId}
                        onClick={() => handleSelectItem(branch, 'city')}
                      >
                        {showCard}
                      </ButtonBase>
                    );
                  })}
              </Accordion>
              <Divider
                classes={{
                  root: classes.divider,
                }}
              />
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default React.memo(CityList, (prevProps, nextProps) => {
  // v.imp - for city list not to render unncecessarily
  return prevProps.data === nextProps.data;
});
