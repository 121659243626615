const styles = (theme) => ({
  dialog: {
    maxWidth: 500,
    borderRadius: 14,
    '& .MuiDialogContent-root': {
      paddingBottom: 12,
    },
  },
  title: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize20,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 12,
  },
  content: {
    padding: 0,
  },
  paragraph: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize14,
    lineHeight: 1.8,
  },
  actionWrapper: {
    alignItems: 'flex-end',
    padding: '16px 24px',
    paddingTop: 12,
  },
  agreeBtn: {
    textTransform: 'none',
  },
});

export default styles;
