import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import addMinutes from 'date-fns/addMinutes';
import format from 'date-fns/format';
import getTime from 'date-fns/getTime';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import Button from '@mui/material/Button';

import { makeStyles } from 'tss-react/mui';

import DEFAULT_BRANCH_TIMING from '@lumirental/lumi-web-sdk/dist/constants/date/DEFAULT_BRANCH_TIMING';
import getKSADate from '@lumirental/lumi-web-sdk/dist/utils/store/getKSADate';
import CLOSED_BRANCH from '@lumirental/lumi-web-shared/lib/constants/app/CLOSED_BRANCH';
import TIME_PICKER_UPDATE_REASON from '@lumirental/lumi-web-shared/lib/constants/app/TIME_PICKER_UPDATE_REASON';
import DATE_TIME_PICKER from '@lumirental/lumi-web-shared/lib/constants/date/DATE_TIME_PICKER';
import LANGUAGES from '@lumirental/lumi-web-shared/lib/constants/lang/LANGUAGES';
import useAB from '@lumirental/lumi-web-shared/lib/hooks/useAB';
import generateAvailableTimeSlots from '@lumirental/lumi-web-shared/lib/utils/date/generateAvailableTimeSlots';
import generateTimeSlots from '@lumirental/lumi-web-shared/lib/utils/date/generateTimeSlots';
import getArabicAmPM from '@lumirental/lumi-web-shared/lib/utils/date/getArabicAmPM';
import timeSelected from '@lumirental/lumi-web-shared/lib/utils/gtm/timeSelected';

import { DROPOFF_TIME, PICKUP_TIME } from '../../constants';
import Picker from '../Picker';

import styles from './TimePicker.style';

const useStyles = makeStyles()(styles);

export default function TimePicker({
  pickupTime,
  dropoffTime,
  pickupDate,
  dropoffDate,
  setPickUpTime,
  setDropOffTime,
  selectedDayTiming,
  isSelectedDayOff,
  timePickerBtnText,
  isPickupTimeDialogOpen,
  isBranchSelected,
  isSameDay,
  pickupTiming,
  dropoffTiming,
  handleConfirmTimePicker,
  leadTimeMinutes,
  checkIfUpdateIsNeeded,
  isTimeNeedUpdate,
  updateTime,
}) {
  const [pickerValue, setPickerValue] = useState({});
  const [time12Hr, setTime12Hr] = useState([]);
  const [branchSlots, setBranchSlots] = useState([]);
  const [isClosedHover, setIsClosedHover] = useState(false);
  const [showIndicators, setShowIndicators] = useState({
    open: false,
    close: false,
  });

  const { classes } = useStyles();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { start24, end24, is24hr } = selectedDayTiming;
  const isEmptyTimeSlots = _isEmpty(time12Hr) || isSelectedDayOff;
  const selectedTime = isPickupTimeDialogOpen ? pickupTime : dropoffTime; // read from store
  const selectedDate = isPickupTimeDialogOpen ? pickupDate : dropoffDate; // read from store
  const branchTiming = isPickupTimeDialogOpen ? pickupTiming : dropoffTiming;
  const formattedSelectedDate = format(selectedDate, 'MMMM dd, yyy'); // eg. 'April 12, 2021'
  const { optionGroups, valueGroups } = pickerValue;

  const isSearchUpdateEnabled = useAB('auto_search_update_tag');

  const isArabic = !!(language === LANGUAGES.AR);
  const pickupTimeInfo = isArabic ? getArabicAmPM(pickupTime) : pickupTime;

  const getWithLeadTime = (branchOpenTime, formattedSelectedDate) => {
    // convert to ksa time zone
    const ksaDate = getKSADate(new Date());
    let openTime;

    // from now the next slot available is T + Lead time
    // for both pickup time and dropoff time picker
    // ** there can be scenario that branch opening time
    // is more than t+leadTime. for eg. Friday branch opens at 4:29pm and the leadTime = 4hrs
    // if you open app at 10AM in morning - T+LeadTime is 2PM which is less than opening time
    // so handle that situation
    // T + LeadTime
    console.log('leadTimeMinutes ::', leadTimeMinutes, 'min');
    const ksaLeadTime = addMinutes(ksaDate, leadTimeMinutes); // returns a date object
    // If no leadTime then dropOff is selected
    if (getTime(branchOpenTime) > getTime(ksaLeadTime) || !leadTimeMinutes) {
      openTime = branchOpenTime;
    } else {
      openTime = ksaLeadTime;
    }
    // return openTime;
    // if pickup and drop dates are same
    // then only for dropoff time picker
    if (isSameDay && !isPickupTimeDialogOpen) {
      // 30 mins gap between pick and drop time
      // find pick up time and add 30 mins to it
      const formattedPickUpTime = new Date(
        `${formattedSelectedDate} ${pickupTime}`,
      ); // using today as fake date
      openTime = addMinutes(formattedPickUpTime, DATE_TIME_PICKER.MINS_APART); // returns a date object
    }
    return openTime;
  };
  // generate available open hours after adding lead time and 30min gap between pickup and dropoff
  const generateAvailableTime = (
    branchOpenTime,
    branchCloseTime,
    formattedSelectedDate,
  ) => {
    // add lead time and 30 min between pickup and dropoff
    const formattedOpenTime = getWithLeadTime(
      branchOpenTime,
      formattedSelectedDate,
    );
    const branchTimeSlots = generateTimeSlots(branchOpenTime, branchCloseTime);
    const availableTimeSlots = generateAvailableTimeSlots(
      branchTimeSlots,
      formattedSelectedDate,
      formattedOpenTime,
    );

    return {
      branchTimeSlots,
      availableTimeSlots,
    };
  };

  const generateTime = () => {
    let branchOpenTime = new Date(`${formattedSelectedDate} ${start24}`);
    let branchCloseTime = new Date(`${formattedSelectedDate} ${end24}`);
    let availableTime;

    const selectedDayTimeDay = branchTiming.filter((el) => {
      return el.daysLabels
        ? el.daysLabels.includes(selectedDayTiming?.day?.toLowerCase())
        : el.dayLabel.toLowerCase() === selectedDayTiming?.day?.toLowerCase();
    });

    const timeRanges = _get(selectedDayTimeDay[0], 'timeRanges', []); // get timeRanges

    // if its a 24 hr branch
    if (is24hr || !timeRanges.length) {
      if (is24hr) {
        const formattedOpenTime = new Date(
          `${formattedSelectedDate} ${DEFAULT_BRANCH_TIMING.start24}`,
        );
        const formattedCloseTime = new Date(
          `${formattedSelectedDate} ${DEFAULT_BRANCH_TIMING.end24}`,
        );
        branchOpenTime = formattedOpenTime;
        branchCloseTime = formattedCloseTime;
      }

      // Prepare branchTimeSlots And availableTimeSlots
      availableTime = generateAvailableTime(
        branchOpenTime,
        branchCloseTime,
        formattedSelectedDate,
      );
    } else {
      const timeSlots = timeRanges.map((time) => {
        const startEndTime = time.split('-');
        const selectedDayTimeRangesStart = startEndTime[0];
        const selectedDayTimeRangesEnd = startEndTime[1];
        const branchRangeTimeStart = new Date(
          `${formattedSelectedDate} ${selectedDayTimeRangesStart}`,
        );
        const branchRangeTimeEnd = new Date(
          `${formattedSelectedDate} ${selectedDayTimeRangesEnd}`,
        );

        const availableTimeRange = generateAvailableTime(
          branchRangeTimeStart,
          branchRangeTimeEnd,
          formattedSelectedDate,
        );

        return {
          branchRangeTimeStart,
          branchRangeTimeEnd,
          ...availableTimeRange,
        };
      });

      // Add closed text if break is available
      const availableTimeFromTimeSlots = timeSlots.reduce(
        (acc, el, idx, arr) => {
          // Do not add Closed text if its is the first range OR if the prev range does not have an available time slot
          if (idx === 0 || !arr[idx - 1]?.availableTimeSlots.length) {
            acc.availableTimeSlots.push(...el.availableTimeSlots);
            acc.branchTimeSlots.push(...el.branchTimeSlots);
            return acc;
          }

          el.availableTimeSlots.length
            ? acc.availableTimeSlots.push(
                `${CLOSED_BRANCH}_${idx}`,
                ...el.availableTimeSlots,
              ) // Add closed text between time ranges
            : acc.availableTimeSlots.push(...el.availableTimeSlots);
          acc.branchTimeSlots.push(...el.branchTimeSlots);
          return acc;
        },
        { branchTimeSlots: [], availableTimeSlots: [] },
      );

      // Prepare branchTimeSlots And availableTimeSlots
      availableTime = availableTimeFromTimeSlots;
    }

    const { branchTimeSlots, availableTimeSlots } = availableTime;
    setBranchSlots(branchTimeSlots); // update local state
    setTime12Hr(availableTimeSlots); // update local state

    return availableTime;
  };

  // on mount
  useEffect(() => {
    // console.log('*** Available Slots: ', availableTimeSlots);
    const { availableTimeSlots, branchTimeSlots } = generateTime();

    // find out the default pick or drop time
    // when the time picker opens, it has to show the value that is currently selected
    // check if the selected time from text field is inside the time slots
    const check = availableTimeSlots.indexOf(selectedTime);
    const defaultTimeSlot = check === -1 ? availableTimeSlots[0] : selectedTime;

    // check if opening or closing time when time picker loads
    // show the indicator accordingly only when a branch is selected
    if (isBranchSelected) {
      if (
        selectedTime === branchTimeSlots[0] ||
        defaultTimeSlot === branchTimeSlots[0]
      ) {
        setShowIndicators({
          ...showIndicators,
          open: true,
        });
      } else if (
        selectedTime === branchTimeSlots[branchTimeSlots.length - 1] ||
        defaultTimeSlot === branchTimeSlots[branchTimeSlots.length - 1]
      ) {
        setShowIndicators({
          ...showIndicators,
          close: true,
        });
      }
    }

    // update picker with opening and closing times
    // set default time to te selected time or first available slot
    !_isEmpty(availableTimeSlots) &&
      setPickerValue({
        valueGroups: {
          time: defaultTimeSlot,
        },
        optionGroups: {
          time: availableTimeSlots,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSearchUpdateEnabled) {
      if (checkIfUpdateIsNeeded) {
        const { availableTimeSlots } = generateTime();
        // get english value always
        const formattedTime = selectedTime;

        // if time is available no need to update anything, just close the time picker dialog
        if (availableTimeSlots.includes(formattedTime)) {
          handleConfirmTimePicker();
          isTimeNeedUpdate(
            TIME_PICKER_UPDATE_REASON.TIME_UPDATED,
            isPickupTimeDialogOpen,
            false,
          );
        } else {
          const isTimeChangeNeeded =
            !!formattedTime &&
            !!availableTimeSlots.length &&
            !availableTimeSlots.includes(formattedTime);

          isTimeNeedUpdate(
            TIME_PICKER_UPDATE_REASON.TIME_UPDATED,
            isPickupTimeDialogOpen,
            isTimeChangeNeeded,
          );
        }
      } else {
        isTimeNeedUpdate(
          TIME_PICKER_UPDATE_REASON.TIME_UPDATED,
          isPickupTimeDialogOpen,
          false,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkIfUpdateIsNeeded]);

  useEffect(() => {
    if (selectedTime && updateTime) {
      const { availableTimeSlots } = generateTime();
      const { closestTime } = findClosestAvailableTime(
        availableTimeSlots,
        selectedTime,
      );

      updateTimePicker(closestTime, availableTimeSlots);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTime]);

  const findClosestAvailableTime = (availableTimeSlots, formattedTime) => {
    const timeDiff = [];
    const today = format(new Date(), 'MMMM dd, yyy');

    // convert 12h format to 24h format
    // e.g '7:30 AM' --> 'Wed Aug 24 2022 07:30:00 GMT+0300 (Eastern European Summer Time)'
    // or '7:30 PM' --> 'Wed Aug 24 2022 19:00:00 GMT+0300 (Eastern European Summer Time)'
    const selectedTime4 = new Date(`${today} ${formattedTime}`);
    const epochSelectedDate = getTime(selectedTime4);

    availableTimeSlots.forEach((time) => {
      // convert 12h format to 24h format
      // e.g '7:30 AM' --> 'Wed Aug 24 2022 07:30:00 GMT+0300 (Eastern European Summer Time)'
      // or '7:30 PM' --> 'Wed Aug 24 2022 19:00:00 GMT+0300 (Eastern European Summer Time)'
      const pickerTime24 = new Date(`${today} ${time}`);
      const epochPicker24 = getTime(pickerTime24);

      // get time difference between selected and time
      const epochDiff = Math.abs(epochPicker24 - epochSelectedDate);
      // convert epoch to hours e.g '7200000' to '2'
      const hourDiff = epochDiff / 60 / 60 / 1000;

      // if the time within the break 'closed' the epoch will be NaN
      if (!Number.isNaN(epochDiff)) {
        timeDiff.push({ time, hourDiff, epochDiff });
      }
    });

    timeDiff.sort((a, b) => a.epochDiff - b.epochDiff);
    const closestTime = timeDiff[0].time;
    console.log('closestTime ::', closestTime);

    return { timeDiff, closestTime };
  };

  const updateTimePicker = (time, availableTimeSlots) => {
    // update picker
    setPickerValue({
      valueGroups: {
        time,
      },
      optionGroups: {
        time: availableTimeSlots,
      },
    });
    if (isPickupTimeDialogOpen) {
      setPickUpTime(time); // update global store
    } else {
      setDropOffTime(time); // update global store
    }

    // invoke callback
    handleConfirmTimePicker();
  };

  // handle change
  const handlePickerChange = (name, value) => {
    // console.log('*** handlePickerChange: ', name, value, showIndicators);

    if (value && value === branchSlots[0] && isBranchSelected) {
      // show opening indicator
      setShowIndicators({
        open: true,
        close: false,
      });
    } else if (
      value &&
      value === branchSlots[branchSlots.length - 1] &&
      isBranchSelected
    ) {
      // show closing indicator
      setShowIndicators({
        open: false,
        close: true,
      });
    } else {
      // hide both
      setShowIndicators({
        open: false,
        close: false,
      });
    }

    const { valueGroups, optionGroups } = pickerValue;
    !isEmptyTimeSlots &&
      setPickerValue({
        valueGroups: {
          ...valueGroups,
          [name]: value,
        },
        optionGroups: {
          ...optionGroups,
        },
      });
  };

  // handle button click
  const handleConfirmClick = () => {
    const { valueGroups } = pickerValue; // read from local state
    const selectedTime = valueGroups.time;

    if (isPickupTimeDialogOpen) {
      setPickUpTime(selectedTime); // update global store

      // send GTM event when pickup time is selected
      timeSelected(PICKUP_TIME, selectedTime);
    } else {
      setDropOffTime(selectedTime); // update global store

      // send GTM event when dropoff time is selected
      timeSelected(DROPOFF_TIME, selectedTime);
    }

    // invoke callback
    handleConfirmTimePicker();
  };

  const onCloseHover = (value) => {
    setIsClosedHover(value);
  };

  const pickerGroup = (
    <>
      {isSameDay && !isPickupTimeDialogOpen && (
        <span
          className={classes.pickTimeInfo}
          data-testid="searchTimePickerPickupTime"
        >
          <span className={classes.pickTimeLabel}>{t('pick_up_time')}</span>
          <span className={classes.pickTime}>{pickupTimeInfo}</span>
        </span>
      )}

      {showIndicators.open && !is24hr && (
        <span
          className={classes.timeLabel}
          data-testid="searchTimePickerOpeningTime"
        >
          {t('opening_time')}
        </span>
      )}
      <Picker
        language={language}
        optionGroups={optionGroups}
        closedText={t('branch_close_between_web')}
        valueGroups={valueGroups}
        onChange={handlePickerChange}
        onCloseHover={onCloseHover}
      />
      {showIndicators.close && !is24hr && (
        <span
          className={classes.timeLabel}
          data-testid="searchTimePickerClosingTime"
        >
          {t('closing_time')}
        </span>
      )}
    </>
  );

  return (
    <>
      {!isEmptyTimeSlots && pickerGroup}
      {isEmptyTimeSlots && (
        <p className={classes.noSlotsMsg}>{t('no_time_slots')}</p>
      )}
      <div className={classes.actionWrapper}>
        <Button
          disabled={isEmptyTimeSlots || isClosedHover}
          fullWidth
          variant="contained"
          onClick={handleConfirmClick}
          data-testid="searchTimePickerConfirmBtn"
        >
          {timePickerBtnText}
        </Button>
      </div>
    </>
  );
}
