export enum LocationType {
  PICKUP = 'pickup',
  DROPOFF = 'dropoff',
}

export type LocationProps = {
  isLocationLoading: boolean;
  isLocationSuccess: boolean;
  lat?: number | null; // Optional type for lat and long
  long?: number | null;
  clearLocationProps: () => void;
  setLocationState: any;
};

export type LocationsProps = {
  [LocationType.PICKUP]: LocationProps;
  [LocationType.DROPOFF]: LocationProps;
};
