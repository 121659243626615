const styles = (theme) => ({
  tabWrapper: {
    position: 'relative',
    display: 'flex',
    '.dark &': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
  },
  tabItem: {
    textAlign: 'center',
    '& > button': {
      width: '100%',
    },
  },
  tabContent: {
    fontFamily: 'inherit',
    padding: '10px 16px',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    '&.active': {
      background: theme.palette.common.green400,
    },
    '.dark &.active': {
      background: theme.palette.primary.main,
    },
  },
  tabTitle: {
    ...theme.typography.subtitle1,
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize15,
    fontWeight: theme.typography.fontWeightSemiBold,
    lineHeight: 1.1,
    '.dark  &': {
      color: theme.palette.common.white,
    },
    '.dark .active > &': {
      color: theme.palette.common.blue400,
    },
    '.active > &': {
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightSemiBold,
    },
  },
  tabSubtitle: {
    ...theme.typography.subtitle2,
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize12,
    lineHeight: '1.5',
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: 2,
    marginLeft: 8,
    '.dark  &': {
      color: theme.palette.common.white,
    },
    '.dark .active > &': {
      color: theme.palette.common.blue400,
    },
    '.active > &': {
      color: theme.palette.common.white,
    },
  },
  closeBtnWrapper: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  closeBtn: {
    color: theme.palette.common.white,
  },
});

export default styles;
