const styles = (theme) => ({
  contentWrapper: {
    padding: '8px 0px',
  },
  info: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize14,
    lineHeight: 1.7,
    marginBottom: 16,
  },
  subTitle: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize14,
    lineHeight: 1.4,
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: 8,
  },
  list: {
    margin: 0,
    padding: 0,
    listStyleType: 'disc',
    listStylePosition: 'inside',
    paddingLeft: 8,
  },
  listItem: {
    padding: 0,
    display: 'list-item',
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize12,
    lineHeight: 1.5,
  },
});

export default styles;
