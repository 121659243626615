import { shakeHorizontal } from '../../../animations';

const styles = (theme) => ({
  inputField: {
    padding: '16px 14px',
    fontSize: 15,
    lineHeight: 1.1,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightSemiBold,
    '&::placeholder': {
      color: theme.palette.common.grey300,
      fontWeight: theme.typography.fontWeightRegular,
    },
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  inputBorder: {
    borderColor: theme.palette.common.grey20,
  },
  dateField: {
    flex: '1 1 auto',
    maxWidth: '65%',
  },
  scrollPaper: {
    alignItems: 'center',
  },
  paper: {
    borderRadius: 12,
  },
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    padding: 0,
    '&:first-of-type': {
      paddingTop: 0,
    },
  },
  contentWrapper: {
    padding: '8px 12px',
  },
  header: {
    backgroundColor: theme.palette.common.grey20,
    padding: 24,
  },
  yearText: {
    color: theme.palette.text.primary,
    fontSize: 15,
    lineHeight: 1.3,
    marginBottom: 8,
  },
  dateText: {
    color: theme.palette.text.primary,
    fontSize: 31,
    lineHeight: 1.2,
    fontWeight: theme.typography.fontWeightSemiBold,
    whiteSpace: 'nowrap',
  },
  actionWrapper: {
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 12,
    paddingTop: 0,
    backgroundColor: theme.palette.common.white,
  },
  snackBarRoot: {
    opacity: 0.8,
  },
  snackBarContent: {
    backgroundColor: theme.palette.text.headingText,
    justifyContent: 'center',
    fontSize: theme.typography.fontSize13,
    fontWeight: theme.typography.fontWeightSemiBold,
  },

  shake: {
    animation: `${shakeHorizontal} 0.3s 2 ${theme.transitions.easing.easeInOut}`,
  },
});

export default styles;
