const styles = (theme) => ({
  dialog: {
    minWidth: 320,
    borderRadius: 14,
    '& .MuiDialogContent-root': {
      paddingBottom: 16,
    },
  },
  title: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize18,
    lineHeight: 1.4,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 8,
  },
  paragraph: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize13,
    lineHeight: 1.5,
    wordBreak: 'break-word',
  },
  actionWrapper: {
    alignItems: 'flex-end',
    borderTop: `1px solid ${theme.palette.common.grey20}`,
    padding: '8px 12px',
  },
  secondaryBtn: {
    color: theme.palette.text.placeholder,
    textTransform: 'capitalize',
  },
  primaryBtn: {
    color: theme.palette.secondary.main,
    textTransform: 'capitalize',
    marginLeft: 8,
  },
});

export default styles;
