import React from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import BRANCH_TYPE from '@lumirental/lumi-web-shared/lib/constants/app/BRANCH_TYPE';
import LANGUAGES from '@lumirental/lumi-web-shared/lib/constants/lang/LANGUAGES';
import checkLength from '@lumirental/lumi-web-shared/lib/utils/checkLength';
import getArabicDayWithLabel from '@lumirental/lumi-web-shared/lib/utils/date/getArabicDayWithLabel';
import getCapitalizeDayLabel from '@lumirental/lumi-web-shared/lib/utils/date/getCapitalizeDayLabel';
import getTimeWithArabicLabel from '@lumirental/lumi-web-shared/lib/utils/date/getTimeWithArabicLabel';

import styles from './BranchCard.style';

const useStyles = makeStyles()(styles);

export default function BranchCard({
  language,
  branch,
  searchValue,
  isfromCityCenter,
}) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const isArabic = LANGUAGES.AR === language;
  const branchDistance = branch.distance;

  const branchType = _get(branch, 'type', '');

  const branchIcon =
    branchType === BRANCH_TYPE.AIRPORT ? (
      /* eslint-disable react/jsx-indent */
      <Image
        height={20}
        width={20}
        className={classes.branchIcon}
        src="/images/svg/airport-icon.svg"
        alt="Airport-icon"
      />
    ) : (
      <Image
        height={20}
        width={20}
        className={classes.branchIcon}
        src="/images/svg/location-icon.svg"
        alt="location-icon"
      />
    );

  // to-do: should be a better way to validate this
  // branch.distanceUnit
  const branchDistanceText =
    checkLength(searchValue) || isfromCityCenter
      ? `${branchDistance} ${t('km_away_from_city_centre', {
          count: branchDistance,
        })}`
      : `${branchDistance} ${t('km_away', { count: branchDistance })}`;
  return (
    <Grid item className={classes.contentWrapper}>
      {branchIcon}
      <div className={classes.infoWrapper}>
        <Typography variant="h5" className={classes.subTitle}>
          {branch.name}
        </Typography>
        <Typography className={classes.distanceText}>
          {branchDistanceText}
        </Typography>
        {branch.timingSummary.map((item) => {
          const timeLabel = _get(item, 'timeLabel', '');
          const dayLabel = _get(item, 'dayLabel', '');
          const formattedTimeClosed = isArabic
            ? getTimeWithArabicLabel(timeLabel)
            : timeLabel;
          const timeSlots = item?.timeRanges?.length
            ? item.timeRanges.map((timeRange) => {
                const formattedTime = isArabic
                  ? getTimeWithArabicLabel(timeRange)
                  : timeRange;
                return formattedTime;
              })
            : [formattedTimeClosed]; // return time slot as array e.g ['Closed']
          const formattedDay = isArabic
            ? getArabicDayWithLabel(dayLabel)
            : dayLabel;
          const capitalDay =
            formattedDay && getCapitalizeDayLabel(formattedDay);
          const formattedDayInfo =
            capitalDay && capitalDay.replace(' - ', ` ${t('to')} `);

          return (
            <React.Fragment key={item.dayLabel}>
              <Typography className={classes.timingSummary}>
                <span>{formattedDayInfo}</span>
                <span className={classes.timingGroup}>
                  {timeSlots.map((slot) => {
                    return <span>{slot}</span>;
                  })}
                </span>
              </Typography>
            </React.Fragment>
          );
        })}
      </div>
    </Grid>
  );
}

BranchCard.propTypes = {
  branch: PropTypes.object.isRequired,
  searchValue: PropTypes.string,
};
