const styles = (theme) => ({
  listWrapper: {
    backgroundColor: theme.palette.common.white,
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 16,
  },
  listTitle: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize20,
    lineHeight: 1.1,
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  titleWrapper: {
    padding: 0,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize14,
    lineHeight: 1.4,
    fontWeight: theme.typography.fontWeightSemiBold,
    textAlign: 'left',
    '@media(min-width: 768px)': {
      fontSize: theme.typography.fontSize18,
      fontWeight: '400',
    },
  },
  subTitle: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize12,
    lineHeight: 1.6,
    textAlign: 'left',
    '@media(min-width: 768px)': {
      fontSize: theme.typography.fontSize14,
    },
  },
  accordionTitleWrapper: {
    padding: 16,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: theme.palette.common.blue50,
    },
  },
  chip: {
    backgroundColor: theme.palette.common.blue300,
    fontWeight: theme.typography.fontWeightBold,
    padding: 4,
    marginLeft: 8,
    '& > .MuiChip-label': {
      color: theme.palette.common.white,
      fontSize: theme.typography.fontSize12,
      lineHeight: 1.2,
    },
  },
  divider: {
    backgroundColor: theme.palette.common.grey20,
  },
  cardWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    textAlign: 'left',
    fontFamily: 'inherit',
    borderRadius: 8,
    maxWidth: '348px !important',
    '@media(max-width: 600px)': {
      maxWidth: '100% !important',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.blue50,
    },
  },
  carDeliveryWrapper: {
    maxWidth: '350px !important',
  },
  arrowIcon: {
    width: 16,
    height: 16,
    '&.active': {
      transform: 'rotate(90deg)',
      '&.rtl': {
        transform: 'rotate(-90deg)',
      },
    },
  },
});

export default styles;
