const styles = (theme) => ({
  header: {
    padding: '20px 15px',
    backgroundColor: theme.palette.common.white,
  },
  logoWrapper: {
    marginBottom: 0,
  },
  logo: {
    display: 'inline-block',
    cursor: 'pointer',
  },
  list: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  listItem: {
    padding: '8px 12px',
    display: 'inline-flex',
    width: 'auto',
    '& a': {
      textDecoration: 'none',
    },
  },
  itemLink: {
    color: theme.palette.common.grey300,
    fontSize: theme.typography.fontSize13,
    lineHeight: '1.5',
    cursor: 'pointer',
    textTransform: 'capitalize',
    textDecoration: 'none',
    '&.active': {
      color: theme.palette.common.blue400,
      fontWeight: theme.typography.fontWeightSemiBold,
    },
  },
  nestedButton: {
    color: theme.palette.common.grey300,
    fontSize: theme.typography.fontSize13,
    lineHeight: '1.5',
    cursor: 'pointer',
    textTransform: 'capitalize',
    textDecoration: 'none',
    fontWeight: '500',
    '&.active': {
      color: theme.palette.common.blue400,
      fontWeight: theme.typography.fontWeightSemiBold,
    },
  },
  signInLink: {
    color: theme.palette.common.purple400,
  },
  menu: {
    width: 170,
    marginTop: 36,
    borderRadius: 0,
    boxShadow: '0px 8px 10px 2px rgba(0, 0, 0, 0.05)',
  },
  dropdownList: {
    padding: 0,
  },
  dropdownItem: {
    padding: 0,
    '&.active': {
      backgroundColor: theme.palette.common.blue50,
    },
  },
  imgIcon: {
    marginRight: 10,
    maxHeight: 20,
  },
  dropdonwItemText: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '15px 18px',
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize13,
    lineHeight: '1.3',
    cursor: 'pointer',
    textTransform: 'capitalize',
    textDecoration: 'none',
    '.active &': {
      fontWeight: theme.typography.fontWeightBold,
    },
    'a:has(> &) ': {
      textDecoration: 'none',
    },
  },
});

export default styles;
