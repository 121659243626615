import React from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import CAR_DELIVERY_DURATION from '@lumirental/lumi-web-shared/lib/constants/date/CAR_DELIVERY_DURATION';
import useAB from '@lumirental/lumi-web-shared/lib/hooks/useAB';

import styles from './CarDeliveryInformation.style';

const useStyles = makeStyles()(styles);

export default function CarDeliveryInformation({ carDeliveryBranchList }) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const isMinimumDeliveryFeatureFlag = useAB('minimum_car_delivery_tag');

  const saveInfo = t('to_save_the_cost_xdays', {
    0: isMinimumDeliveryFeatureFlag
      ? CAR_DELIVERY_DURATION.DAYS_15
      : CAR_DELIVERY_DURATION.DAYS_30,
  });

  return (
    <div className={classes.wrapper}>
      <Typography variant="body1" className={classes.info}>
        {saveInfo}
      </Typography>
      <Typography variant="h5" className={classes.subTitle}>
        {t('arranging_delivery')}
      </Typography>
      <Typography variant="body1" className={classes.info}>
        {t('we_will_get_in_touch')}
      </Typography>
      <Typography variant="h5" className={classes.subTitle}>
        {t('car_delivery_terms')}
      </Typography>
      <Typography variant="body1" className={classes.info}>
        {t('you_must_be_located')}
      </Typography>

      <Typography variant="h5" className={classes.subTitle}>
        {t('cities_car_delivery_is_currently_available_in')}
      </Typography>

      <List className={classes.list}>
        {carDeliveryBranchList &&
          carDeliveryBranchList.map((branch) => {
            const cityId = _get(branch, 'id', 0);
            const cityName = _get(branch, 'cityName', '');
            return (
              <ListItem key={cityId} className={classes.listItem}>
                {cityName}
              </ListItem>
            );
          })}
      </List>
    </div>
  );
}
