const styles = (theme) => ({
  actionWrapper: {
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
    marginTop: 16,
  },
  timeLabel: {
    position: 'absolute',
    left: 0,
    top: 82,
    zIndex: theme.zIndex.zIndex10,
    color: theme.palette.text.placeholder,
    fontSize: theme.typography.fontSize12,
    lineHeight: 1.2,
  },
  noSlotsMsg: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.fontSize16,
    textAlign: 'center',
    padding: '8px 0',
    marginBottom: 50,
  },
  pickTimeInfo: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
  },
  pickTimeLabel: {
    position: 'absolute',
    left: 0,
    color: theme.palette.text.placeholder,
    fontSize: theme.typography.fontSize12,
    lineHeight: 1.2,
  },
  pickTime: {
    display: 'inline-flex',
    justifyContent: 'center',
    width: '100%',
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.4,
  },
});

export default styles;
