const styles = (theme) => ({
  subTitle: {
    fontSize: theme.typography.fontSize14,
    lineHeight: 1.5,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 16,
  },
  divider: {
    backgroundColor: theme.palette.common.grey20,
    marginBottom: 8,
  },
  listWrapper: {
    backgroundColor: theme.palette.common.white,
  },
  listItem: {
    padding: 0,
    marginBottom: 16,
    backgroundColor: theme.palette.common.white,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  noData: {
    padding: 8,
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize16,
    marginBottom: 32,
  },
});

export default styles;
