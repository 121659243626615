const styles = (theme) => ({
  mainWrapper: {
    position: 'relative',
    backgroundColor: '#204c56',
  },
  bgWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '& img': {
      objectFit: 'cover',
    },
  },
  wrapperInner: {
    width: '100%',
    padding: '40px 0px',
  },
  container: {
    position: 'relative',
    zIndex: theme.zIndex.zIndex100,
  },
  titleWrapper: {
    width: '100%',
    marginBottom: 24,
  },
  title: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize38,
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightBold,
  },
  subTitle: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightSemiBold,
  },
});

export default styles;
