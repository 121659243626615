import { shakeHorizontal } from '../../../animations';

const styles = (theme) => ({
  scrollPaper: {
    alignItems: 'center',
  },
  paper: {
    borderRadius: 12,
  },
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    padding: 0,
    '&:first-of-type': {
      paddingTop: 0,
    },
  },
  contentWrapper: {
    padding: '8px 12px',
  },
  timePicker: {
    paddingTop: 34,
    paddingBottom: 4,
    position: 'relative',
  },
  inputField: {
    padding: '16px 14px',
    fontSize: 15,
    lineHeight: 1.1,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightSemiBold,
    '&::placeholder': {
      color: theme.palette.common.grey300,
      fontWeight: theme.typography.fontWeightRegular,
    },
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  inputLabel: {
    color: theme.palette.common.grey300,
  },
  inputBorder: {
    borderColor: theme.palette.common.grey20,
  },
  timeField: {
    flex: '1 1 auto',
    maxWidth: '35%',
    marginLeft: 16,
  },
  shake: {
    animation: `${shakeHorizontal} 0.3s 2 ${theme.transitions.easing.easeInOut}`,
  },
});

export default styles;
