import React from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import _isEmpty from 'lodash/isEmpty';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import MobileOnly from '@/components/Responsive/MobileOnly';

import RecentSearchList from '../Lists/RecentSearch';

import styles from './RecentSearch.style';

const useStyles = makeStyles()(styles);

export default function RecentSearch({
  language,
  branches,
  handleSelectItem,
  searchValue,
  handleViewAllCity,
}) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  // comment for publish package.
  const hasNoRecentSearch = _isEmpty(branches);
  const recentTitle = !hasNoRecentSearch
    ? t('Select_from_recent_searches')
    : t('no_recen_searches');

  const recentTitleInfo = (
    <Typography className={classes.title}>
      <span className={classes.clockIcon}>
        <Image
          height={24}
          width={24}
          src="/images/svg/clock-counter.svg"
          alt="Clock Counter"
        />
      </span>
      {recentTitle}
    </Typography>
  );

  if (hasNoRecentSearch) {
    return recentTitleInfo;
  }

  return (
    <div className={classes.wrapper}>
      <MobileOnly>
        {recentTitleInfo}
        <Divider
          classes={{
            root: classes.divider,
          }}
        />
      </MobileOnly>

      {!hasNoRecentSearch && (
        <RecentSearchList
          branches={branches}
          handleSelectItem={handleSelectItem}
        />
      )}
      <MobileOnly>
        <div className={classes.btnWrapper}>
          <Button
            variant="text"
            data-testid="searchViewAllCity"
            className={classes.allCityBtn}
            onClick={handleViewAllCity}
          >
            {t('View_all_cities')}
          </Button>
        </div>
      </MobileOnly>

      <Divider
        classes={{
          root: classes.divider,
        }}
      />
    </div>
  );
}
