import React from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/legacy/image';
import Link from 'next/link';
import _get from 'lodash/get';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import BRANCH_TYPE from '@lumirental/lumi-web-shared/lib/constants/app/BRANCH_TYPE';
import getArabicDayWithLabel from '@lumirental/lumi-web-shared/lib/utils/date/getArabicDayWithLabel';
import getCapitalizeDayLabel from '@lumirental/lumi-web-shared/lib/utils/date/getCapitalizeDayLabel';
import getTimeWithArabicLabel from '@lumirental/lumi-web-shared/lib/utils/date/getTimeWithArabicLabel';
import goToMapURL from '@lumirental/lumi-web-shared/lib/utils/goToMapURL';

import { branchDirectionsClicked } from '@/utils/gtm';
import { getLocaleRoute } from '@/utils/lang';

import styles from './BranchCard.style';

const useStyles = makeStyles()(styles);

export default function SearchWidgetBranchCard({
  branchRoute = null,
  branch,
  handleBranchClick,
  screenName,
}) {
  const { classes } = useStyles();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const isArabic = language === 'ar';
  const branchName = _get(branch, 'name', '');
  const branchSlug = _get(branch, 'slug', '');
  const cityName = _get(branch, 'cityName', '');
  const directions = _get(branch, 'directions', '');
  const phoneNumber = _get(branch, 'phoneNumber', '');
  const timingSummary = _get(branch, 'timingSummary', []);

  const hasDirections = _get(directions, 'length', 0) !== 0;

  const branchInfo = `${branchName}, ${cityName}`;
  const branchPath = branchRoute && `${branchRoute}/${branchSlug}`;

  const ButtonControl = branchPath ? Link : Button;
  const branchType = _get(branch, 'type', '');

  const branchIcon =
    branchType === BRANCH_TYPE.AIRPORT ? (
      /* eslint-disable react/jsx-indent */
      <Image
        className={classes.branchIcon}
        src="/images/svg/airport-icon.svg"
        alt="Airport-icon"
        width={14}
        height={17}
      />
    ) : (
      <Image
        className={classes.branchIcon}
        src="/images/svg/location-icon.svg"
        alt="Location-icon"
        width={14}
        height={17}
      />
    );

  // go to branch location
  const goToBranchLocation = (evt, branch) => {
    evt.stopPropagation();
    evt.preventDefault();

    const directionsURL = _get(branch, 'directions', '');
    if (directionsURL.length > 0) {
      // go to map Location.
      goToMapURL(directionsURL);
    }

    // send GTM event
    branchDirectionsClicked(screenName, branchName);
  };

  return (
    <ButtonControl
      alt={branchName}
      title={branchName}
      href={branchPath ? getLocaleRoute(language, branchPath) : undefined}
      onClick={(event) => handleBranchClick && handleBranchClick(event, branch)}
      data-testid={`searchWidgetBranchCard_${branchName}`}
      style={{
        display: 'block',
        width: '100%',
        borderBottom: `1px solid #f1f1f1`,
        textDecoration: 'none',
        height: '100%',
      }}
    >
      <Typography component="div" className={classes.cardWrapper}>
        <Card className={classes.card}>
          <div className={classes.cardHeader}>
            <div className={classes.labelWrapper}>
              <div className={classes.mapIcon}>{branchIcon}</div>
              <Typography className={classes.label}>{branchInfo}</Typography>
            </div>
            {hasDirections && (
              <Button
                variant="text"
                className={classes.directionBtn}
                onClick={(e) => goToBranchLocation(e, branch)}
              >
                {t('directions_web')}
              </Button>
            )}
          </div>
          <CardContent className={classes.cardContent}>
            {timingSummary.map((item) => {
              const dayLabel = _get(item, 'dayLabel', '');
              const timeLabel = _get(item, 'timeLabel', '');

              const formattedTimeClosed = isArabic
                ? getTimeWithArabicLabel(timeLabel)
                : timeLabel;
              const timeSlots = item?.timeRanges?.length
                ? item.timeRanges.map((timeRange) => {
                    const formattedTime = isArabic
                      ? getTimeWithArabicLabel(timeRange)
                      : timeRange;
                    return formattedTime;
                  })
                : [formattedTimeClosed]; // return time slot as array e.g ['Closed']
              const formattedDay = isArabic
                ? getArabicDayWithLabel(dayLabel)
                : dayLabel;

              const capitalDay =
                formattedDay && getCapitalizeDayLabel(formattedDay);
              const formattedDayInfo =
                capitalDay && capitalDay.replace(' - ', ` ${t('to')} `);

              return (
                <React.Fragment key={dayLabel}>
                  <Typography className={classes.timingSummary}>
                    <span>{formattedDayInfo}</span>
                    <span className={classes.timingGroup}>
                      {timeSlots.map((slot) => {
                        return <span key={slot}>{slot}</span>;
                      })}
                    </span>
                  </Typography>
                </React.Fragment>
              );
            })}
            <Typography className={classes.timingSummary}>
              <span>{t('Current_number')}</span>
              <span className={classes.timingSummaryTime}>{phoneNumber}</span>
            </Typography>
          </CardContent>
        </Card>
      </Typography>
    </ButtonControl>
  );
}
