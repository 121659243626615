import format from 'date-fns/format';
import isEqual from 'date-fns/isEqual';
import _filter from 'lodash/filter';
import _fromPairs from 'lodash/fromPairs';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import _keyBy from 'lodash/keyBy';
import _mapKeys from 'lodash/mapKeys';
import _orderBy from 'lodash/orderBy';
import _size from 'lodash/size';
import _toPairs from 'lodash/toPairs';

import { BranchQueryType } from '@lumirental/lumi-web-sdk/src/types/branch.types';

import { getSlug } from '@/utils';

const TAB_INDEX = {
  FIRST: 0,
  SECOND: 1,
};

const derive = (
  branchList: any[],
  cityList: any[],
  activeListBy: string,
  activeTabIndex: number,
): Record<string, any> => {
  /**
   * Returns pickup branch list
   */
  const pickUpBranchList = _filter(
    branchList,
    (branch) => !_get(branch, 'carDelivery', false),
  );

  /**
   * Returns an object hashmap of branches by branchId
   * @returns {Object}
   */
  const branchHashMap = _keyBy(branchList, 'id');

  const cityIdHashMap = _groupBy(branchList, 'cityId');

  /**
   * Returns PickUp branches grouped by cities
   */
  const cityIdPickUpHashMap = _groupBy(pickUpBranchList, 'cityId');

  const carDeliveryBranchList = _filter(branchList, (branch) =>
    _get(branch, 'carDelivery', false),
  );
  const cityDeliveryIdHashMap = _groupBy(carDeliveryBranchList, 'cityId');

  /**
   * Returns Delivery branches grouped by cities
   */
  const cityIdDeliveryHashMap = _groupBy(carDeliveryBranchList, 'cityId');

  /**
   * Returns pick-up branch count
   * @returns {Number}
   */
  const pickUpCount = _size(pickUpBranchList);

  /**
   * Returns car delivery branch count
   * @returns {Number}
   */
  const carDeliveryCount = _size(carDeliveryBranchList);

  /**
   * Returns branches grouped by cities
   * sorted by distance
   */
  const sortedCityIdHashMap = () => {
    const updatedKeyObj = _mapKeys(cityIdHashMap, (value) => {
      return `city_${value[0].distance}`;
    });
    const pairs = _toPairs(updatedKeyObj);
    const sorted = _orderBy(pairs, (value) => {
      return value[1][0].distance;
    });
    return _fromPairs(sorted);
  };

  /**
   * Returns Delivery branches grouped by cities
   * sorted by distance
   */
  const sortedDeliveryCityIdHashMap = () => {
    const updatedKeyObj = _mapKeys(cityIdDeliveryHashMap, (value) => {
      return `city_${value[0].distance}`;
    });
    const pairs = _toPairs(updatedKeyObj);
    const sorted = _orderBy(pairs, (value) => {
      return value[1][0].distance;
    });
    return _fromPairs(sorted);
  };

  /**
   * Returns Delivery branches grouped by cities
   * sorted by distance
   */
  const sortedPickUpCityIdHashMap = () => {
    const updatedKeyObj = _mapKeys(cityIdPickUpHashMap, (value) => {
      return `city_${value[0].distance}`;
    });
    const pairs = _toPairs(updatedKeyObj);
    const sorted = _orderBy(pairs, (value) => {
      return value[1][0].distance;
    });
    return _fromPairs(sorted);
  };

  /**
   * Returns active branch list
   * @returns {Array<BranchFactoryType>}
   */
  const branchListByTab =
    activeTabIndex === 0 ? pickUpBranchList : carDeliveryBranchList;

  /**
   * Returns active branch list
   * @returns {Array<BranchFactoryType>}
   */
  const activeBranchList = branchListByTab;
  // activeListBy === 'listByTab' ? branchListByTab : branchList;

  /**
   * Returns city list by active tab
   * @param {Array} cityList
   * @returns {Array}
   */
  const getCityListByActiveTab = () => {
    const cityIdHashmap = _keyBy(activeBranchList, 'cityId');
    return _filter(cityList, (city) => cityIdHashmap[city.id]);
  };

  /**
   * Returns true if pick-up tab is active
   */
  const isPickUpTabActive = activeTabIndex === TAB_INDEX.FIRST;

  /**
   * Returns true if delivery tab is active
   */
  const isDeliveryTabActive = activeTabIndex === TAB_INDEX.SECOND;

  /**
   * Returns branches for a city ID
   * @param {number} cityId
   * @returns {Array}
   */
  const getBranchesByCityId = (cityId: number): Array<any> => {
    return isDeliveryTabActive
      ? cityDeliveryIdHashMap[cityId]
      : cityIdPickUpHashMap[cityId];
  };

  /**
   * Returns branch by ID
   * @param {number} id
   * @returns {Array}
   */
  const getBrancheById = (id: number): any => {
    const filteredBranch = activeBranchList.find((b) => b.id === id);
    return filteredBranch;
  };

  const getBrancheBySlug = (slug: string): any => {
    const brancsh = activeBranchList.find((b) => {
      const branchSlug = getSlug(b.name.toLowerCase());
      return branchSlug === slug;
    });
    return brancsh;
  };

  return {
    pickUpBranchList,
    branchHashMap,
    cityIdHashMap,
    cityIdPickUpHashMap,
    cityIdDeliveryHashMap,
    sortedCityIdHashMap: sortedCityIdHashMap(),
    sortedDeliveryCityIdHashMap: sortedDeliveryCityIdHashMap(),
    sortedPickUpCityIdHashMap: sortedPickUpCityIdHashMap(),
    getCityListByActiveTab: getCityListByActiveTab(),
    getBranchesByCityId,
    getBrancheById,
    getBrancheBySlug,
    pickUpCount,
    carDeliveryCount,
    branchListByTab,
    isPickUpTabActive,
    isDeliveryTabActive,
  };
};

export default derive;

/**
 * Returns true if pick and drop off are same dates
 * I am picking up only the date values since UTC times
 * may be diff for pickup and drop dates
 */
export const isSameDay = (
  pickupDate: number | Date,
  dropoffDate: number | Date,
): boolean => {
  const formattedPickupDate = new Date(format(pickupDate, 'MMMM dd, YYY'));
  const formattedDropoffDate = new Date(format(dropoffDate, 'MMMM dd, YYY'));
  return isEqual(formattedPickupDate, formattedDropoffDate);
};

/**
 * Constructs the fetch query object
 * @param searchText {string}
 * @returns {object}
 */
export const getQueryObj = (
  searchText: string,
  lat: number,
  long: number,
): BranchQueryType => {
  const queryData: BranchQueryType = {
    sort: 'distance', // usually this will come from app side
  };

  // to-do: see if there is better way using lodash
  if (searchText) {
    queryData.query = searchText;
  }
  if (lat) {
    queryData.latitude = lat;
  }
  if (long) {
    queryData.longitude = long;
  }
  return queryData;
};
