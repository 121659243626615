const styles = (theme) => ({
  paper: {
    fontFamily: 'inherit',
    maxWidth: 500,
    padding: 24,
    borderRadius: 8,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
  },
  infoIcon: {
    marginLeft: 6,
    '& > img': {
      maxWidth: 18,
      verticalAlign: 'sub',
    },
  },
  tooltipArrow: {
    color: theme.palette.common.white,
    width: 24,
    height: 14,
    marginTop: '-14px !important',
  },
});

export default styles;
