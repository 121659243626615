import { useCallback, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import useSWR from 'swr';

import getBranchList from '@lumirental/lumi-web-sdk/dist/clients/branch/getBranchList';
import getDropoffCharges from '@lumirental/lumi-web-sdk/dist/clients/branch/getDropoffCharges';
import { DEFAULT_CURRENCY } from '@lumirental/lumi-web-sdk/dist/constants';
import STORAGE_KEYS from '@lumirental/lumi-web-sdk/dist/constants/app/STORAGE_KEYS';
import DEFAULT_BRANCH_DATES from '@lumirental/lumi-web-sdk/dist/constants/date/DEFAULT_BRANCH_DATES';
import DEFAULT_PICKUP_TIME from '@lumirental/lumi-web-sdk/dist/constants/date/DEFAULT_PICKUP_TIME';
import branchFactory from '@lumirental/lumi-web-sdk/dist/factories/branch/branchFactory';
import $sessionStorage from '@lumirental/lumi-web-sdk/dist/services/session.storage.service';
import getBranchNotFound from '@lumirental/lumi-web-sdk/dist/utils/error/getBranchNotFound';
import getLocalTimeStampFromGMT from '@lumirental/lumi-web-sdk/dist/utils/store/getLocalTimeStampFromGMT';
import getTimeStampForDateTime from '@lumirental/lumi-web-sdk/dist/utils/store/getTimeStampForDateTime';
import { BranchFactoryType } from '@lumirental/lumi-web-sdk/src/types/branch.types';
import CAR_DELIVERY_DURATION from '@lumirental/lumi-web-shared/lib/constants/date/CAR_DELIVERY_DURATION';
import useAB from '@lumirental/lumi-web-shared/lib/hooks/useAB';

import { LocationProps, LocationsProps, LocationType } from '@/types/Location';
import deriveBranchHelpers, { getQueryObj, isSameDay } from '@/utils/branch';
import { useUrl } from '@/utils/useUrl';

// #region helper functions
const isValidDate = (date: Date) => !Number.isNaN(date.getTime());
const parseDateOrTimestamp = (input: string): Date | undefined => {
  if (!input) return;

  let date = new Date(input);
  if (isValidDate(date)) {
    return date;
  }

  const timestamp = Number(input);
  if (!Number.isNaN(timestamp)) {
    date = getLocalTimeStampFromGMT(timestamp);
    if (isValidDate(date)) {
      return date;
    }
  }

  throw new Error('Invalid date or timestamp');
};

const formatTimestampToTime = (unixTimestamp: string): string => {
  const dateTimestamp = Number(unixTimestamp);
  if (Number.isNaN(dateTimestamp)) return DEFAULT_PICKUP_TIME;

  const date = getLocalTimeStampFromGMT(dateTimestamp);

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes.toString();

  const strTime = `${hours}:${minutesStr} ${ampm}`;
  return strTime;
};

const dropOffCharges = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [dropChargesData, setDropChargesData] = useState(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isDropoffChargesLoading, setIsDropoffChargesLoading] = useState(false);

  const dropOffCharge = dropChargesData?.dropOff.charge ?? 0;
  const currency = dropChargesData?.dropOff.currency ?? DEFAULT_CURRENCY;
  const dropoffCharge = `${dropOffCharge} ${currency}`;

  return {
    isDropoffChargesLoading,
    dropoffCharge,
    dropoffCategoryType: dropChargesData?.dropOff?.category,
    fetchDropoffCharges: async ({
      pickupBranchId,
      dropOffBranchId,
    }: {
      pickupBranchId: number;
      dropOffBranchId: number;
    }) => {
      try {
        setIsDropoffChargesLoading(true);
        const res = await getDropoffCharges({
          pickupBranchId,
          dropOffBranchId,
        });
        setDropChargesData(res);
      } catch (error) {
        console.error('error drop off charges');
      }
      setIsDropoffChargesLoading(false);
    },
  };
};

const getProps = (): LocationProps => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { changeParams } = useUrl();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [locationState, setLocationState] = useState(null);

  return {
    isLocationLoading: locationState === 'loading',
    isLocationSuccess: !!locationState,
    lat: locationState?.coords?.latitude,
    long: locationState?.coords?.longitude,
    setLocationState,
    clearLocationProps: () => {
      setLocationState(null);
      changeParams({
        search: '',
        lat: 'null',
        long: 'null',
      });
    },
  };
};

export const useMobileSearch = (
  branch: Record<string, any>,
  cityList: any[],
  _branchList: any[],
  language: string,
  slug?: string,
): Record<string, any> => {
  const { changeParam, changeParams } = useUrl();
  const searchParams = useSearchParams();

  const query = getQueryObj(
    searchParams.get('search'),
    Number(searchParams.get('lat')),
    Number(searchParams.get('long')),
  );
  const {
    data: draftBranchList,
    error: queryBranchError,
    isLoading: branchLoading,
  } = useSWR(query, getBranchList);

  const { data: branchListAPI, error: allBranchError } = useSWR(
    { sort: 'distance' },
    getBranchList,
  );
  const branchList = branchListAPI?.data.map((item: any) =>
    branchFactory(item, language),
  );
  const queryBranchList = draftBranchList?.data
    ? draftBranchList?.data.map((item: any) => branchFactory(item, language))
    : branchList;

  const branchError =
    (queryBranchList?.length < 1 && getBranchNotFound()?.response) ||
    queryBranchError ||
    allBranchError;

  const activeTabIndex = Number(searchParams.get('branchTab') ?? '0');
  const pickUpBranchId = Number(
    searchParams.get('pickUpBranch') ??
      searchParams.get('pickupBranchId') ??
      // branch?.branchId ??
      // branch?.id ??
      '0',
  );

  const dropOffBranchId = Number(
    searchParams.get('dropOffBranch') ??
      searchParams.get('dropOffBranchId') ??
      // branch?.branchId ??
      // branch?.id ??
      '0',
  );

  const isSwitchOn = Boolean(searchParams.get('dropOffSwitch') === 'true');

  const pickupDateParam =
    searchParams.get('pickupDate') || searchParams.get('pickUpDate');
  const parsedPickupDate = parseDateOrTimestamp(pickupDateParam);
  const pickupDate = parsedPickupDate ?? DEFAULT_BRANCH_DATES.PICKUP;

  const dropoffDateParam =
    searchParams.get('dropOffDate') || searchParams.get('dropoffDate');
  const parsedDropoffDate = parseDateOrTimestamp(dropoffDateParam);
  const dropoffDate = parsedDropoffDate ?? DEFAULT_BRANCH_DATES.DROPOFF;

  const isSameDayLocal = isSameDay(pickupDate, dropoffDate);

  const pickUpTimeParam = searchParams.get('pickUpTime');
  const pickupTime =
    pickupDateParam && !pickUpTimeParam
      ? formatTimestampToTime(pickupDateParam)
      : pickUpTimeParam ?? DEFAULT_PICKUP_TIME;

  const dropOffTimeParam = searchParams.get('dropOffTime');
  const dropoffTime =
    dropoffDateParam && !dropOffTimeParam
      ? formatTimestampToTime(dropoffDateParam)
      : dropOffTimeParam ?? DEFAULT_PICKUP_TIME;

  const activeListBy = searchParams.get('activeList');

  if (!activeTabIndex || Number(activeTabIndex) === 0) {
    $sessionStorage.clear(STORAGE_KEYS.ACTIVE_TAB);
  }

  const branchHelpers = deriveBranchHelpers(
    branchList,
    cityList,
    activeListBy,
    activeTabIndex,
  );

  let dropOffBranchToDisplay;

  if (pickUpBranchId > 0)
    dropOffBranchToDisplay = branchHelpers.getBrancheById(dropOffBranchId);
  else if ((dropOffBranchId === 0 || activeTabIndex === 1) && slug)
    dropOffBranchToDisplay = branchHelpers.getBrancheBySlug(slug);
  else dropOffBranchToDisplay = branchHelpers.getBrancheById(dropOffBranchId);

  let pickUpBranchToDisplay;

  if (pickUpBranchId > 0)
    pickUpBranchToDisplay = branchHelpers.getBrancheById(pickUpBranchId);
  else if ((pickUpBranchId === 0 || activeTabIndex === 1) && slug)
    pickUpBranchToDisplay = branchHelpers.getBrancheBySlug(slug);
  else pickUpBranchToDisplay = branchHelpers.getBrancheById(pickUpBranchId);

  const queryBranchHelpers = deriveBranchHelpers(
    queryBranchList,
    cityList,
    activeListBy,
    activeTabIndex,
  );

  const {
    sortedDeliveryCityIdHashMap: allSortedDeliveryCityIdHashMap,
    sortedPickUpCityIdHashMap: allSortedPickUpCityIdHashMap,
    getBrancheById,
    getBranchesByCityId,
    getCityListByActiveTab,
    isPickUpTabActive,
    isDeliveryTabActive,
    pickUpBranchList,
    pickUpCount,
    carDeliveryCount,
  } = branchHelpers;

  const { sortedDeliveryCityIdHashMap, sortedPickUpCityIdHashMap } =
    queryBranchHelpers;

  const branchProps = {
    queryBranchList,
    sortedQueryPickUpCityIdHashMap:
      queryBranchHelpers.sortedPickUpCityIdHashMap,
    sortedQueryDeliveryCityIdHashMap:
      queryBranchHelpers.sortedDeliveryCityIdHashMap,
    branchErrorData: branchError,
    isBranchError: branchError,
    isBranchLoading: branchLoading,
    sortedPickUpCityIdHashMap,
    sortedDeliveryCityIdHashMap,
    allSortedDeliveryCityIdHashMap,
    allSortedPickUpCityIdHashMap,
    setInitialState: () => console.log,
  };

  const cityProps: Record<string, any> = {
    cityErrorData: null,
  };

  const locationProps: LocationsProps = {
    pickup: getProps(),
    dropoff: getProps(),
  };

  const isDeliveryFeatureFlag = useAB('free_car_delivery_tag');
  const isMinimumDeliveryFeatureFlag = useAB('minimum_car_delivery_tag');
  const minDaysOfDeliveryDropOff =
    isDeliveryFeatureFlag && isMinimumDeliveryFeatureFlag
      ? CAR_DELIVERY_DURATION.DAYS_15
      : CAR_DELIVERY_DURATION.DAYS_30;

  const searchPageProps = {
    setSearchDataUpdated: console.log,
    activeTabIndex,
    pickupBranch: pickUpBranchToDisplay,
    dropoffBranch: dropOffBranchToDisplay,
    backupDropoffBranch: {},
    isPickUpDialogOpen: false,
    isDropOffDialogOpen: false,
    getBranchesByCityId,
    getBranchByBranchId: getBrancheById,
    isSwitchOn,
    pickupDate,
    dropoffDate,
    pickupTime,
    dropoffTime,
    searchDataUpdated: false,
    openPickupTimer: false,
    openDropoffTimer: false,
    minDaysOfDeliveryDropOff,
    activeListBy,
    isDeliveryTabActive,
    isPickUpTabActive,
    isSameDay: isSameDayLocal,
    pickUpBranchList,
    pickUpCount,
    carDeliveryCount,
    isPickUpBranchSelected: pickUpBranchId > 0,
    getCityListByActiveTab: () => getCityListByActiveTab,
    setActiveListBy: (value: string) => changeParam('activeList', value),
    dropOffSwitch: isSwitchOn,
    setPickUpDate: (value: Date) => {
      const pickupDateTime = getTimeStampForDateTime(value, pickupTime);
      const dropoffDateTime = getTimeStampForDateTime(dropoffDate, dropoffTime);
      changeParams({
        pickupDate: pickupDateTime,
        dropOffDate: dropoffDateTime,
      });
    },
    setDropOffDate: (value: Date) => {
      const pickupDateTime = getTimeStampForDateTime(pickupDate, pickupTime);
      const dropoffDateTime = getTimeStampForDateTime(value, dropoffTime);
      changeParams({
        pickupDate: pickupDateTime,
        dropOffDate: dropoffDateTime,
      });
    },
    setBookingDates: (pickupDate: Date, dropOffDate: Date) => {
      const pickupDateTime = getTimeStampForDateTime(pickupDate, pickupTime);
      const dropoffDateTime = getTimeStampForDateTime(dropOffDate, dropoffTime);
      changeParams({
        pickupDate: pickupDateTime,
        dropOffDate: dropoffDateTime,
      });
    },
    setOpenPickupTimer: console.log,
    setOpenDropoffTimer: console.log,
    setPickUpBranch: (value: BranchFactoryType) => {
      if (isDeliveryTabActive) {
        changeParams({
          pickUpBranch: value.id,
          dropOffBranch: value.id,
        });
      } else if (!isSwitchOn) {
        changeParams({
          pickUpBranch: value.id,
          dropOffBranch: value.id,
        });
      } else changeParam('pickUpBranch', value.id);
    },
    setDropOffBranch: (value: BranchFactoryType) =>
      changeParam('dropOffBranch', value?.id),
    setSwitchState: (value: boolean) => {
      if (value) {
        changeParams({
          dropOffSwitch: value,
          dropOffBranch: -1,
        });
      } else if (dropOffBranchId === -1) {
        changeParams({
          dropOffSwitch: value,
          dropOffBranch: null,
        });
      } else changeParam('dropOffSwitch', value);
    },
    setActiveTabIndex: (index: number) => {
      changeParam('branchTab', index);
    },
    setPickUpTime: (value: string) => {
      changeParam('pickUpTime', value);
    },
    setDropOffTime: (value: string) => {
      changeParam('dropOffTime', value);
    },
    setDeliveryParams: ({
      pickupDate,
      dropOffDate,
      branchTab,
      dropOffSwitch,
    }: any) => {
      const pickupDateTime = getTimeStampForDateTime(pickupDate, pickupTime);
      const dropoffDateTime = getTimeStampForDateTime(dropOffDate, dropoffTime);
      changeParams({
        pickupDate: pickupDateTime,
        dropoffDate: dropoffDateTime,
        branchTab,
        dropOffSwitch,
      });
    },
  };

  const detectLocation = useCallback((type: LocationType) => {
    locationProps[type].setLocationState('loading');
    window.navigator.geolocation.getCurrentPosition((position) => {
      locationProps[type].setLocationState(position);
    }, console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   detectLocation();
  // }, [detectLocation]);

  return {
    searchPageProps,
    cityProps,
    branchProps,
    locationProps,
    detectLocation,
    isDeliveryTabActive,
    dropOffCharges: dropOffCharges(),
  };
};
