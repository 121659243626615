const styles = (theme) => ({
  wrapper: {
    position: 'relative',
    width: '100%',
    maxWidth: '100%',
    padding: '0 12px',
  },
  fieldLabel: {
    color: theme.palette.text.placeholder,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.5,
    marginBottom: 4,
    '.dark &': {
      color: theme.palette.common.white,
    },
  },
  branchWrapper: {
    padding: 24,
    position: 'absolute',
    left: 12,
    width: 'calc(100% - 24px)',
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.grey20}`,
    marginTop: 4,
    borderRadius: 4,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.05)',
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: 550,
    zIndex: theme.zIndex.zIndex100,
    '& .MuiLinearProgress-colorPrimary': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
    },
  },

  inputField: {
    padding: '16px 14px',
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.1,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightSemiBold,
    '.dark &': {
      backgroundColor: theme.palette.common.white,
    },
    '&::placeholder': {
      color: theme.palette.common.grey200,
      fontWeight: theme.typography.fontWeightRegular,
      opacity: 1,
    },
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  navigation: {
    marginBottom: 12,
    '& > .MuiButton-text': {
      textTransform: 'capitalize',
    },
    '&  img': {
      marginRight: 8,
    },
  },
  branchNearBtn: {
    textTransform: 'none',
    padding: 0,
    fontSize: theme.typography.fontSize16,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightRegular,
    '& img': {
      width: '100%',
      maxWidth: 18,
    },
  },
});

export default styles;
