const styles = (theme) => ({
  dialog: {
    minWidth: 320,
    borderRadius: 14,
    '& .MuiDialogContent-root': {
      paddingBottom: 16,
    },
  },
  mainWrapper: {
    padding: '20px !important',
    '@media(min-width: 768px)': {
      padding: '60px 70px !important',
    },
    textAlign: 'center',
  },
  paragraph: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.5,
  },
  actionWrapper: {
    justifyContent: 'center',
    padding: 0,
    borderTop: `1px solid ${theme.palette.common.grey20}`,
  },
  closeBtn: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.5,
    textTransform: 'capitalize',
    padding: '20px 24px',
  },
});

export default styles;
