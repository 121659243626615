const styles = (theme) => ({
  /* DayPicker styles */
  dayPickerWrapper: {
    width: '100%',
    '& .DayPicker': {
      display: 'inline-block',
      fontSize: '1rem',
    },
    '& .DayPicker-wrapper': {
      position: 'relative',
      flexDirection: 'row',
      paddingBottom: '1rem',
      webkitUserSelect: 'none',
      mozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none',
    },
    '& .DayPicker-Months': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    '& .DayPicker-Month': {
      display: 'table',
      margin: '0 1em',
      marginTop: '1em',
      borderSpacing: 0,
      borderCollapse: 'collapse',
      webkitUserSelect: 'none',
      mozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none',
      '& .DayPicker-Weekdays': {
        display: 'table-header-group',
        marginTop: '1em',
      },
      '& .DayPicker-WeekdaysRow': {
        display: 'table-row',
      },
      '& .DayPicker-Weekday': {
        display: 'table-cell',
        padding: '0.5em',
        color: theme.palette.common.grey100,
        textAlign: 'center',
        fontSize: '0.875em',
      },
      '& .DayPicker-Weekday abbr[title]': {
        borderBottom: 'none',
        textDecoration: 'none',
      },
      '& .DayPicker-Body ': {
        display: 'table-row-group',
      },
      '& .DayPicker-Week': {
        display: 'table-row',
      },
      '& .DayPicker-Day': {
        display: 'table-cell',
        padding: '0.5em',
        borderRadius: '50%',
        verticalAlign: 'middle',
        textAlign: 'center',
        cursor: 'pointer',
      },
      '& .DayPicker-WeekNumber': {
        display: 'table-cell',
        padding: '0.5em',
        minWidth: '1em',
        borderRight: `1px solid ${theme.palette.common.grey50}`,
        color: theme.palette.common.grey100,
        verticalAlign: 'middle',
        textAlign: 'right',
        fontSize: '0.75em',
        cursor: 'pointer',
      },
      '& .DayPicker--interactionDisabled .DayPicker-Day': {
        cursor: 'default',
      },
    },
    '& .DayPicker-NavButton': {
      position: 'absolute',
      top: '1em',
      right: '1.5em',
      left: 'auto',
      display: 'inline-block',
      marginTop: 2,
      width: '1.25em',
      height: '1.25em',
      backgroundPosition: 'center',
      backgroundSize: '50%',
      backgroundRepeat: 'no-repeat',
      color: theme.palette.common.grey100,
      cursor: 'pointer',
      '&:hover': {
        opacity: '0.8',
      },
      '& .DayPicker-NavButton--prev': {
        marginRight: '1.5em',
        backgroundImage:
          'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC")',
      },
      '& .DayPicker-NavButton--next': {
        backgroundImage:
          'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==")',
      },
      '& .DayPicker-NavButton--interactionDisabled': {
        display: 'none',
      },
      '& .DayPicker-Footer': {
        paddingTop: '0.5em',
      },
      '& .DayPicker-TodayButton': {
        border: 'none',
        backgroundColor: 'transparent',
        backgroundImage: 'none',
        boxShadow: 'none',
        color: theme.palette.common.blue300,
        fontSize: '0.875em',
        cursor: 'pointer',
      },
      '& .DayPicker-Day--today': {
        color: theme.palette.common.red400,
        fontWeight: 700,
      },
      '& .DayPicker-Day--outside': {
        color: theme.palette.common.grey100,
        cursor: 'default',
      },
      '& .DayPicker-Day--disabled': {
        color: theme.palette.common.grey100,
        cursor: 'default',
      },
      '& .DayPicker-Day--sunday': {
        backgroundColor: theme.palette.common.grey20,
      },
      '& .DayPicker-Day--sunday:not(.DayPicker-Day--today)': {
        color: theme.palette.common.grey50,
      },
      '& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)':
        {
          position: 'relative',
          backgroundColor: theme.palette.common.blue300,
          color: theme.palette.common.grey20,
        },
      '& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover':
        {
          backgroundColor: theme.palette.common.blue300,
        },
      '& .DayPicker:not(.DayPicker--interactionDisabled).DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover':
        {
          backgroundColor: theme.palette.common.grey20,
        },
      '& .DayPickerInput': {
        display: 'inline-block',
      },
      '& .DayPickerInput-OverlayWrapper': {
        position: 'relative',
      },
      '& .DayPickerInput-Overlay': {
        position: 'absolute',
        left: 0,
        zIndex: 1,
        background: 'white',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
      },
    },
  },
  datePickerWrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflowY: 'auto',
    '& .DayPicker-Day': {
      width: 40,
      color: theme.palette.text.primary,
      outline: 'none',
    },
    '& .DayPicker-Day--disabled': {
      color: theme.palette.common.grey100,
    },
    '& .DayPicker-Day--end.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside), & .DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)':
      {
        position: 'relative',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.blue400,
        borderRadius: '50%',
        outline: 'none',
      },
    '&:not(.date-pickup-active) .DayPicker-Day--end.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):before, &:not(.date-pickup-active) .DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):after':
      {
        content: '" "',
        width: '50%',
        position: 'absolute',
        height: '100%',
        top: 0,
        opacity: 0.3,
        backgroundColor: theme.palette.primary.main,
      },
    '&:not(.date-pickup-active) .DayPicker-Day--end.DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):before, &:not(.date-pickup-active) .DayPicker-Day--start.DayPicker-Day--end.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):after':
      {
        content: 'none',
      },
    '&:not(.date-pickup-active) .DayPicker-Day--end.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):before':
      {
        left: 0,
      },
    '&:not(.date-pickup-active) .DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):after':
      {
        right: 0,
      },
    '& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)':
      {
        backgroundColor: theme.palette.common.grey20,
        color: theme.palette.text.primary,
        borderRadius: 0,
      },
    '& .DayPicker-Day--selected.DayPicker-Day--outside:first-child, & .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):first-child':
      {
        borderTopLeftRadius: 12,
        borderBottomLeftRadius: 12,
      },
    '& .DayPicker-Day--selected.DayPicker-Day--outside:last-child, & .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):last-child':
      {
        borderTopRightRadius: 12,
        borderBottomRightRadius: 12,
      },
    '& .DayPicker-Day--selected:not(.DayPicker-Day--outside).DayPicker-Day--disabled':
      {
        backgroundColor: theme.palette.common.grey20,
        borderRadius: 0,
      },
    '& .DayPicker': {
      display: 'block',
      width: '100%',
    },
    '& .DayPicker-Month': {
      width: '100%',
      margin: 0,
      marginTop: 8,
    },
    '& .DayPicker-Caption': {
      display: 'table-caption',
      marginBottom: '0.5em',
      padding: '0 0.5em',
      textAlign: 'center',
      '& > div': {
        color: theme.palette.text.primary,
        fontSize: 15,
        lineHeight: 1.3,
        fontWeight: theme.typography.fontWeightSemiBold,
      },
    },
    '& .DayPicker-wrapper': {
      padding: 0,
    },
    '& .DayPicker-NavBar': {
      position: 'absolute',
      top: 10,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      '&.rtl > button img': {
        transform: 'rotate(180deg)',
      },
    },
  },
  navBtn: {
    width: 36,
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    color: theme.palette.text.primary,
    '& img': {
      width: 14,
      height: 14,
    },
  },
});

export default styles;
