import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getDay from 'date-fns/getDay';
import _get from 'lodash/get';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';

import { makeStyles } from 'tss-react/mui';

import DEFAULT_BRANCH_TIMING from '@lumirental/lumi-web-sdk/dist/constants/date/DEFAULT_BRANCH_TIMING';
import DATE_TIME_PICKER from '@lumirental/lumi-web-shared/lib/constants/date/DATE_TIME_PICKER';
import useAB from '@lumirental/lumi-web-shared/lib/hooks/useAB';
import isToday from '@lumirental/lumi-web-shared/lib/utils/date/isToday';

import TimePicker from '../../TimePicker';

import styles from './TimePickerWrapper.style';

const useStyles = makeStyles()(styles);

export default function TimePickerWrapper({
  id,
  value,
  dataTestId,
  openTimerDialog,
  searchPageProps,
  customClasses = {
    textField: '',
  },
  handleOpenDatePicker = () => {},
  isError = false,
  handleConfirmClick = () => {},
}) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const textInputRef = createRef();

  const textFieldStyle = `${classes.timeField} ${customClasses.textField}`;
  const shakeClass = `${isError ? classes.shake : ''}`;

  // local state
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [showTimePickerDialog, setShowTimePickerDialog] = useState(false);
  const [updateTime, setUpdateTime] = useState(false);

  const isPickupTimeDialogOpen = id === 'pickupTime';
  const cId = isPickupTimeDialogOpen ? 'pickup-time' : 'dropoff-time';
  const timePickerBtnText = isPickupTimeDialogOpen
    ? t('confirm_pickup_time')
    : t('confirm_dropoff_time');

  // read props for csr
  const {
    pickupBranch,
    dropoffBranch,
    pickupDate,
    dropoffDate,
    pickupTime,
    dropoffTime,
    setPickUpTime,
    setDropOffTime,
    setOpenPickupTimer,
    setOpenDropoffTimer,
    isPickUpBranchSelected,
    isDropOffBranchSelected,
    isSameDay,
  } = searchPageProps;

  // onDays is a hashmap
  const onDays = isPickupTimeDialogOpen
    ? _get(pickupBranch, 'onDays', {})
    : _get(dropoffBranch, 'onDays', {});

  const selectedDay = isPickupTimeDialogOpen ? pickupDate : dropoffDate;
  const isItToday = openTimerDialog && isToday(selectedDay);
  const selectedWeekDay = getDay(selectedDay);
  const selectedDayTiming = _get(
    onDays,
    `${selectedWeekDay}`,
    DEFAULT_BRANCH_TIMING,
  );
  const offDays = isPickupTimeDialogOpen
    ? _get(pickupBranch, 'offDays', [])
    : _get(dropoffBranch, 'offDays', []);
  const isSelectedDayOff = offDays.indexOf(selectedWeekDay) !== -1;
  const isBranchSelected = isPickupTimeDialogOpen
    ? !!(isPickUpBranchSelected || pickupBranch)
    : !!(isDropOffBranchSelected || dropoffBranch);
  const pickupTiming = _get(pickupBranch, 'timingSummary', []);
  const dropoffTiming = _get(dropoffBranch, 'timingSummary', []);
  const leadTimeMinutes = _get(
    pickupBranch,
    'leadTimeMinutes',
    DATE_TIME_PICKER.T_PLUS_240_M,
  );

  const timePickerChangeMsg = isPickupTimeDialogOpen
    ? t('pickup_time_updated')
    : t('dropoff_time_updated');

  /**
   * Effect to auto open timer dialog
   * when user confirms a date
   */
  useEffect(() => {
    if (openTimerDialog) {
      textInputRef.current.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTimerDialog]);

  const handleTimePickerOpen = () => {
    setShowTimePickerDialog(true);
  };
  const handleTimePickerClose = () => {
    setShowTimePickerDialog(false);
    setUpdateTime(false);
  };
  const handleConfirmTimePicker = () => {
    handleTimePickerClose(); //  close time picker dialog

    // on time picker close update store
    if (isPickupTimeDialogOpen) {
      setOpenPickupTimer(false); // update global store

      // open date picker
      handleOpenDatePicker();
    } else {
      setOpenDropoffTimer(false); // update global store
    }
    handleConfirmClick && handleConfirmClick();
  };

  const handleSnackBarClose = (evt, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  return (
    <>
      <TextField
        fullWidth
        ref={textInputRef}
        label={t('time')}
        value={value}
        id={cId}
        variant="outlined"
        onClick={handleTimePickerOpen}
        className={textFieldStyle}
        InputProps={{
          readOnly: true,
          classes: {
            root: shakeClass,
            input: classes.inputField,
            notchedOutline: classes.inputBorder,
          },
        }}
        InputLabelProps={{
          classes: {
            root: classes.inputLabel,
          },
        }}
        data-testid={dataTestId}
        error={isError}
      />
      <Dialog
        fullWidth
        open={showTimePickerDialog}
        onClose={handleTimePickerClose}
        classes={{
          scrollPaper: classes.scrollPaper,
          paper: classes.paper,
        }}
      >
        <DialogContent className={classes.mainWrapper}>
          <div className={classes.contentWrapper}>
            <div className={classes.timePicker}>
              <TimePicker
                pickupTime={pickupTime}
                dropoffTime={dropoffTime}
                pickupDate={pickupDate}
                dropoffDate={dropoffDate}
                setPickUpTime={setPickUpTime}
                setDropOffTime={setDropOffTime}
                selectedDayTiming={selectedDayTiming}
                isSelectedDayOff={isSelectedDayOff}
                timePickerBtnText={timePickerBtnText}
                isPickupTimeDialogOpen={isPickupTimeDialogOpen}
                isBranchSelected={isBranchSelected}
                isItToday={isItToday}
                isSameDay={isSameDay}
                pickupTiming={pickupTiming}
                dropoffTiming={dropoffTiming}
                handleConfirmTimePicker={handleConfirmTimePicker}
                leadTimeMinutes={leadTimeMinutes}
                updateTime={updateTime}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackBar}
        onClose={handleSnackBarClose}
        autoHideDuration={2000}
        message={timePickerChangeMsg}
        data-testid="timePickerSnackBar"
      />
    </>
  );
}
