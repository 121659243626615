const styles = (theme) => ({
  cardWrapper: {
    width: '100%',
    textAlign: 'left',
    display: 'block',
    maxWidth: 360,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: theme.palette.common.blue50,
    },
  },
  titleWrap: {
    padding: 16,
    width: '100%',
  },
  title: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize18,
    lineHeight: 1.3,
    marginBottom: 4,
  },
  subTitle: {
    color: theme.palette.common.grey300,
    fontSize: theme.typography.fontSize14,
    lineHeight: 1.5,
  },
  fullWidth: {
    alignItems: 'flex-start',
    '& button.MuiButtonBase-root': {
      maxWidth: 348,
    },
  },
  branchList: {
    paddingLeft: 16,
    paddingRight: 16,
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
  },
  flexCol: {
    flexDirection: 'column',
  },
});

export default styles;
