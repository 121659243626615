const styles = (theme) => ({
  inputField: {
    padding: '16px 14px',
    fontSize: 15,
    lineHeight: 1.1,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightSemiBold,
    '&::placeholder': {
      color: theme.palette.common.grey300,
      fontWeight: theme.typography.fontWeightRegular,
    },
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  inputBorder: {
    borderColor: theme.palette.common.grey20,
  },
  scrollPaper: {
    alignItems: 'flex-end',
  },
  header: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.grey400,
    boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.1)',
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      flex: '0 0 auto',
      fill: theme.palette.common.grey300,
    },
    '& > h4': {
      flex: '1 0 auto',
      textAlign: 'center',
      fontSize: '1.4rem',
      fontWeight: 'bold',
    },
  },
  mainWrapper: {
    padding: 16,
  },
  actionWrapper: {
    justifyContent: 'center',
    padding: '8px 12px',
    '& button': {
      width: '100%',
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderRadius: 8,
      '&:hover': {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
  },
  searchWrapper: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
    marginLeft: -8,
  },
  searchInput: {
    width: '100%',
    marginTop: 8,
    '& .MuiInputBase-root': {
      color: theme.palette.common.black,
      '&:before, &:after': {
        display: 'none',
      },
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text.primary,
      fontSize: 15,
      letterSpacing: theme.typography.subtitle2.letterSpacing,
      '&::placeholdr': {
        color: theme.palette.text.placeholder,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  noData: {
    padding: 8,
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize15,
    marginBottom: 15,
  },
  navigation: {
    marginBottom: 12,
    '& > .MuiButton-text': {
      textTransform: 'capitalize',
    },
    '&  img': {
      marginRight: 12,
    },
  },
  searchCloseIcon: {
    '& img': {
      width: 20,
      height: 20,
    },
  },
  backIcon: {
    marginTop: 5,
    '& img': {
      width: 20,
      height: 20,
    },
  },
});

export default styles;
