const styles = (theme) => ({
  tabWrapper: {
    position: 'relative',
    padding: '12px 16px 0',
    borderBottom: `1px solid ${theme.palette.common.grey20}`,
    background: theme.palette.common.white,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    overflow: 'hidden',
  },
  tabItem: {
    textAlign: 'center',
    '& > button': {
      width: '100%',
      fontFamily: 'inherit',
    },
  },
  tabContent: {
    marginBottom: 16,
    display: 'inline-block',
  },
  title: {
    ...theme.typography.subtitle1,
    color: theme.palette.common.grey300,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.1,
    '.active > &': {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightSemiBold,
    },
  },
  subTitle: {
    ...theme.typography.subtitle2,
    color: theme.palette.common.grey300,
    fontWeight: theme.typography.fontWeightRegular,
    '.active > &': {
      color: theme.palette.text.secondary,
    },
  },
  activeTabTip: {
    position: 'absolute',
    left: '25%',
    bottom: -6,
    display: 'inline-block',
    width: 12,
    height: 12,
    backgroundColor: theme.palette.text.headingText,
    borderRadius: '50%',
    transition: 'all 0.3s ease',
  },
  moveRightTip: {
    marginLeft: -24,
  },
  moveLeftTip: {
    marginLeft: 24,
  },
});

export default styles;
