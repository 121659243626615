// NestedMenu.tsx

import React, { useState } from 'react';
import Link from 'next/link';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Menu, MenuItem } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import styles from './DesktopHeader.style';

const useStyles = makeStyles()(styles);

const NestedMenu = ({ buttonLabel, menuItems, className }) => {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="text"
        size="small"
        className={`${classes.nestedButton} ${className || ''}`}
        aria-controls={anchorEl ? 'nested-menu' : undefined}
        aria-haspopup="true"
        onMouseEnter={handleMenuOpen}
      >
        {buttonLabel}
        <ExpandMoreIcon />
      </Button>

      <Menu
        id="nested-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
        MenuListProps={{
          onMouseLeave: handleMenuClose,
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={item}
            onClick={() => {
              item.onClick?.();
              handleMenuClose();
            }}
          >
            <Link href={item.href} passHref legacyBehavior>
              <a style={{ color: 'inherit', textDecoration: 'none' }}>
                {item.label}
              </a>
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default NestedMenu;
