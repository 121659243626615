import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import addDays from 'date-fns/addDays';
import _get from 'lodash/get';
import _some from 'lodash/some';

import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import {
  DATE_TIME_PICKER,
  DEFAULT_BRANCH_DATES,
} from '@lumirental/lumi-web-sdk/dist/constants';
import DROPOFF_BRANCH_CATEGORY from '@lumirental/lumi-web-shared/lib/constants/app/DROPOFF_BRANCH_CATEGORY';
import SEARCH_DEBOUNCE_TIME from '@lumirental/lumi-web-shared/lib/constants/app/SEARCH_DEBOUNCE_TIME';
import TAB_INDEX from '@lumirental/lumi-web-shared/lib/constants/form/TAB_INDEX';
import getLocaleDayMonth from '@lumirental/lumi-web-shared/lib/utils/date/getLocaleDayMonth';
import getNextWorkingDay from '@lumirental/lumi-web-shared/lib/utils/date/getNextWorkingDay';
import dateSelected from '@lumirental/lumi-web-shared/lib/utils/gtm/dateSelected';
import timeSelected from '@lumirental/lumi-web-shared/lib/utils/gtm/timeSelected';
import { validateDateAndTimeSearch } from '@lumirental/lumi-web-shared/lib/utils/validateSearch';

import {
  DifferentCityRegionDialog,
  DropoffChargesInfo,
} from '@lumirental/lumi-web-components-ui';

import { useUrl } from '@/utils/useUrl';

import {
  DARK_CLASS,
  DROPOFF_DATE,
  DROPOFF_TIME,
  PICKUP_DATE,
  PICKUP_TIME,
} from '../../constants';
import DropOffBranchPicker from '../BranchPicker/DropOffBranchPicker';
import PickUpBranchPicker from '../BranchPicker/PickUpBranchPicker';
import DesktopDatePicker from '../DekstopDatePicker/DatePicker';
import DesktopTimePicker from '../DesktopTimePicker/TimePicker';
import FreeCarDeliveryDialog from '../Dialogs/FreeCarDelivery';
import DesktopFindBranchTab from '../Tabs/DesktopFindBranchTab';

import styles from './FindBranchBlock.style';

const useStyles = makeStyles()(styles);

const defaultDateAndTimeError = {
  [PICKUP_DATE]: false,
  [PICKUP_TIME]: false,
  [DROPOFF_DATE]: false,
  [DROPOFF_TIME]: false,
};

export default function FindBranchBlock({
  searchPageProps,
  branchProps,
  cityProps,
  locationProps,
  detectLocation,
  fetchBranch,
  moveTip,
  textInputRef,
  onSearchClick,
  showCloseBtn,
  handleCloseSearch,
  isThemeChange,
  dropOffCharges,
}) {
  const { classes } = useStyles();
  const { changeParams } = useUrl();

  const {
    t,
    i18n: { language },
  } = useTranslation();
  // const pathName = usePathname();

  const pickupDatePickerRef = useRef(null);
  const dropoffDatePickerRef = useRef(null);

  // adding a local state because of a switch issue
  // switch does not turn on when coming from listing page
  const [localSwitchState, setlocalSwitchState] = useState(false);
  const [openFreeCarDeliveryDialog, setOpenFreeCarDeliveryDialog] =
    useState(false);

  // state for snackbar message to show tab is changed
  const [dateAndTimeError, setDateAndTimeError] = useState(
    defaultDateAndTimeError,
  );
  const [openDifferentCityReturnDialog, setOpenDifferentCityReturnDialog] =
    useState(false);
  // const isSearchUpdateEnabled = useAB('auto_search_update_tag');

  // read props for csr
  const {
    activeTabIndex,
    setSwitchState,
    isSwitchOn,
    isDeliveryTabActive,
    pickupBranch,
    dropoffBranch,
    pickupDate,
    dropoffDate,
    pickupTime,
    dropoffTime,
    setPickUpTime,
    setDropOffTime,
    isSameDay,
    setDropOffDate,
    dropOffSwitch,
    isPickUpBranchSelected,
    isDropOffBranchSelected,
    openPickupTimer,
    openDropoffTimer,
    setOpenPickupTimer,
    setOpenDropoffTimer,
    minDaysOfDeliveryDropOff,
    pickUpCount,
    carDeliveryCount,
    setDropOffBranch,
  } = searchPageProps;

  const isCarDelivery = isDeliveryTabActive;

  const pickupBranchName = _get(pickupBranch, 'name', '');
  const dropoffBranchName = _get(dropoffBranch, 'name', '');
  const pickupCityId = _get(pickupBranch, 'cityId', -1);
  const dropoffCityId = _get(dropoffBranch, 'cityId', -1);
  const pickupOnDays = _get(pickupBranch, 'onDays', {});
  const dropoffOnDays = _get(dropoffBranch, 'onDays', {});
  const pickupOffDays = _get(pickupBranch, 'offDays', []);
  const dropoffOffDays = _get(dropoffBranch, 'offDays', []);
  const pickupTiming = _get(pickupBranch, 'timingSummary', []);
  const dropoffTiming = _get(dropoffBranch, 'timingSummary', []);
  const leadTimeMinutes = _get(
    pickupBranch,
    'leadTimeMinutes',
    DATE_TIME_PICKER.T_PLUS_240_M,
  );

  const formattedPickupDate =
    pickupDate && getLocaleDayMonth(pickupDate, language);
  const formattedDropoffDate =
    dropoffDate && getLocaleDayMonth(dropoffDate, language);

  const searchBtnDisabled = !pickupBranch || (dropOffSwitch && !dropoffBranch);

  const wrapperClass = `${classes.wrapper} ${isThemeChange ? DARK_CLASS : ''}`;

  const isListingScreen = showCloseBtn;

  /**
   * effect to persist the switch state
   */
  useEffect(() => {
    setlocalSwitchState(isSwitchOn); // update local state
  }, [isSwitchOn]);

  /**
   * Handles switch toggle
   * @param {object} evt
   */
  const handleToggleSwitch = (evt) => {
    const { checked } = evt.target;
    setlocalSwitchState(checked); // update local state
    setSwitchState(checked); // update global store
  };

  const handlePickupDateClick = (pickupDate) => {
    // send GTM event when date is selected
    dateSelected(PICKUP_DATE, pickupDate);

    // update local state
    setDateAndTimeError({
      ...dateAndTimeError,
      [PICKUP_DATE]: false,
    });

    setOpenPickupTimer(true);
  };

  const handleDropoffDateClick = (dropoffDate) => {
    // send GTM event when date is selected
    dateSelected(DROPOFF_DATE, dropoffDate);

    // update local state
    setDateAndTimeError({
      ...dateAndTimeError,
      [DROPOFF_DATE]: false,
    });

    setOpenDropoffTimer(true);
  };

  const handleSelectPickupTime = (selectedTime) => {
    // update local state
    setDateAndTimeError({
      ...dateAndTimeError,
      [PICKUP_TIME]: false,
    });

    // open date picker
    handleOpenDatePicker();

    setPickUpTime(selectedTime); // update global store
    closePickupTimer();

    // send GTM event when pickup time is selected
    timeSelected(PICKUP_TIME, selectedTime);
  };

  const handleSelectDropoffTime = (selectedTime) => {
    // update local state
    setDateAndTimeError({
      ...dateAndTimeError,
      [DROPOFF_TIME]: false,
    });

    setDropOffTime(selectedTime); // update global store
    closeDropoffTimer();

    // send GTM event when dropoff time is selected
    timeSelected(DROPOFF_TIME, selectedTime);
  };

  const closePickupTimer = () => {
    setOpenPickupTimer(false); // update local state
  };

  const closeDropoffTimer = () => {
    setOpenDropoffTimer(false); // update local state
  };

  const tabChanged = (tabIndx) => {
    if (tabIndx !== activeTabIndex) {
      let newDropOffDate = null;
      if (tabIndx === TAB_INDEX.FIRST) {
        newDropOffDate = addDays(pickupDate, 1); // add 1 day
      } else {
        newDropOffDate = addDays(pickupDate, minDaysOfDeliveryDropOff + 1); // add no. of days for delivery branch
      }

      const offDays = _get(dropoffBranch, 'offDays', []); // get dropoff off days
      const nextWorkingDay = getNextWorkingDay(newDropOffDate, offDays);

      changeParams({
        pickUpBranch: null,
        dropOffBranch: null,
        dropOffSwitch: null,
        dropOffDate: nextWorkingDay,
        branchTab: tabIndx,
      });
    }
  };

  const handelSearch = () => {
    handleOpenFreeCarDeliveryDialog();
  };

  /**
   * Open free car delivery dialog
   */
  const handleOpenFreeCarDeliveryDialog = () => {
    setOpenFreeCarDeliveryDialog(true);
  };

  /**
   * Close free car delivery dialog
   */
  const handleCloseFreeCarDeliveryDialog = () => {
    setOpenFreeCarDeliveryDialog(false);
    handleSearchClick && handleSearchClick();
  };

  const handleSearchClick = () => {
    const {
      isPickupBranchClosed,
      isDropoffBranchClosed,
      isPickupTimeValid,
      isDropoffTimeValid,
      isMinDurationValid,
      isTPlusLeadTimeValid,
    } = validateDateAndTimeSearch({
      pickupBranch,
      dropoffBranch,
      pickupDate,
      dropoffDate,
      pickupTime,
      dropoffTime,
      isSameDay,
    });

    const dateAndTimeErrObj = {
      [PICKUP_DATE]: isPickupBranchClosed,
      [PICKUP_TIME]: !isPickupTimeValid || !isTPlusLeadTimeValid,
      [DROPOFF_DATE]: isDropoffBranchClosed,
      [DROPOFF_TIME]: !isDropoffTimeValid || !isMinDurationValid,
    };

    setDateAndTimeError(dateAndTimeErrObj);

    const hasError = _some(dateAndTimeErrObj);

    if (hasError) {
      // add fake delay if date/time is invalid
      setTimeout(() => {
        onSearchClick && onSearchClick();
      }, SEARCH_DEBOUNCE_TIME);
    } else {
      onSearchClick();
    }
  };

  const handleOpenDatePicker = () => {
    if (isPickUpBranchSelected) {
      // open dropoff date picker dialog.
      dropoffDatePickerRef?.current?.click();
    }
  };

  const { isDropoffChargesLoading, dropoffCharge, dropoffCategoryType } =
    dropOffCharges;

  const dropOffChargesInfo = {
    [DROPOFF_BRANCH_CATEGORY.INTRA_CITY]: {
      title: t('modal_title_free_charges', {
        0: '',
      }),
      description: t('modal_text_additional_charges_different_intracity'),
      imgUrl: '/images/png/different-city-dropoff.png',
    },
    [DROPOFF_BRANCH_CATEGORY.INTRA_REGION]: {
      title: t('modal_title_additional_charges', {
        price: dropoffCharge,
        0: '',
      }),
      description: t('modal_text_additional_charges_different_city'),
      imgUrl: '/images/png/different-city-dropoff.png',
    },
    [DROPOFF_BRANCH_CATEGORY.INTER_REGION]: {
      title: t('modal_title_additional_charges', {
        price: dropoffCharge,
        0: '',
      }),
      description: t('modal_text_additional_charges_different_region'),
      imgUrl: '/images/png/different-region-dropoff.png',
    },
  };

  const handleDiffDropCityReturnChange = () => {
    setDropOffBranch(null); // update global store
    handleDiffDropCityReturnClose(); // close dialog
  };

  const handleDiffDropCityReturnClose = () => {
    setOpenDifferentCityReturnDialog(false);
  };

  return (
    <>
      <div className={wrapperClass}>
        <DesktopFindBranchTab
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={tabChanged}
          pickUpBranchCount={pickUpCount}
          deliveryBranchCount={carDeliveryCount}
          pickupDate={pickupDate}
          dropoffBranch={dropoffBranch}
          minDaysOfDeliveryDropOff={minDaysOfDeliveryDropOff}
          setDropOffDate={setDropOffDate}
          isPickUpBranchSelected={isPickUpBranchSelected}
          showCloseBtn={showCloseBtn}
          handleCloseSearch={handleCloseSearch}
        />
        <div className={classes.searchFormWrapper}>
          <Grid container className={classes.formSection}>
            <Grid item xs={12} className={classes.fieldRow}>
              <PickUpBranchPicker
                branchProps={branchProps}
                searchPageProps={searchPageProps}
                language={language}
                cityProps={cityProps}
                locationProps={locationProps}
                detectLocation={detectLocation}
                fetchBranch={fetchBranch}
                textInputRef={textInputRef}
                id="pickupLocation"
                isSwitchOpen={isSwitchOn}
                defaultValue={pickupBranchName}
                dropoffCityId={dropoffCityId}
                isDropOffBranchSelected={isDropOffBranchSelected}
                isListingScreen={isListingScreen}
                dropOffCharges={dropOffCharges}
                setOpenDifferentCityReturnDialog={
                  setOpenDifferentCityReturnDialog
                }
              />
              {/* show dropoff location field when switch is on */}
              {isSwitchOn && (
                <DropOffBranchPicker
                  branchProps={branchProps}
                  searchPageProps={searchPageProps}
                  language={language}
                  cityProps={cityProps}
                  locationProps={locationProps}
                  detectLocation={detectLocation}
                  fetchBranch={fetchBranch}
                  id="dropoffLocation"
                  isSwitchOpen={isSwitchOn}
                  defaultValue={dropoffBranchName}
                  pickupCityId={pickupCityId}
                  isPickUpBranchSelected={isPickUpBranchSelected}
                  dropOffCharges={dropOffCharges}
                  setOpenDifferentCityReturnDialog={
                    setOpenDifferentCityReturnDialog
                  }
                />
              )}
            </Grid>
            <Grid item xs={12} className={classes.fieldRow}>
              <DesktopDatePicker
                id="pickupDate"
                value={formattedPickupDate || ''}
                dataTestId="searchSelectPickUpDate"
                handleDateClick={handlePickupDateClick}
                forwardedRef={pickupDatePickerRef}
                isError={dateAndTimeError[PICKUP_DATE]}
                searchPageProps={searchPageProps}
              />
              <DesktopTimePicker
                id={PICKUP_TIME}
                value={pickupTime}
                onDays={pickupOnDays}
                offDays={pickupOffDays}
                selectedDate={pickupDate || DEFAULT_BRANCH_DATES.PICKUP}
                selectedTime={pickupTime}
                isSameDay={isSameDay}
                isBranchSelected={!!pickupBranch}
                handleSelectTime={handleSelectPickupTime}
                openTimer={openPickupTimer}
                outsideCloseTimer={closePickupTimer}
                isThemeChange={isThemeChange}
                branchTiming={pickupTiming}
                leadTimeMinutes={leadTimeMinutes}
                isError={dateAndTimeError[PICKUP_TIME]}
                searchPageProps={searchPageProps}
              />

              <DesktopDatePicker
                id="dropoffDate"
                value={formattedDropoffDate || ''}
                dataTestId="searchSelectDropOffDate"
                searchPageProps={searchPageProps}
                onDateConfirmClick={handleDropoffDateClick}
                forwardedRef={dropoffDatePickerRef}
                isError={dateAndTimeError[DROPOFF_DATE]}
              />
              <DesktopTimePicker
                id="dropoffTime"
                value={dropoffTime}
                onDays={dropoffOnDays}
                offDays={dropoffOffDays}
                selectedDate={dropoffDate || DEFAULT_BRANCH_DATES.DROPOFF}
                selectedTime={dropoffTime}
                pickupTime={pickupTime}
                isSameDay={isSameDay}
                isBranchSelected={!!dropoffBranch}
                handleSelectTime={handleSelectDropoffTime}
                openTimer={openDropoffTimer}
                outsideCloseTimer={closeDropoffTimer}
                isThemeChange={isThemeChange}
                branchTiming={dropoffTiming}
                isError={dateAndTimeError[DROPOFF_TIME]}
              />
            </Grid>

            <Grid
              item
              xs={12}
              className={(classes.fieldRow, classes.switchWrapper)}
            >
              <div className={classes.switchSection}>
                {!isCarDelivery && (
                  <>
                    <Switch
                      checked={localSwitchState}
                      onChange={handleToggleSwitch}
                      name="checkedA"
                      classes={{
                        thumb: classes.switchThumb,
                        track: classes.switchTrack,
                      }}
                      data-testid="searchSwitch"
                      inputProps={{
                        'aria-label': t('return_to_a_different_location'),
                      }}
                    />
                    <Box sx={{ display: 'flex' }}>
                      <Typography
                        variant="subtitle1"
                        className={classes.switchLabel}
                      >
                        {t('return_to_a_different_location')}
                      </Typography>
                      <DropoffChargesInfo isSearchFunnel />
                    </Box>
                  </>
                )}
              </div>

              <div className={classes.btnWrapper}>
                <Button
                  disabled={searchBtnDisabled}
                  fullWidth
                  variant="contained"
                  onClick={isCarDelivery ? handelSearch : handleSearchClick}
                  data-testid="searchButton"
                >
                  {t('search')}
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <FreeCarDeliveryDialog
        openDialog={openFreeCarDeliveryDialog}
        handleCloseDialog={handleCloseFreeCarDeliveryDialog}
      />
      <DifferentCityRegionDialog
        isOpen={openDifferentCityReturnDialog}
        onClose={handleDiffDropCityReturnChange}
        onDropoffConfirm={handleDiffDropCityReturnClose}
        isLoading={isDropoffChargesLoading}
        dropOffChargesInfo={dropOffChargesInfo[dropoffCategoryType]}
      />
    </>
  );
}
