import { shakeHorizontal } from '../../animations';

const styles = (theme) => ({
  paper: {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.grey20}`,
    padding: '8px 16px',
    width: 255,
    maxHeight: 260,
    marginTop: 4,
    borderRadius: 4,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.05)',
    transition: 'none !important',
    '&.dark': {
      width: 270,
    },
  },

  wrapper: {
    width: '100%',
    maxWidth: '25%',
    padding: '0 12px',
  },
  fieldLabel: {
    display: 'inline-flex',
    color: theme.palette.text.placeholder,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.5,
    '.dark &': {
      color: theme.palette.common.white,
    },
    '&.error': {
      color: theme.palette.common.red400,
    },
  },
  inputField: {
    padding: '16px 14px',
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.1,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightSemiBold,
    '.dark &': {
      backgroundColor: theme.palette.common.white,
    },
    '&::placeholder': {
      color: theme.palette.common.grey300,
      fontWeight: theme.typography.fontWeightRegular,
    },
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  list: {
    padding: 0,
  },
  listItem: {
    padding: 16,
    margin: '0px -16px',
    width: 'auto',
  },
  itemText: {
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&.item-selected': {
      '& .MuiTypography-body1': {
        fontWeight: theme.typography.fontWeightSemiBold,
        color: theme.palette.common.grey400,
      },
    },
    '& .MuiTypography-body1': {
      fontSize: theme.typography.fontSize13,
      lineHeight: 1.5,
      color: theme.palette.text.placeholder,
    },
    '& .MuiListItemText-secondary': {
      fontSize: theme.typography.fontSize13,
      lineHeight: 1.5,
      color: theme.palette.text.primary,
    },
  },
  noSlotsMsg: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.fontSize16,
    textAlign: 'center',
    padding: '8px 0',
    marginTop: 50,
    marginBottom: 50,
  },
  closedItem: {
    fontSize: theme.typography.fontSize12,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.grey300,
    textTransform: 'none',
    '& .item-closed-text span': {
      fontSize: theme.typography.fontSize12,
    },
  },
  shake: {
    animation: `${shakeHorizontal} 0.3s 2 ${theme.transitions.easing.easeInOut}`,
  },
});

export default styles;
